import { SvgIcon, type SvgIconProps } from '@afosto/components';

export const ApplePay = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 30 20">
    <rect
      width="27"
      height="19"
      x=".5"
      y=".5"
      fill="#fff"
      stroke="#E2E9EF"
      rx="3.5"
    />
    <path
      fill="#000"
      fillRule="evenodd"
      d="M7.66 6.95c-.23.3-.6.53-.98.5A1.52 1.52 0 0 1 8 5.82c.04.42-.11.82-.35 1.12Zm.34.57c-.33-.02-.63.1-.87.2a1.2 1.2 0 0 1-.4.13c-.1 0-.25-.06-.4-.12-.2-.09-.44-.19-.68-.18a1.6 1.6 0 0 0-1.37.87c-.59 1.07-.15 2.65.41 3.51.28.43.62.9 1.05.89.2-.01.34-.07.48-.14.17-.07.34-.15.6-.15.26 0 .43.08.59.15.15.07.3.13.5.13.46 0 .75-.43 1.02-.86a4 4 0 0 0 .46-.98l-.01-.01c-.1-.05-.87-.42-.88-1.41 0-.84.61-1.26.7-1.33h.02c-.4-.61-1-.68-1.22-.7Zm3.15 5.24V6.32h2.3c1.17 0 2 .86 2 2.12 0 1.26-.84 2.12-2.04 2.12H12.1v2.2h-.95Zm.95-5.6h1.1c.81 0 1.28.47 1.28 1.28 0 .82-.47 1.28-1.3 1.28H12.1V7.17ZM18.58 12c-.25.5-.8.82-1.4.82-.88 0-1.5-.55-1.5-1.38 0-.83.6-1.3 1.7-1.38l1.19-.07v-.36c0-.52-.33-.8-.91-.8-.48 0-.83.26-.9.65h-.85c.03-.83.77-1.44 1.78-1.44 1.08 0 1.79.6 1.79 1.53v3.2h-.88V12h-.02Zm-1.14.06c-.5 0-.83-.26-.83-.65 0-.4.31-.64.9-.68l1.05-.07v.37c0 .6-.48 1.03-1.12 1.03Zm4.95.97c-.38 1.12-.82 1.5-1.74 1.5-.07 0-.3-.01-.36-.03v-.77l.28.01c.42 0 .65-.18.8-.66l.08-.29-1.6-4.69h.99l1.11 3.8h.02l1.12-3.8h.96l-1.66 4.93Z"
      clipRule="evenodd"
    />
  </SvgIcon>
);
