import { useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useCountries } from '@afosto/hooks';
import { isDefined } from '@afosto/utils';
import { useChannel } from '../ChannelProvider/hooks/useChannel';
import { FormDialog } from '../FormDialog';
import { useOrder } from '../OrderProvider/hooks/useOrder';
import { SearchCustomerFormSection } from './components/SearchCustomerFormSection';
import { SelectCustomerFormSection } from './components/SelectCustomerFormSection';
import { TypeFormSection } from './components/TypeFormSection';
import { validationSchema } from './validationSchema';
import { translations } from './translations';
import type { UseFormReturn } from '@afosto/components';
import type { SearchResultsListItem } from '../SearchResultsList';
import type {
  SelectCustomerFormDialogData,
  SelectCustomerFormDialogProps,
} from './types';

export const SelectCustomerFormDialog = (
  props: SelectCustomerFormDialogProps
) => {
  const { formProps, title, TransitionProps, ...otherProps } = props;
  const { onExited } = TransitionProps || {};

  const intl = useIntl();
  const { channel } = useChannel();
  const { defaultCountry, formatCountryAsOption } = useCountries({
    locale: intl.locale,
  });
  const { addCustomerToOrder, closeAddCustomerDialog } = useOrder();

  const formRef = useRef<UseFormReturn | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSearch, setShowSearch] = useState(false);

  const defaultValues = {
    contact: {
      phoneNumberCountry: formatCountryAsOption(defaultCountry),
    },
    customerType: 'contact',
    organisation: {
      phoneNumberCountry: formatCountryAsOption(defaultCountry),
    },
  };

  const handleCloseSearch = () => {
    setShowSearch(false);
  };

  const handleShowSearch = () => {
    setShowSearch(true);
  };

  const handleSelectExistingCustomer =
    (customer: SearchResultsListItem) => async () => {
      try {
        setIsSubmitting(true);

        const formValues = formRef.current?.getValues();
        let customerInput;

        if (formValues?.customerType === 'contact' && customer.id) {
          customerInput = {
            contactId: customer.id,
          };
        } else if (formValues?.customerType === 'organisation' && customer.id) {
          customerInput = {
            organisationId: customer.id,
          };
        }

        if (!customerInput) {
          return;
        }

        await addCustomerToOrder({
          customer: customerInput,
        });

        closeAddCustomerDialog();
        setIsSubmitting(false);
      } catch {
        //TODO: Do something with error.
        setIsSubmitting(false);
      }
    };

  const handleExited = (node: HTMLElement) => {
    setShowSearch(false);
    setIsSubmitting(false);

    if (onExited && typeof onExited === 'function') {
      onExited(node);
    }
  };

  const handleSubmit = async (data: SelectCustomerFormDialogData) => {
    try {
      setIsSubmitting(true);

      const {
        phoneNumberCountry: contactPhoneNumberCountry,
        phoneNumberNumber: contactPhoneNumberNumber,
        ...otherContactData
      } = data.contact || {};

      const {
        email: organisationEmail,
        phoneNumberCountry: organisationPhoneNumberCountry,
        phoneNumberNumber: organisationPhoneNumberNumber,
        ...otherOrganisationData
      } = data.organisation || {};

      await addCustomerToOrder({
        customer:
          data.customerType === 'organisation'
            ? {
                organisation: {
                  ...otherOrganisationData,
                  channelId: channel?.id,
                  isGuest: true,
                  administration: {
                    email: organisationEmail || '',
                  },
                  ...(isDefined(organisationPhoneNumberCountry) &&
                  isDefined(organisationPhoneNumberNumber)
                    ? {
                        phoneNumbers: [
                          {
                            countryCode:
                              organisationPhoneNumberCountry?.value || '',
                            number: organisationPhoneNumberNumber || '',
                          },
                        ],
                      }
                    : {}),
                },
              }
            : {
                contact: {
                  ...otherContactData,
                  channelId: channel?.id,
                  isGuest: false,
                  ...(isDefined(contactPhoneNumberCountry) &&
                  isDefined(contactPhoneNumberNumber)
                    ? {
                        phoneNumbers: [
                          {
                            countryCode: contactPhoneNumberCountry?.value || '',
                            number: contactPhoneNumberNumber || '',
                          },
                        ],
                      }
                    : {}),
                },
              },
      });

      closeAddCustomerDialog();
      setIsSubmitting(false);
    } catch (error) {
      setIsSubmitting(false);
      return Promise.reject(error);
    }
  };

  return (
    <FormDialog
      {...otherProps}
      dialogHeaderProps={{
        onBack: showSearch ? handleCloseSearch : undefined,
      }}
      formProps={{
        submitLabel: intl.formatMessage(translations.add),
        ...(formProps || {}),
        defaultValues,
        formRef,
        isSubmitting,
        validationSchema,
      }}
      onSubmit={handleSubmit}
      topBarProps={{
        hideActions: showSearch,
      }}
      TransitionProps={{
        ...(TransitionProps || {}),
        onExited: handleExited,
      }}
      title={title || intl.formatMessage(translations.title)}
    >
      {!showSearch && (
        <>
          <TypeFormSection />
          <SelectCustomerFormSection onShowSearch={handleShowSearch} />
        </>
      )}
      {showSearch && (
        <SearchCustomerFormSection
          onSelectCustomer={handleSelectExistingCustomer}
        />
      )}
    </FormDialog>
  );
};
