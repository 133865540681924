import apiClient from '@afosto/api-client';
import { Query } from '@afosto/datagrid-service';
import { isDefined } from '@afosto/utils';
import { queryOptions } from '@tanstack/react-query';
import { convertRelativeDateToUnixTimestamp } from '../utils/convertRelativeDateToUnixTimestamp';
import { formatActiveSortToUrlParams } from '../utils/formatActiveSortToUrlParams';
import { getUnixTimestamp } from '../utils/getUnixTimestamp';
import { isRelativeDate } from '../utils/isRelativeDate';
import type { OpUnitType } from 'dayjs';

export interface QueryViewDataResponseResultColumns {
  [key: string]: string | boolean | number | null;
}

export interface QueryViewDataResponseResult {
  columns: QueryViewDataResponseResultColumns;
  entity: {
    id: string;
    type: string;
  };
  row: {
    id: string;
    score: number;
    updatedAt: number;
    version: string;
  };
}

export interface QueryViewDataResponse {
  data: QueryViewDataResponseResult[];
}

export interface QueryViewDataFilter {
  key: string;
  operator: string;
  type?: string;
  values: unknown[];
}

export interface QueryViewDataSorting {
  key: string;
  direction: 'asc' | 'desc';
}

export interface QueryViewDataQuery {
  facets?: string[];
  filters?: QueryViewDataFilter[];
  fixedFilters?: QueryViewDataFilter[];
  itemsPerPage?: number;
  page?: number;
  sorting?: QueryViewDataSorting[];
}

export const QUERY_VIEW_DATA_QUERY_KEY = 'queryViewData';

export const fetchQueryViewData = async ({
  queryKey,
}: {
  queryKey: [string, string, QueryViewDataQuery, object];
}): Promise<QueryViewDataResponse> => {
  const [, id, query, requestOptions] = queryKey;
  const {
    sorting = [],
    facets: activeFacets = [],
    filters: activeFilters,
    fixedFilters,
    itemsPerPage,
    page,
  } = query || {};
  const sort = formatActiveSortToUrlParams(sorting);
  const filters = [...(activeFilters || []), ...(fixedFilters || [])].reduce(
    (acc, { key, operator, type, values }) => {
      const filterKey = `filter[${key}]`;
      const operatorLowerCase = operator?.toLowerCase();
      const typeLowerCase = type?.toLowerCase() || '';
      const isDateType = ['date', 'datetime'].includes(typeLowerCase);
      let filterValues = [...values];

      if (isDateType && operatorLowerCase === 'last') {
        const timestampValue = convertRelativeDateToUnixTimestamp(
          filterValues[0],
          {
            startOf: 'day',
            subtract: true,
          }
        );

        return {
          ...acc,
          [`${filterKey}[GTE]`]: timestampValue,
          [`${filterKey}[LTE]`]: getUnixTimestamp(),
        };
      }

      if (isDateType && operatorLowerCase === 'next') {
        const timestampValue = convertRelativeDateToUnixTimestamp(
          filterValues[0],
          {
            endOf: 'day',
          }
        );

        return {
          ...acc,
          [`${filterKey}[GTE]`]: getUnixTimestamp(),
          [`${filterKey}[LTE]`]: timestampValue,
        };
      }

      if (isDateType && !['last', 'next'].includes(operatorLowerCase)) {
        if (
          operatorLowerCase === 'between' &&
          filterValues.length === 1 &&
          isRelativeDate(filterValues[0])
        ) {
          const [firstValue] = (filterValues || []) as string[];
          const [amount, unit] = firstValue?.split('_') || [];

          if (unit !== 'day') {
            const isNegativeAmount = Number(amount) < 0;
            const secondFilterValue = `${isNegativeAmount ? '-' : ''}1_${unit}`;

            filterValues = [...filterValues, secondFilterValue];
          }
        }

        filterValues = (filterValues as string[]).map((value, idx) => {
          if (isRelativeDate(value)) {
            const [, unit] = value.split('_');

            return convertRelativeDateToUnixTimestamp(value, {
              ...(['lt', 'lte'].includes(operatorLowerCase) || idx === 0
                ? { startOf: unit as OpUnitType }
                : {}),
              ...(['gt', 'gte'].includes(operatorLowerCase) || idx === 1
                ? { endOf: unit as OpUnitType }
                : {}),
            });
          }

          return value;
        });
      }

      if (operatorLowerCase === 'between') {
        return {
          ...acc,
          [`${filterKey}[GTE]`]:
            filterValues[0] || getUnixTimestamp(undefined, { startOf: 'day' }),
          [`${filterKey}[LTE]`]:
            filterValues[1] || getUnixTimestamp(undefined, { endOf: 'day' }),
        };
      }

      const paramKey = `${filterKey}[${operator?.toLowerCase()}]`;

      return {
        ...acc,
        [paramKey]: filterValues.join(','),
      };
    },
    {}
  );

  const queryClient = new Query(apiClient);
  const response = await queryClient.queryView(
    id,
    {
      pageAfter: page,
      pageSize: itemsPerPage,
      ...(isDefined(sort) ? { sort } : {}),
      ...filters,
      metricFacet:
        activeFacets?.length > 0 ? activeFacets.join(',') : undefined,
    },
    requestOptions
  );
  return response.data;
};

export const queryViewData = (
  id: string,
  query: QueryViewDataQuery = {},
  requestOptions = {}
) => {
  return queryOptions({
    queryKey: [QUERY_VIEW_DATA_QUERY_KEY, id, query, requestOptions],
    queryFn: fetchQueryViewData,
  });
};
