import {
  graphQLClient,
  gql,
  type GraphQLRequestOptions,
} from '@afosto/graphql-client';
import { CoreOrderFragment } from '../fragments';
import type { Order } from '../types';

export interface RemoveCustomerFromOrderResponse {
  removeCustomerFromOrder: {
    order: Order;
  };
}

export interface RemoveCustomerFromOrderInput {
  orderId: string;
  requestOptions?: GraphQLRequestOptions<RemoveCustomerFromOrderResponse>;
}

export const removeCustomerFromOrder = async ({
  orderId,
  requestOptions = {},
}: RemoveCustomerFromOrderInput) => {
  const mutation = gql`
    ${CoreOrderFragment}
    mutation RemoveCustomerFromOrder($input: RemoveCustomerFromOrderInput!) {
      removeCustomerFromOrder(input: $input) {
        order {
          ...CoreOrderFragment
        }
      }
    }
  `;

  return graphQLClient.request<RemoveCustomerFromOrderResponse>(
    mutation,
    {
      input: {
        orderId,
      },
    },
    requestOptions
  );
};
