import { Avatar, ListItemAvatar, ListItemText } from '@afosto/components';
import {
  type SearchResultsItem,
  SearchResultsListItem,
} from '../SearchResultsListItem';
import type { DefaultSearchResultListItemProps } from './types';

export const DefaultSearchResultListItem = <T extends SearchResultsItem>(
  props: DefaultSearchResultListItemProps<T>
) => {
  const { item, onSelectSearchResult, showSelector } = props;

  return (
    <SearchResultsListItem
      key={item.id}
      item={item}
      onSelectSearchResult={onSelectSearchResult}
      showSelector={showSelector}
    >
      {'avatar' in item && (
        <ListItemAvatar>
          <Avatar src={item.avatar} variant="rounded" />
        </ListItemAvatar>
      )}
      <ListItemText
        primary={item.label}
        primaryTypographyProps={{ fontWeight: 500, variant: 'h6' }}
        secondary={item.description}
        secondaryTypographyProps={{
          color: (theme) => theme.palette.gray[800],
          variant: 'bodyMedium',
        }}
      />
    </SearchResultsListItem>
  );
};
