import { defineMessages } from 'react-intl';

export const translations = defineMessages({
  add: {
    id: 'actions.add',
    defaultMessage: 'Add',
    description: 'Common label for the add action',
  },
  coupon: {
    id: 'common.coupon',
    defaultMessage: 'Coupon',
    description: 'Common label for coupon',
  },
  discountOnTheOrder: {
    id: 'common.discountOnTheOrder',
    defaultMessage: 'Discount on the order',
    description: 'Common label for discount on order',
  },
  discounts: {
    id: 'common.discounts',
    defaultMessage: 'Discounts',
    description: 'Common label for discounts',
  },
  editOrderDiscount: {
    id: 'common.editOrderDiscount',
    defaultMessage: 'Edit order discount',
    description: 'Common label for edit order discount',
  },
  save: {
    id: 'actions.save',
    defaultMessage: 'Save',
    description: 'Common label for the save action',
  },
});
