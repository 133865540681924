import { ChannelContext } from './ChannelContext';
import { SelectChannelDialog } from '../SelectChannelDialog';
import { useChannelProvider } from './hooks/useChannelProvider';
import type { ChannelProviderProps } from './types';

export const ChannelProvider = (props: ChannelProviderProps) => {
  const { children } = props;

  const channelProviderState = useChannelProvider();
  const { selectChannel, showSelectChannelDialog } = channelProviderState;

  return (
    <ChannelContext.Provider value={channelProviderState}>
      {children}
      <SelectChannelDialog
        onSelectChannel={selectChannel}
        open={showSelectChannelDialog}
      />
    </ChannelContext.Provider>
  );
};
