import {
  createContext,
  useCallback,
  useContext,
  useRef,
  useState,
} from 'react';
import { ConfirmDialog } from './ConfirmDialog';
import type {
  ConfirmationServiceConfirmationPromise,
  ConfirmationServiceOpenConfirmationFn,
  ConfirmationServiceProviderConfirmationState,
  ConfirmationServiceProviderProps,
} from './types';

const initialState: ConfirmationServiceProviderConfirmationState = {
  catchOnCancel: true,
  disableEscapeKeyDown: false,
  title: '',
  description: undefined,
  buttons: undefined,
  dialogProps: {},
};
const ConfirmationServiceContext =
  createContext<ConfirmationServiceOpenConfirmationFn>(() =>
    Promise.resolve('accepted')
  );

export const useConfirm = () => useContext(ConfirmationServiceContext);

export const ConfirmationServiceProvider = (
  props: ConfirmationServiceProviderProps
) => {
  const { children } = props;
  const [confirmationState, setConfirmationState] =
    useState<ConfirmationServiceProviderConfirmationState>(initialState);
  const [isOpen, setIsOpen] = useState(false);
  const awaitingPromiseRef =
    useRef<ConfirmationServiceConfirmationPromise | null>(null);
  const {
    catchOnCancel,
    disableEscapeKeyDown,
    dialogProps,
    ...otherConfirmationState
  } = confirmationState || {};

  const openConfirmation = useCallback(
    (options: ConfirmationServiceProviderConfirmationState) => {
      setConfirmationState({ ...initialState, ...options });
      setIsOpen(true);

      return new Promise<string>((resolve, reject) => {
        // save the promise result to the ref
        awaitingPromiseRef.current = { resolve, reject };
      });
    },
    []
  );

  const handleClose = (response?: string) => {
    // Mostly always you don't need to handle canceling of alert dialog
    // So shutting up the unhandledPromiseRejection errors
    if (catchOnCancel && awaitingPromiseRef.current) {
      awaitingPromiseRef.current.reject(response || 'cancelled');
    }

    if (!catchOnCancel && awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve('cancel');
    }

    setIsOpen(false);
  };

  const handleSubmit = (response?: string) => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve(response || 'accepted');
    }

    setIsOpen(false);
  };

  const handleExited = (node: HTMLElement) => {
    setConfirmationState(initialState);
    awaitingPromiseRef.current = null;

    if (dialogProps?.TransitionProps?.onExited) {
      dialogProps.TransitionProps.onExited(node);
    }
  };

  return (
    <>
      <ConfirmationServiceContext.Provider value={openConfirmation}>
        {children}
      </ConfirmationServiceContext.Provider>
      <ConfirmDialog
        {...otherConfirmationState}
        {...(dialogProps || {})}
        disableEscapeKeyDown={disableEscapeKeyDown}
        onClose={handleClose}
        onSubmit={handleSubmit}
        open={isOpen}
        TransitionProps={{
          ...(dialogProps?.TransitionProps || {}),
          onExited: handleExited,
        }}
      />
    </>
  );
};
