import {
  graphQLClient,
  gql,
  type GraphQLRequestOptions,
} from '@afosto/graphql-client';
import { CoreOrderFragment } from '../fragments';
import type { Order } from '../types';

export interface RemoveAdjustmentsFromOrderResponse {
  removeAdjustmentsFromOrder: {
    order: Order;
  };
}

export interface RemoveAdjustmentsFromOrderInput {
  orderId: string;
  adjustmentIds: string[];
  requestOptions?: GraphQLRequestOptions<RemoveAdjustmentsFromOrderResponse>;
}

export const removeAdjustmentsFromOrder = async ({
  orderId,
  adjustmentIds,
  requestOptions = {},
}: RemoveAdjustmentsFromOrderInput) => {
  const mutation = gql`
    ${CoreOrderFragment}
    mutation RemoveAdjustmentsFromOrder(
      $input: RemoveAdjustmentsFromOrderInput!
    ) {
      removeAdjustmentsFromOrder(input: $input) {
        order {
          ...CoreOrderFragment
        }
      }
    }
  `;

  return graphQLClient.request<RemoveAdjustmentsFromOrderResponse>(
    mutation,
    {
      input: {
        orderId,
        adjustmentIds,
      },
    },
    requestOptions
  );
};
