import {
  graphQLClient,
  gql,
  type GraphQLRequestOptions,
} from '@afosto/graphql-client';
import type { AdjustmentInput } from './addAdjustmentsToOrder';

export interface AddAdjustmentsToOrderItemsInput {
  itemIds: string[];
  adjustments: AdjustmentInput[];
  requestOptions?: GraphQLRequestOptions<object>;
}

export const addAdjustmentsToOrderItems = async ({
  itemIds,
  adjustments,
  requestOptions = {},
}: AddAdjustmentsToOrderItemsInput) => {
  const mutation = gql`
    mutation AddAdjustmentsToOrderItems(${itemIds
      .map((itemId, idx) => `$input${idx}: AddAdjustmentsToOrderItemInput!`)
      .join(', ')}) {
      ${itemIds
        .map((itemId, idx) => {
          return `item${idx}: addAdjustmentsToOrderItem(input: $input${idx}) {
            item {
              id
            }
          }`;
        })
        .join('\n')}
    }
  `;

  return graphQLClient.request<object>(
    mutation,
    itemIds.reduce((acc, itemId, idx) => {
      return {
        ...acc,
        [`input${idx}`]: {
          itemId,
          adjustments,
        },
      };
    }, {}),
    requestOptions
  );
};
