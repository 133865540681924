import { defineMessages } from 'react-intl';

export const translations = defineMessages({
  title: {
    id: 'components.manuallyAddProductFormDialog.title',
    defaultMessage: 'Manually add product',
    description: 'Title of the manually add product form dialog',
  },
  add: {
    id: 'actions.add',
    defaultMessage: 'Add',
    description: 'Common label for the add action',
  },
});
