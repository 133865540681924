import { useIntl } from 'react-intl';
import { Card, Typography } from '@afosto/components';
import { CardSelector } from '../../../CardSelector';
import { Fieldset } from '../../../Fieldset';
import { useOrder } from '../../../OrderProvider/hooks/useOrder';
import { User } from '../../../../icons/solid';
import { getFullName } from '../../../../utils';
import { translations } from './translations';

export const CustomerFormSection = () => {
  const intl = useIntl();
  const { openAddCustomerDialog, order } = useOrder();
  const hasCustomer = !!order?.customer?.contact?.id;
  const { contact } = order?.customer || {};

  return (
    <Fieldset title={intl.formatMessage(translations.customer)}>
      {!hasCustomer && (
        <CardSelector
          Icon={User}
          label={intl.formatMessage(translations.addCustomer)}
          onSelect={openAddCustomerDialog}
        />
      )}
      {hasCustomer && contact && (
        <Card sx={{ px: 2.5, py: 2 }}>
          <Typography fontWeight={500} mb={0.5} variant="h6">
            {getFullName(contact)}
          </Typography>
          <Typography color="gray.800">{contact.email}</Typography>
        </Card>
      )}
    </Fieldset>
  );
};
