import { clsx } from 'clsx';
import { Typography } from '@afosto/components';
import { AfostoLogo } from '../AfostoLogo';
import * as Styled from './SplashScreen.styles';
import type { SplashScreenProps } from './types';

export const SplashScreen = (props: SplashScreenProps) => {
  const { message } = props;

  return (
    <Styled.Container>
      <AfostoLogo height={32} dark />
      <Styled.Progress className={clsx({ 'has-message': !!message })} />
      {message && <Typography color="gray.500">{message}</Typography>}
    </Styled.Container>
  );
};
