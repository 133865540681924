import {
  Budbee,
  DeliveryMatch,
  Dgo,
  Dhl,
  Dpd,
  Fedex,
  Gls,
  Postnl,
  Sendcloud,
  Ups,
} from '../icons/carriers';
import {
  ABNAmro,
  ASNBank,
  Bunq,
  Handelsbanken,
  ING,
  Knab,
  Rabobank,
  RegioBank,
  Revolut,
  SNSBank,
  Triodos,
  VanLanschot,
} from '../icons/paymentIssuers';
import {
  Afterpay,
  Alipay,
  ApplePay,
  Bacs,
  Bancontact,
  Bank,
  BankTransfer,
  Billink,
  CarteBleue,
  Cash,
  CashOnDelivery,
  CreditCard,
  EPSUberweisung,
  Fashioncheque,
  Giropay,
  GivaCard,
  Ideal,
  In3,
  Klarna,
  ManualCard,
  ManualGiftCard,
  Multibanco,
  OnAccount,
  Payconiq,
  PayPal,
  Paysafe,
  Pin,
  PodiumCadeaukaart,
  Postepay,
  Przelewy24,
  Spraypay,
  VVVCadeaukaart,
  Walley,
  WebshopGiftcard,
  WeChat,
  YourGift,
} from '../icons/paymentMethods';

export const PAYMENT_METHOD_ICON_MAPPING = {
  ideal: Ideal,
  card: CreditCard,
  bacs_direct_debit: Bacs,
  bank_transfer: BankTransfer,
  on_account: OnAccount,
  cod: CashOnDelivery,
  spraypay: Spraypay,
  sofort: Klarna,
  paypal_express: PayPal,
  paypal: PayPal,
  mistercash_bancontact: Bancontact,
  paysafecard: Paysafe,
  giropay: Giropay,
  postepay: Postepay,
  carte_bleue: CarteBleue,
  afterpay: Afterpay,
  webshopgiftcard: WebshopGiftcard,
  fashioncheque: Fashioncheque,
  podiumcadeaukaart: PodiumCadeaukaart,
  yourgift: YourGift,
  givacard: GivaCard,
  vvv_cadeaukaart: VVVCadeaukaart,
  wechat_pay: WeChat,
  alipay: Alipay,
  payconiq: Payconiq,
  multibanco: Multibanco,
  eps_uberweisung: EPSUberweisung,
  przelewy24: Przelewy24,
  billink: Billink,
  klarna: Klarna,
  klarna_now: Klarna,
  klarna_over_time: Klarna,
  in3: In3,
  cash: Cash,
  manual_gift_card: ManualGiftCard,
  manual_card: ManualCard,
  pin: Pin,
  apple_pay: ApplePay,
  pay_by_bank: Bank,
  walley: Walley,
};

export const PAYMENT_ISSUER_ICON_MAPPING = {
  abn_amro: ABNAmro,
  ing: ING,
  rabobank: Rabobank,
  asn_bank: ASNBank,
  bunq: Bunq,
  handelsbanken: Handelsbanken,
  knab: Knab,
  regiobank: RegioBank,
  revolut: Revolut,
  sns_bank: SNSBank,
  triodos: Triodos,
  van_lanschot: VanLanschot,
};

export const CARRIER_ICON_MAPPING = {
  budbee: Budbee,
  delivery_match: DeliveryMatch,
  dgo: Dgo,
  dhl: Dhl,
  dhl_de: Dhl,
  dhl_express: Dhl,
  dpd: Dpd,
  fedex: Fedex,
  gls: Gls,
  gls_nl: Gls,
  postnl: Postnl,
  sendcloud: Sendcloud,
  ups: Ups,
};
