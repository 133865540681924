import { defineMessages } from 'react-intl';

export const translations = defineMessages({
  searchAnExistingContact: {
    id: 'common.searchAnExistingContact',
    defaultMessage: 'Search an existing contact',
    description: 'Common label for search an existing contact',
  },
  searchAnExistingOrganisation: {
    id: 'common.searchAnExistingOrganisation',
    defaultMessage: 'Search an existing organisation',
    description: 'Common label for search an existing organisation',
  },
  startASearch: {
    id: 'feedback.startASearch.message',
    defaultMessage: 'Start a search',
    description: 'Feedback message for start a search',
  },
});
