import { useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { AdjustmentActionsFormSection } from '../AdjustmentActionsFormSection';
import { AdjustmentsFormSection } from '../AdjustmentsFormSection';
import { CouponsFormSection } from '../CouponsFormSection';
import { DiscountFormSection } from '../DiscountFormSection';
import { FormDialog } from '../FormDialog';
import { useOrder } from '../OrderProvider/hooks/useOrder';
import { DiscountActionsFormSection } from './components/DiscountActionsFormSection';
import { CouponFormSection } from './components/CouponFormSection';
import {
  couponValidationSchema,
  orderDiscountValidationSchema,
} from './validationSchema';
import { translations } from './translations';
import type { UseFormReturn } from '@afosto/components';
import type {
  DiscountAction,
  ManageOrderDiscountFormCouponData,
  ManageOrderDiscountFormData,
  ManageOrderDiscountFormDialogProps,
  ManagerOrderDiscountFormOrderDiscountData,
} from './types';
import type { Adjustment, Coupon } from '../../types';

export const ManageOrderDiscountFormDialog = (
  props: ManageOrderDiscountFormDialogProps
) => {
  const { formProps, title, TransitionProps, ...otherProps } = props;
  const { onExited } = TransitionProps || {};

  const intl = useIntl();
  const {
    addAdjustmentsToOrder,
    addCouponToOrder,
    closeManageOrderDiscountDialog,
    order,
    removeAdjustmentsFromOrder,
    removeCouponFromOrder,
    updateAdjustmentForOrder,
  } = useOrder();

  const formRef = useRef<UseFormReturn | null>(null);
  const [discountAction, setDiscountAction] = useState<DiscountAction>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedAdjustment, setSelectedAdjustment] =
    useState<Adjustment | null>(null);

  const submitLabelTranslationKey = (
    selectedAdjustment ? 'save' : 'add'
  ) as keyof typeof translations;
  const titleTranslationKey = (
    selectedAdjustment ? 'editOrderDiscount' : discountAction
  ) as keyof typeof translations;

  const defaultValues = {
    discount: null,
  };

  const handleSelectDiscountAction = (action: DiscountAction) => () => {
    setDiscountAction(action);

    if (!action) {
      setSelectedAdjustment(null);
    }

    if (action === 'discountOnTheOrder') {
      formRef.current?.reset({
        amount: '',
        description: '',
        type: 'percentage',
      });
    }

    if (action === 'coupon') {
      formRef.current?.reset({
        coupon: '',
      });
    }
  };

  const handleEditAdjustment = (adjustment: Adjustment) => () => {
    setSelectedAdjustment(adjustment);
    setDiscountAction('discountOnTheOrder');

    formRef.current?.reset({
      amount: adjustment?.isPercentage
        ? adjustment?.amount || ''
        : adjustment?.outcome?.amount || '',
      description: adjustment?.description || '',
      type: adjustment
        ? adjustment.isPercentage
          ? 'percentage'
          : 'amount'
        : 'percentage',
    });
  };

  const handleRemoveAdjustment = async () => {
    try {
      if (!selectedAdjustment) {
        return;
      }

      await removeAdjustmentsFromOrder({
        adjustmentIds: [selectedAdjustment.id],
      });

      handleSelectDiscountAction(null)();
    } catch {
      // Do something with error.
    }
  };

  const handleRemoveCoupon = (coupon: Coupon) => async () => {
    try {
      if (!coupon) {
        return;
      }

      await removeCouponFromOrder({ coupon: coupon.code });

      handleSelectDiscountAction(null)();
    } catch {
      //TODO: Do something with error.
    }
  };

  const handleExited = (node: HTMLElement) => {
    setDiscountAction(null);
    setSelectedAdjustment(null);
    setIsSubmitting(false);

    if (onExited && typeof onExited === 'function') {
      onExited(node);
    }
  };

  const handleSubmit = async (data: ManageOrderDiscountFormData) => {
    try {
      setIsSubmitting(true);

      if (!discountAction) {
        setIsSubmitting(false);
        return;
      }

      if (discountAction === 'coupon') {
        const formData = data as ManageOrderDiscountFormCouponData;
        await addCouponToOrder({ coupon: formData.coupon });
      }

      if (discountAction === 'discountOnTheOrder') {
        const formData = data as ManagerOrderDiscountFormOrderDiscountData;
        const discountAmount =
          typeof formData.amount === 'string'
            ? parseInt(formData.amount, 10)
            : formData.amount;

        if (selectedAdjustment) {
          await updateAdjustmentForOrder({
            adjustment: {
              ...selectedAdjustment,
              amount: discountAmount,
              description: formData.description,
              isPercentage: formData.type === 'percentage',
            },
          });
        } else {
          await addAdjustmentsToOrder({
            adjustments: [
              {
                amount: discountAmount,
                description: formData.description,
                isPercentage: formData.type === 'percentage',
              },
            ],
          });
        }
      }

      closeManageOrderDiscountDialog();
      setIsSubmitting(false);
    } catch (error) {
      // TODO: Do something with error.

      setIsSubmitting(false);
      return Promise.reject(error);
    }
  };

  return (
    <FormDialog
      {...otherProps}
      dialogHeaderProps={
        discountAction
          ? {
              onBack: handleSelectDiscountAction(null),
              title: intl.formatMessage(translations[titleTranslationKey]),
            }
          : {}
      }
      formProps={{
        submitLabel: intl.formatMessage(
          translations[submitLabelTranslationKey]
        ),
        validationSchema:
          discountAction === 'coupon'
            ? couponValidationSchema
            : orderDiscountValidationSchema,
        ...(formProps || {}),
        defaultValues,
        formRef,
        hideSubmitButton: !discountAction,
        isSubmitting,
      }}
      TransitionProps={{
        ...(TransitionProps || {}),
        onExited: handleExited,
      }}
      onSubmit={handleSubmit}
      title={title || intl.formatMessage(translations.discounts)}
    >
      {!discountAction && (
        <>
          <DiscountActionsFormSection
            discountAction={discountAction}
            onSelectAction={handleSelectDiscountAction}
          />
          <AdjustmentsFormSection
            adjustments={order?.adjustments || []}
            onEdit={handleEditAdjustment}
          />
          <CouponsFormSection
            coupons={order?.coupons || []}
            onRemove={handleRemoveCoupon}
          />
        </>
      )}
      {discountAction === 'discountOnTheOrder' && (
        <>
          <DiscountFormSection />
          {selectedAdjustment && (
            <AdjustmentActionsFormSection onRemove={handleRemoveAdjustment} />
          )}
        </>
      )}
      {discountAction === 'coupon' && <CouponFormSection />}
    </FormDialog>
  );
};
