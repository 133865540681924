import { BottomNavigation, styled } from '@afosto/components';

export const BottomNavigationContainer = styled(BottomNavigation)`
  height: 88px;

  > a,
  button {
    gap: ${(props) => props.theme.spacing(0.5)};

    .MuiBottomNavigationAction-label {
      color: ${(props) => props.theme.palette.gray[800]};
      font-size: ${(props) => props.theme.typography.bodyMedium.fontSize};
      line-height: ${(props) => props.theme.typography.bodyMedium.lineHeight};
    }

    .MuiSvgIcon-root {
      color: ${(props) => props.theme.palette.gray[600]};
      font-size: 24px;
    }

    &.Mui-selected {
      .MuiSvgIcon-root {
        color: ${(props) => props.theme.palette.primary.main};
      }
    }
  }
`;
