import { SvgIcon, type SvgIconProps } from '@afosto/components';

export const ManualGiftCard = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 30 20">
    <rect
      width="27"
      height="19"
      x=".5"
      y=".5"
      fill="#fff"
      stroke="#D8E0E7"
      rx="3.5"
    />
    <path
      fill="#4A6579"
      d="M16.36 3.33h.05a2.05 2.05 0 0 1 1.81 3h.97c.81 0 1.48.68 1.48 1.5v6c0 .83-.67 1.5-1.48 1.5H8.8a1.5 1.5 0 0 1-1.48-1.5v-6c0-.82.67-1.5 1.48-1.5h.97a2.05 2.05 0 0 1 1.81-3h.05c.74 0 1.43.4 1.8 1.05l.56.96.56-.96a2.08 2.08 0 0 1 1.8-1.05Zm-1.65 3h1.7c.5 0 .92-.42.92-.93a.93.93 0 0 0-.92-.94h-.05a.97.97 0 0 0-.84.49l-.8 1.38Zm-3.07-1.87h-.05a.93.93 0 0 0-.92.94c0 .51.41.93.92.93h1.7l-.8-1.38a.97.97 0 0 0-.85-.5Zm-2.83 7.87v1.5H19.2v-1.5H8.8Zm10.38-1.5v-3h-3.85l.94 1.14c.2.24.16.58-.07.8a.55.55 0 0 1-.78-.08L14 7.96 12.57 9.7a.55.55 0 0 1-.78.07.57.57 0 0 1-.07-.79l.94-1.14H8.81v3H19.2Z"
    />
  </SvgIcon>
);
