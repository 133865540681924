import {
  graphQLClient,
  gql,
  type GraphQLRequestOptions,
} from '@afosto/graphql-client';
import { CoreInvoiceFragment } from '../fragments';
import type { Invoice } from '../types';

export interface InvoiceItemsResponse {
  invoiceItems: {
    invoice: Invoice;
  };
}

export interface InvoiceItemsInput {
  items: string[];
  requestOptions?: GraphQLRequestOptions<InvoiceItemsResponse>;
}

export const invoiceItems = async ({
  items,
  requestOptions = {},
}: InvoiceItemsInput) => {
  const mutation = gql`
    ${CoreInvoiceFragment}
    mutation InvoiceItems($input: InvoiceItemsInput!) {
      invoiceItems(input: $input) {
        invoice {
          ...CoreInvoiceFragment
        }
      }
    }
  `;

  return graphQLClient.request<InvoiceItemsResponse>(
    mutation,
    {
      input: {
        items,
      },
    },
    requestOptions
  );
};
