export const getCurrencySymbol = (locale: string, currency = 'EUR') => {
  try {
    const parts = new Intl.NumberFormat(locale, {
      style: 'currency',
      currencyDisplay: 'narrowSymbol',
      currency,
    }).formatToParts();
    const currencyPart = parts.find((part) => part.type === 'currency');

    return currencyPart?.value;
  } catch {
    return '';
  }
};
