import { defineMessages } from 'react-intl';

export const translations = defineMessages({
  coupons: {
    id: 'common.coupons',
    defaultMessage: 'Coupons',
    description: 'Common label for coupons',
  },
  remove: {
    id: 'actions.remove',
    defaultMessage: 'Remove',
    description: 'Common label for the remove action',
  },
});
