import { useIntl } from 'react-intl';
import { ActionsList } from '../../../ActionsList';
import { CardSelector } from '../../../CardSelector';
import { Fieldset } from '../../../Fieldset';
import { BadgePercent, Ticket } from '../../../../icons/solid';
import { translations } from './translations';
import type { DiscountActionsFormSectionProps } from './types';

export const DiscountActionsFormSection = (
  props: DiscountActionsFormSectionProps
) => {
  const { onSelectAction } = props;

  const intl = useIntl();

  return (
    <Fieldset title={intl.formatMessage(translations.actions)}>
      <ActionsList>
        <CardSelector
          Icon={BadgePercent}
          label={intl.formatMessage(translations.discountOnTheOrder)}
          onSelect={onSelectAction('discountOnTheOrder')}
        />
        <CardSelector
          Icon={Ticket}
          label={intl.formatMessage(translations.coupon)}
          onSelect={onSelectAction('coupon')}
        />
      </ActionsList>
    </Fieldset>
  );
};
