import { MenuList as AfMenuList, styled } from '@afosto/components';

export const Container = styled('main')`
  background-color: ${(props) => props.theme.palette.common.white};
  display: grid;
  grid-template-rows: calc(-64px + -88px + 100vh);
  grid-template-columns: 30% 70%;
  overflow: hidden;
`;

export const LeftLayout = styled('div')`
  border-right: 1px solid ${(props) => props.theme.palette.divider};
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: 1fr;
  overflow: hidden;
`;

export const TitleHeader = styled('div')`
  border-bottom: 1px solid ${(props) => props.theme.palette.divider};
  display: flex;
  padding: 14px ${(props) => props.theme.spacing(2.5)};
`;

export const MenuList = styled(AfMenuList)`
  padding: ${(props) => props.theme.spacing(1.5)};

  .MuiMenuItem-root {
    color: ${(props) => props.theme.palette.gray[600]};
    font-weight: 500;
    padding: ${(props) => props.theme.spacing(1.5)}
      ${(props) => props.theme.spacing(2)};

    &.Mui-selected {
      background-color: ${(props) => props.theme.palette.gray[75]};
      color: ${(props) => props.theme.palette.primary.main};
    }
  }
`;
