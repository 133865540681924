import { defineMessages } from 'react-intl';

export const translations = defineMessages({
  backToPaymentMethods: {
    id: 'actions.backToPaymentMethods',
    defaultMessage: 'Back to payment methods',
    description: 'Common label for the payment methods action',
  },
  couldNotProcessPayment: {
    id: 'feedback.couldNotProcessPayment.message',
    defaultMessage: 'Could not process payment',
    description: 'Message of the could not process payment feedback',
  },
  paymentCancelledMessage: {
    id: 'feedback.paymentCancelled.message',
    defaultMessage: 'Start a new payment by selecting a payment method',
    description: 'Message of the payment cancelled feedback',
  },
  paymentCancelledTitle: {
    id: 'feedback.paymentCancelled.title',
    defaultMessage: 'Payment cancelled',
    description: 'Title of the payment cancelled feedback',
  },
  paymentExpiredMessage: {
    id: 'feedback.paymentExpired.message',
    defaultMessage:
      'Try again or start a new payment by selecting a new payment method',
    description: 'Message of the payment expired feedback',
  },
  paymentExpiredTitle: {
    id: 'feedback.paymentExpired.title',
    defaultMessage: 'Payment expired',
    description: 'Title of the payment expired feedback',
  },
  retry: {
    id: 'actions.retry',
    defaultMessage: 'Retry',
    description: 'Common label for the retry action',
  },
});
