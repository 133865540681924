import { SvgIcon, type SvgIconProps } from '@afosto/components';

export const CreditCard = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 30 20">
    <rect width="28" height="20" fill="#fff" rx="4" />
    <mask
      id="CreditCardMask"
      width="28"
      height="20"
      x="0"
      y="0"
      maskUnits="userSpaceOnUse"
    >
      <path
        fill="#fff"
        d="M24.5 0h-21C1.57 0 0 1.5 0 3.33v13.34A3.42 3.42 0 0 0 3.5 20h21c1.93 0 3.5-1.5 3.5-3.33V3.33A3.42 3.42 0 0 0 24.5 0Z"
      />
    </mask>
    <g mask="url(#CreditCardMask)">
      <path
        fill="url(#CreditCardGradient-b)"
        d="M24.5 0h-21C1.57 0 0 1.5 0 3.33v13.34A3.42 3.42 0 0 0 3.5 20h21c1.93 0 3.5-1.5 3.5-3.33V3.33A3.42 3.42 0 0 0 24.5 0Z"
      />
      <path
        fill="url(#CreditCardGradient-c)"
        d="M32.81 57.5c15.22 0 27.56-11.75 27.56-26.25S48.03 5 32.81 5C17.6 5 5.25 16.75 5.25 31.25S17.59 57.5 32.81 57.5Z"
        opacity=".1"
      />
      <path
        fill="url(#CreditCardGradient-d)"
        d="M5.69 37.5c7.97 0 14.43-6.16 14.43-13.75C20.13 16.15 13.66 10 5.7 10c-8 0-14.45 6.16-14.45 13.75 0 7.6 6.46 13.75 14.44 13.75Z"
        opacity=".1"
      />
      <path
        fill="url(#CreditCardGradient-e)"
        d="M37.19 18.33c12.8 0 23.19-9.88 23.19-22.08 0-12.2-10.39-22.08-23.2-22.08C24.39-25.83 14-15.95 14-3.75c0 12.2 10.38 22.08 23.19 22.08Z"
        opacity=".1"
      />
      <g filter="url(#CreditCardFilter)">
        <path
          fill="#000"
          d="M8.75 5.83H4.37a.85.85 0 0 0-.87.84v2.5c0 .46.4.83.88.83h4.37c.48 0 .88-.37.88-.83v-2.5c0-.46-.4-.84-.88-.84Z"
        />
      </g>
      <path
        fill="url(#CreditCardGradient-g)"
        d="M8.75 5.83H4.37a.85.85 0 0 0-.87.84v2.5c0 .46.4.83.88.83h4.37c.48 0 .88-.37.88-.83v-2.5c0-.46-.4-.84-.88-.84Z"
      />
      <path
        fill="#7A3802"
        d="M5.25 6.67h-.88v2.5h.88v-2.5Zm1.75 0h-.88v2.5H7v-2.5Zm1.75 0h-.88v2.5h.88v-2.5Z"
        opacity=".3"
      />
      <path
        fill="#fff"
        fillOpacity=".8"
        d="M7.88 12.5H3.5v.83h4.38v-.83Zm-1.76 1.67H3.5V15h2.62v-.83Zm3.5 0H7V15h2.62v-.83Zm3.5 0H10.5V15h2.62v-.83Zm.01-1.67H8.75v.83h4.38v-.83Zm5.25 0H14v.83h4.38v-.83Zm5.25 0h-4.38v.83h4.38v-.83Z"
      />
    </g>
    <defs>
      <linearGradient
        id="CreditCardGradient-b"
        x1="-14"
        x2="4.92"
        y1="10"
        y2="-16.49"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#060B0E" />
        <stop offset="1" stopColor="#254764" />
      </linearGradient>
      <linearGradient
        id="CreditCardGradient-c"
        x1="16.67"
        x2="14.12"
        y1="19.7"
        y2="12.36"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" stopOpacity="0" />
        <stop offset="1" stopColor="#fff" />
      </linearGradient>
      <linearGradient
        id="CreditCardGradient-d"
        x1="-.73"
        x2="-3.64"
        y1="39.02"
        y2="26.72"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" stopOpacity="0" />
        <stop offset="1" stopColor="#fff" />
      </linearGradient>
      <linearGradient
        id="CreditCardGradient-e"
        x1="42.37"
        x2="47.05"
        y1="-3.75"
        y2="16"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" stopOpacity="0" />
        <stop offset="1" stopColor="#fff" />
      </linearGradient>
      <linearGradient
        id="CreditCardGradient-g"
        x1="9.63"
        x2="9.63"
        y1="5.83"
        y2="10"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FAD961" />
        <stop offset="1" stopColor="#F7955D" />
      </linearGradient>
      <filter
        id="CreditCardFilter"
        width="8.13"
        height="6.17"
        x="2.5"
        y="5.83"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation=".5" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_2_4971" />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_2_4971"
          result="shape"
        />
      </filter>
    </defs>
  </SvgIcon>
);
