import { useIntl } from 'react-intl';
import { Fields, Grid, useFormContext } from '@afosto/components';
import { getCurrencySymbol } from '../../utils';
import { translations } from './translations';
import type { MouseEvent } from 'react';
import type {
  DiscountFormSectionProps,
  DiscountType,
  DiscountTypeOption,
} from './types';

export const DiscountFormSection = (props: DiscountFormSectionProps) => {
  const { currency } = props;

  const { getValues, reset, watch } = useFormContext();
  const intl = useIntl();

  const type = watch('type');

  const typeOptions: DiscountTypeOption[] = [
    {
      label: `${intl.formatMessage(translations.percentage)} (%)`,
      value: 'percentage',
    },
    {
      label: `${intl.formatMessage(
        translations.fixedAmount
      )} (${getCurrencySymbol(intl.locale, currency)})`,
      value: 'amount',
    },
  ];

  const handleChangeType = (
    event: MouseEvent<HTMLElement>,
    value: DiscountType
  ) => {
    reset({
      ...getValues(),
      amount: '',
      type: value || '',
    });
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Fields.OptionSwitch
          name="type"
          onChange={handleChangeType}
          options={typeOptions}
          size="large"
        />
      </Grid>
      {type === 'percentage' && (
        <Grid item xs={12}>
          <Fields.TextField
            label={intl.formatMessage(translations.percentage)}
            name="amount"
            fullWidth
          />
        </Grid>
      )}
      {type === 'amount' && (
        <Grid item xs={12}>
          <Fields.CurrencyField
            label={intl.formatMessage(translations.amount)}
            name="amount"
            currency={currency}
            fullWidth
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <Fields.TextField
          label={intl.formatMessage(translations.description)}
          name="description"
          fullWidth
        />
      </Grid>
    </Grid>
  );
};
