import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { useAuthentication } from '@afosto/auth-react';
import { useChannel } from '../ChannelProvider/hooks/useChannel';
import { usePrint } from '../PrintProvider/hooks/usePrint';
import { SplashScreen } from '../SplashScreen';
import { translations } from './translations';
import type { AppSplashScreenProps } from './types';

export const AppSplashScreen = (props: AppSplashScreenProps) => {
  const { children } = props;

  const intl = useIntl();
  const location = useLocation();
  const { isAuthenticated, isAuthorizing } = useAuthentication();
  const { isInitializingChannel } = useChannel();
  const { isInitializingPrintService } = usePrint();

  const isValidRoute = !['/auth/callback', '/auth/authorize'].includes(
    location?.pathname
  );
  const isRunningAuthentication = !isAuthenticated && isAuthorizing;

  if (
    isValidRoute &&
    (isRunningAuthentication ||
      isInitializingChannel ||
      isInitializingPrintService)
  ) {
    let translationKey: keyof typeof translations = 'authenticating';

    if (isRunningAuthentication) {
      translationKey = 'authenticating';
    } else if (isInitializingChannel) {
      translationKey = 'initializingPosChannels';
    } else if (isInitializingPrintService) {
      translationKey = 'initializingPrintService';
    }

    return (
      <SplashScreen
        message={intl.formatMessage(translations[translationKey])}
      />
    );
  }

  return children;
};
