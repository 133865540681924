import { useIntl } from 'react-intl';
import { useOrder } from '../OrderProvider/hooks/useOrder';
import { ShortcutCard } from '../ShortcutCard';
import { BadgePercent } from '../../icons/solid';
import { translations } from './translations';

export const OrderDiscountShortcut = () => {
  const intl = useIntl();
  const { openManageOrderDiscountDialog, order } = useOrder();
  const hasCoupons = (order?.coupons || []).length > 0;
  const hasManualAdjustments = (order?.adjustments || []).some(
    (adjustment) => !adjustment?.outcome?.ruleId
  );
  const hasDiscount = hasCoupons || hasManualAdjustments;

  return (
    <ShortcutCard
      color={hasDiscount ? 'success' : undefined}
      disabled={!order}
      label={intl.formatMessage(translations.discounts)}
      Icon={BadgePercent}
      onClick={openManageOrderDiscountDialog}
    />
  );
};
