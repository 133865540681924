import {
  graphQLClient,
  gql,
  type GraphQLRequestOptions,
} from '@afosto/graphql-client';

export interface RemoveAdjustmentsFromOrderItemsResponse {
  [key: string]: {
    item: {
      id: string;
    };
  };
}

export interface RemoveAdjustmentsFromOrderItemsInput {
  itemIds: string[];
  adjustmentIds: string[];
  requestOptions?: GraphQLRequestOptions<RemoveAdjustmentsFromOrderItemsResponse>;
}

export const removeAdjustmentsFromOrderItems = async ({
  itemIds,
  adjustmentIds,
  requestOptions = {},
}: RemoveAdjustmentsFromOrderItemsInput) => {
  const mutation = gql`
    mutation RemoveAdjustmentsFromOrderItem(${itemIds
      .map(
        (itemId, idx) => `$input${idx}: RemoveAdjustmentsFromOrderItemInput!`
      )
      .join(', ')}) {
      ${itemIds
        .map((itemId, idx) => {
          return `item${idx}: removeAdjustmentsFromOrderItem(input: $input${idx}) {
            item {
              id
            }
          }`;
        })
        .join('\n')}
    }
  `;

  return graphQLClient.request<RemoveAdjustmentsFromOrderItemsResponse>(
    mutation,
    itemIds.reduce((acc, itemId, idx) => {
      return {
        ...acc,
        [`input${idx}`]: {
          itemId,
          adjustmentIds,
        },
      };
    }, {}),
    requestOptions
  );
};
