import { Button, styled } from '@afosto/components';

export const Container = styled('div')`
  border-top: 1px solid ${(props) => props.theme.palette.divider};
  padding: ${(props) => props.theme.spacing(2.5)};
`;

export const CheckoutButton = styled(Button)`
  font-size: ${(props) => props.theme.typography.bodyLarge.fontSize};
  height: 56px;
  justify-content: space-between;
  padding: ${(props) => props.theme.spacing(2.5)};
`;

export const Summary = styled('div')`
  display: grid;
  grid-template-columns: repeat(2, auto);
  justify-content: space-between;
  gap: ${(props) => props.theme.spacing(2)};
  padding-bottom: ${(props) => props.theme.spacing(2)};
`;
