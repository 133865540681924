import { SvgIcon, type SvgIconProps } from '@afosto/components';

export const ManualCard = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 30 20">
    <rect
      width="27"
      height="19"
      x=".5"
      y=".5"
      fill="#fff"
      stroke="#DBE2EA"
      rx="3.5"
    />
    <path
      fill="#4B6373"
      d="M7.33 14.2c0 .62.5 1.13 1.11 1.13h11.12c.6 0 1.1-.5 1.1-1.14V10H7.34v4.19Zm4.45-1.63c0-.16.12-.28.28-.28h3.14c.16 0 .28.12.28.28v.95c0 .16-.12.29-.28.29h-3.14a.28.28 0 0 1-.28-.29v-.95Zm-2.97 0c0-.16.13-.28.28-.28h1.67c.15 0 .28.12.28.28v.95c0 .16-.13.29-.28.29H9.09a.28.28 0 0 1-.28-.29v-.95Zm11.86-6.76v1.14H7.33V5.81c0-.63.5-1.14 1.11-1.14h11.12c.6 0 1.1.5 1.1 1.14Z"
    />
  </SvgIcon>
);
