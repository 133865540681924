import dayjs from 'dayjs';

export interface ConvertedGs1Values {
  batchNumber?: string;
  bestBefore?: number;
  gtin?: string;
  serialNumber?: string;
  sku?: string;
}

const convertGtin = (value: string) => {
  if (value.startsWith('000000')) {
    return value.replace(/^(000000)/, '');
  }

  if (value.startsWith('0')) {
    return value.substring(1, value.length);
  }

  return value;
};

const convertGs1DateToUnix = (value: string) => {
  const date = dayjs(value, 'YYMMDD');

  if (date.isValid()) {
    return date.startOf('day').valueOf();
  }

  return null;
};

export const convertGs1Values = (values = {}): ConvertedGs1Values => {
  return Object.keys(values).reduce((acc, applicationIdentifier) => {
    const value = values[applicationIdentifier as keyof typeof values];

    if (`${applicationIdentifier}` === '01') {
      return { ...acc, gtin: convertGtin(value) };
    }

    if (`${applicationIdentifier}` === '10') {
      return { ...acc, batchNumber: value };
    }

    if (['15', '17'].includes(`${applicationIdentifier}`)) {
      return { ...acc, bestBefore: convertGs1DateToUnix(value) };
    }

    if (`${applicationIdentifier}` === '21') {
      return { ...acc, serialNumber: value };
    }

    if (`${applicationIdentifier}` === '241') {
      return { ...acc, sku: value };
    }

    return acc;
  }, {});
};
