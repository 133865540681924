import { SvgIcon, type SvgIconProps } from '@afosto/components';

export const WebshopGiftcard = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 30 20">
    <rect
      width="27"
      height="19"
      x=".5"
      y=".5"
      fill="#fff"
      stroke="#DBE2EA"
      rx="3.5"
    />
    <path
      fill="#FFE252"
      fillRule="evenodd"
      d="m11.58 8.58-.25-2.65v-.08L9.59 4.66a.17.17 0 0 0-.23.08L7.02 8.9a.17.17 0 0 0 .06.23l1.62.71.88-.39 2-.88Z"
      clipRule="evenodd"
    />
    <path
      fill="#33A2DB"
      fillRule="evenodd"
      d="m18.55 9.88 1.68-.74c.08-.05.11-.15.06-.23l-2.33-4.17c-.05-.09-.15-.12-.23-.08L16 5.85l-.25 2.79 2.8 1.24Z"
      clipRule="evenodd"
    />
    <path
      fill="#C9529E"
      fillRule="evenodd"
      d="m11.23 13.97-.2 2.16c0 .09.08.16.18.16H16c.1 0 .17-.07.17-.16l-.19-2.1-2.42-1.64-2.33 1.58Z"
      clipRule="evenodd"
    />
    <path
      fill="#37559A"
      fillRule="evenodd"
      d="m20.23 10.55-1.68-.74-.16.07-2.65 1.17.25 2.8 1.74 1.18c.08.04.18 0 .23-.08l2.33-4.16c.05-.09.02-.2-.06-.24Z"
      clipRule="evenodd"
    />
    <path
      fill="#F37B46"
      fillRule="evenodd"
      d="m11.33 13.8.1-1.06.15-1.66L8.7 9.81l-1.62.71a.18.18 0 0 0-.06.23l2.34 4.17c.04.09.15.12.23.07l1.74-1.18Z"
      clipRule="evenodd"
    />
    <path
      fill="#6BBD45"
      fillRule="evenodd"
      d="m11.22 6.25 2.38 1.62.5-.33 1.88-1.29.2-2.09c0-.09-.08-.16-.18-.16h-4.8c-.09 0-.17.07-.17.16l.2 2.1Z"
      clipRule="evenodd"
    />
  </SvgIcon>
);
