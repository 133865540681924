import apiClient from '@afosto/api-client';
import { Profile } from '@afosto/profile-service';
import { queryOptions } from '@tanstack/react-query';
import type { Category } from '../components/CategoryProvider';

export const LIST_CATEGORIES_QUERY_KEY = 'listCategories';

export interface ListCategoriesResponse {
  data: {
    pos: Category[];
  };
}

export const fetchCategories = async ({
  queryKey,
}: {
  queryKey: [string, object, object];
}): Promise<ListCategoriesResponse> => {
  const [, query = {}, requestOptions = {}] = queryKey;

  const profileClient = new Profile(apiClient);
  const response = await profileClient.getProfile(
    'consultancy.cmr.pos.tiles.v1',
    query,
    requestOptions
  );

  return response.data;
};

export const listCategories = (query = {}, requestOptions = {}) => {
  return queryOptions({
    queryKey: [LIST_CATEGORIES_QUERY_KEY, query, requestOptions],
    queryFn: fetchCategories,
    select: (response) => response?.data?.pos || [],
  });
};
