import { defineMessages } from 'react-intl';

export const translations = defineMessages({
  allOrders: {
    id: 'common.allOrders',
    defaultMessage: 'All orders',
    description: 'Common label for all orders',
  },
  closed: {
    id: 'order.status.closed',
    defaultMessage: 'Closed',
    description: 'Label for the order closed status',
  },
  concept: {
    id: 'order.status.concept',
    defaultMessage: 'Concept',
    description: 'Label for the order concept status',
  },
  open: {
    id: 'order.status.open',
    defaultMessage: 'Open',
    description: 'Label for the order open status',
  },
  title: {
    id: 'common.orders',
    defaultMessage: 'Orders',
    description: 'Common label for orders',
  },
});
