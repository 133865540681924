import { defineMessages } from 'react-intl';

export const translations = defineMessages({
  accept: {
    id: 'actions.accept',
    defaultMessage: 'Accept',
    description: 'Common label for the accept action',
  },
  cancel: {
    id: 'actions.cancel',
    defaultMessage: 'Cancel',
    description: 'Common label for the cancel action',
  },
});
