import {
  graphQLClient,
  gql,
  type GraphQLRequestOptions,
} from '@afosto/graphql-client';
import { CoreOrderFragment } from '../fragments';
import type { Order } from '../types';

export interface CancelOrderItemsResponse {
  cancelOrderItems: {
    order: Order;
  };
}

export type CancelReason =
  | 'CUSTOMER_REQUEST'
  | 'OUT_OF_STOCK'
  | 'PAYMENT_ISSUES'
  | 'FRAUDULENT'
  | 'UNSHIPPABLE'
  | 'PRICING_ERROR';

export interface CancelOrderItemsItemInput {
  contraItemId: string;
  itemId: string;
  reason?: CancelReason;
}

export interface CancelOrderItemsInput {
  orderId: string;
  items: CancelOrderItemsItemInput[];
  requestOptions?: GraphQLRequestOptions<CancelOrderItemsResponse>;
}

export const cancelOrderItems = async ({
  orderId,
  items,
  requestOptions = {},
}: CancelOrderItemsInput) => {
  const mutation = gql`
    ${CoreOrderFragment}
    mutation CancelOrderItems($input: CancelOrderItemsInput!) {
      cancelOrderItems(input: $input) {
        order {
          ...CoreOrderFragment
        }
      }
    }
  `;

  return graphQLClient.request<CancelOrderItemsResponse>(
    mutation,
    {
      input: {
        orderId,
        items,
      },
    },
    requestOptions
  );
};
