import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { setupApiClient } from './utils/setupApiClient';
import { setupCustomValidation } from './utils/setupCustomValidation';
import { setupRouter } from './utils/setupRouter';
import './utils/setupSentry';

setupApiClient();
setupCustomValidation();

const router = setupRouter();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <StrictMode>
    <RouterProvider router={router} />
  </StrictMode>
);
