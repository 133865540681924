import { useState } from 'react';
import { useIntl } from 'react-intl';
import { Box, Button, Fields } from '@afosto/components';
import { FormDialog } from '../FormDialog';
import { useOrder } from '../OrderProvider/hooks/useOrder';
import { ClearFieldButton } from './components/ClearFieldButton';
import { validationSchema } from './validationSchema';
import { translations } from './translations';
import type { OrderNoteFormData, OrderNoteFormDialogProps } from './types';

export const OrderNoteFormDialog = (props: OrderNoteFormDialogProps) => {
  const { formProps, title, ...otherProps } = props;

  const intl = useIntl();
  const { closeOrderNoteDialog, setNoteForOrder, order } = useOrder();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const currentOrderNote = order?.customer?.notes;
  const titleKey = (
    currentOrderNote ? 'editNote' : 'addNote'
  ) as keyof typeof translations;
  const submitLabel = intl.formatMessage(
    translations[currentOrderNote ? 'save' : 'add']
  );

  const defaultValues = {
    note: currentOrderNote || '',
  };

  const handleSubmit = async (data: OrderNoteFormData) => {
    try {
      setIsSubmitting(true);

      await setNoteForOrder({
        note: data.note || '',
      });

      closeOrderNoteDialog();
      setIsSubmitting(false);
    } catch (error) {
      // TODO: Do something with error.
      setIsSubmitting(false);
      return Promise.reject(error);
    }
  };

  return (
    <FormDialog
      {...otherProps}
      formProps={{
        ...(formProps || {}),
        defaultValues,
        isSubmitting,
        submitLabel,
        validationSchema,
      }}
      onSubmit={handleSubmit}
      title={title || intl.formatMessage(translations[titleKey])}
      hideDialogHeaderDivider
    >
      <Fields.TextField
        name="note"
        placeholder={intl.formatMessage(translations.typeANote)}
        rows={10}
        sx={{ mt: 1 }}
        fullWidth
        multiline
      />
      <Box
        sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 3, mt: 3 }}
      >
        <ClearFieldButton />
        <Button size="large" sx={{ gridColumn: 2 }} type="submit" fullWidth>
          {submitLabel}
        </Button>
      </Box>
    </FormDialog>
  );
};
