import { SvgIcon, type SvgIconProps } from '@afosto/components';

export const Przelewy24 = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 30 20">
    <rect
      width="27"
      height="19"
      x=".5"
      y=".5"
      fill="#fff"
      stroke="#D8E0E7"
      rx="3.5"
    />
    <path
      fill="#B3B2B1"
      d="M19.87 10.66h.5l.03-.2s.06-.35.17-.42a.6.6 0 0 1 .18-.05c.13-.03.3-.03.43-.03.21.01.29.01.5.04.22.02.16.22.16.22l-.04.31s-.02.14-.07.22c-.04.08-.16.13-.23.15-.16.06-.73.2-.73.2l-.45.13s-.27.08-.42.25a.96.96 0 0 0-.24.46l-.14.8h2.4l.09-.48h-1.9l.03-.2s.02-.2.1-.26c.03-.03.04-.05.2-.1.09-.04.4-.12.4-.12l.71-.2s.4-.1.55-.31c.15-.22.21-.62.21-.62s.04-.4.01-.53a.58.58 0 0 0-.29-.34c-.14-.06-.29-.1-.72-.1-.43.02-.65.03-.87.12a.7.7 0 0 0-.43.43c-.09.2-.14.63-.14.63Zm4.53.89.33-2.02h-.6l-1.85 2-.09.5h1.63l-.12.71h.5l.12-.7h.46l.08-.49h-.46Zm-.5 0h-1.05l1.28-1.36-.23 1.36ZM6.46 9.8H8s.34-.28.59-.46c.24-.18.69-.46.69-.46l-.87-.4s-.73.45-1.04.66c-.3.2-.9.65-.9.65Zm3.29-1.2-.71-.48s.64-.37 1.5-.71a21.5 21.5 0 0 1 1.3-.48l.15.67s-.82.28-1.3.5c-.48.21-.94.5-.94.5Zm2.77-1.14-.13-.7s.88-.23 1.68-.38c.8-.15 1.87-.22 1.87-.22l-.36 1.07s-.93-.13-1.81 0c-.68.08-1.25.23-1.25.23Zm3.62-.14.6-1.19s1.3-.02 2.41.15c1.12.18 2.14.44 2.12.45l-2.84 1.48s-.66-.41-1.49-.67c-.46-.14-.8-.22-.8-.22Zm2.8 1.22.63.47h5.12s-.01-.16-.15-.4c-.08-.14-.24-.3-.4-.46a7.2 7.2 0 0 0-1.65-.98l-3.55 1.37Z"
    />
    <path
      fill="#D13239"
      d="M7.4 10.43h1.83l-.07.4-1.53 1.46h1.28l-.07.45H6.95l.08-.46 1.46-1.4H7.33l.07-.45Zm-2-.71a.48.48 0 0 0-.15-.1 1.3 1.3 0 0 0-.46-.09H3.31l-.53 3.2h.5l.19-1.18h.96s.38.02.64-.12c.27-.14.34-.46.34-.46l.06-.27.06-.45.01-.07.01-.09a.6.6 0 0 0-.06-.28l-.08-.1Zm-.37.56-.1.57c-.03.12-.12.18-.22.2-.17.04-.35.04-.35.04h-.81L3.73 10h.84l.27.02c.08.01.13.03.15.05.03.03.04.07.04.1v.1Zm6.67-.75h.47l-.53 3.2h-.48l.54-3.2Zm2.55.9h.46l.16 1.62.7-1.63h.57l.16 1.64.7-1.64h.49l-1.02 2.32h-.56l-.16-1.62-.72 1.62h-.55l-.23-2.31Zm-3.43.02a2.13 2.13 0 0 0-.57-.06c-.19 0-.27.01-.35.03 0 0-.34.05-.53.29s-.25.75-.25.75-.11.58-.08.77c.03.2.1.37.31.45.22.09.4.08.4.08s.39.03.68-.03a.83.83 0 0 0 .44-.27l.12-.2c.05-.1.07-.18.07-.19l.03-.12h-.5s-.02.32-.3.35c-.26.03-.4.02-.46.02-.05 0-.34.01-.31-.23v-.01c0-.28.04-.35.04-.35h1.56l.06-.39c.09-.43.03-.77-.36-.9Zm-.11.84H9.64l.04-.17s.04-.13.11-.19a.49.49 0 0 1 .26-.07c.09-.01.32-.03.52.01.06.01.12.05.14.1.04.13 0 .32 0 .32Zm-1.18.79v.01-.01Zm2.93 0v.01c0 .02 0-.01 0 0Zm1.29-1.63a2.13 2.13 0 0 0-.56-.06c-.2 0-.28.01-.35.03 0 0-.34.05-.53.29-.2.24-.25.75-.25.75s-.12.58-.08.77c.03.2.09.37.3.45.22.09.4.08.4.08s.4.03.68-.03c.3-.07.45-.27.45-.27l.12-.2.06-.19.03-.12h-.5s-.02.32-.29.35c-.27.03-.4.02-.46.02-.05 0-.34.01-.32-.23v-.01c.01-.27.04-.34.04-.34h1.56l.07-.4c.08-.43.03-.77-.37-.9Zm-.1.84h-1.07l.04-.17s.03-.13.1-.19a.49.49 0 0 1 .26-.07c.1-.01.33-.03.52.01.07.01.13.05.14.1.04.13 0 .32 0 .32Zm4.38-.86.31 1.69.86-1.7.5.01-1.26 2.42s-.22.44-.36.55c-.14.1-.23.16-.34.17-.11.01-.16.02-.27 0l-.12-.02.08-.43s.2.03.3-.01c.12-.05.21-.25.21-.25l.06-.1-.45-2.34h.48ZM6.8 10.42c-.2 0-.38.08-.54.17l.03-.17h-.51l-.4 2.3h.5l.23-1.27c.05-.26.24-.58.62-.58h.26l.08-.45H6.8Z"
    />
  </SvgIcon>
);
