import { defineMessages } from 'react-intl';

export const translations = defineMessages({
  addNote: {
    id: 'common.addNote',
    defaultMessage: 'Add note',
    description: 'Common label for add note',
  },
  editNote: {
    id: 'common.editNote',
    defaultMessage: 'Edit note',
    description: 'Common label for edit note',
  },
});
