import { ActionsList } from '../ActionsList';
import { CardSelector } from '../CardSelector';
import { PAYMENT_METHOD_ICON_MAPPING } from '../../constants/iconMappings';
import type { PaymentMethod } from '../../types';
import type { PaymentMethodsListProps } from './types';

export const PaymentMethodsList = (props: PaymentMethodsListProps) => {
  const { onSelectPaymentMethod, paymentMethods } = props;

  const handleSelectPaymentMethod = (paymentMethod: PaymentMethod) => () => {
    onSelectPaymentMethod(paymentMethod);
  };

  return (
    <ActionsList>
      {paymentMethods.map((paymentMethod) => (
        <CardSelector
          key={paymentMethod.id}
          Icon={
            PAYMENT_METHOD_ICON_MAPPING[
              paymentMethod?.code?.toLowerCase() as keyof typeof PAYMENT_METHOD_ICON_MAPPING
            ]
          }
          label={paymentMethod.name}
          onSelect={handleSelectPaymentMethod(paymentMethod)}
        />
      ))}
    </ActionsList>
  );
};
