import { useState } from 'react';
import { useIntl } from 'react-intl';
import { FormDialog } from '../FormDialog';
import { useOrder } from '../OrderProvider/hooks/useOrder';
import { ProductDetailsFormSection } from './components/ProductDetailsFormSection';
import { QuantityFormSection } from '../QuantityFormSection';
import { validationSchema } from './validationSchema';
import { translations } from './translations';
import type {
  ManuallyAddProductFormData,
  ManuallyAddProductFormDialogProps,
} from './types';

export const ManuallyAddProductFormDialog = (
  props: ManuallyAddProductFormDialogProps
) => {
  const { formProps, title, ...otherProps } = props;

  const intl = useIntl();
  const { addItemsToOrder, closeManuallyAddProductDialog } = useOrder();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const defaultValues = {
    sku: '',
    price: '',
    label: '',
    quantity: 1,
  };

  const handleSubmit = async (data: ManuallyAddProductFormData) => {
    try {
      setIsSubmitting(true);

      await addItemsToOrder({
        items: [
          {
            sku: data.sku,
            price: Number(data.price),
            label: data.label,
            quantity: Number(data.quantity),
          },
        ],
      });

      closeManuallyAddProductDialog();
      setIsSubmitting(false);
    } catch (error) {
      // TODO: Do something with error.
      setIsSubmitting(false);
      return Promise.reject(error);
    }
  };

  return (
    <FormDialog
      {...otherProps}
      formProps={{
        submitLabel: intl.formatMessage(translations.add),
        ...(formProps || {}),
        defaultValues,
        isSubmitting,
        validationSchema,
      }}
      onSubmit={handleSubmit}
      title={title || intl.formatMessage(translations.title)}
    >
      <ProductDetailsFormSection />
      <QuantityFormSection />
    </FormDialog>
  );
};
