import { defineMessages } from 'react-intl';

export const translations = defineMessages({
  details: {
    id: 'common.details',
    defaultMessage: 'Details',
    description: 'Common label for details',
  },
  email: {
    id: 'common.email',
    defaultMessage: 'Email',
    description: 'Common label for email',
  },
  name: {
    id: 'common.name',
    defaultMessage: 'Name',
    description: 'Common label for name',
  },
  phoneNumber: {
    id: 'common.phoneNumber',
    defaultMessage: 'Phone number',
    description: 'Common label for phone number',
  },
});
