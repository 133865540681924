import {
  graphQLClient,
  gql,
  type GraphQLRequestOptions,
} from '@afosto/graphql-client';
import { CoreOrderFragment } from '../fragments';
import type { Order } from '../types';

export interface ChangeOrderStateToOpenResponse {
  openOrder: {
    order: Order;
  };
}

export interface ChangeOrderStateToOpenInput {
  orderId: string;
  requestOptions?: GraphQLRequestOptions<ChangeOrderStateToOpenResponse>;
}

export const changeOrderStateToOpen = async ({
  orderId,
  requestOptions = {},
}: ChangeOrderStateToOpenInput) => {
  const mutation = gql`
    ${CoreOrderFragment}
    mutation ChangeOrderStateToOpen($input: OpenOrderInput!) {
      openOrder(input: $input) {
        order {
          ...CoreOrderFragment
        }
      }
    }
  `;

  return graphQLClient.request<ChangeOrderStateToOpenResponse>(
    mutation,
    {
      input: {
        orderId,
      },
    },
    requestOptions
  );
};
