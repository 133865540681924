import { ListItemButton } from '@afosto/components';
import { AngleRight } from '../../icons/regular';
import type { SearchResultsItem, SearchResultsListItemProps } from './types';

export const SearchResultsListItem = <T extends SearchResultsItem>(
  props: SearchResultsListItemProps<T>
) => {
  const { children, item, onSelectSearchResult, showSelector = false } = props;

  return (
    <ListItemButton
      key={item.id}
      onClick={onSelectSearchResult(item)}
      sx={(theme) => ({
        borderBottom: `1px solid ${theme.palette.divider}`,
        px: 0,
        py: 2,
      })}
    >
      {children}
      {showSelector && (
        <AngleRight
          sx={{
            color: (theme) => theme.palette.gray[600],
            fontSize: 24,
            alignSelf: 'center',
          }}
        />
      )}
    </ListItemButton>
  );
};
