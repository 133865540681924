import { defineMessages } from 'react-intl';

export const translations = defineMessages({
  type: {
    id: 'common.type',
    defaultMessage: 'Type',
    description: 'Common label for type',
  },
  'customerTypes.contact': {
    id: 'common.contact',
    defaultMessage: 'Contact',
    description: 'Common label for contact',
  },
  'customerTypes.organisation': {
    id: 'common.organisation',
    defaultMessage: 'Organisation',
    description: 'Common label for organisation',
  },
});
