import { FormattedMessage, useIntl } from 'react-intl';
import { Box, Button, FeedbackMessage } from '@afosto/components';
import { translations } from './translations';
import type { ConfirmMakePaymentLaterFormSectionProps } from './types';

export const ConfirmMakePaymentLaterFormSection = (
  props: ConfirmMakePaymentLaterFormSectionProps
) => {
  const { isProcessing, onCancel, onProceed } = props;

  const intl = useIntl();

  return (
    <Box sx={{ mt: 6 }}>
      <FeedbackMessage
        description={intl.formatMessage(
          translations.confirmMakePaymentLaterMessage
        )}
        descriptionTypographyProps={{
          variant: 'bodyLarge',
        }}
        severity="warning"
        title={intl.formatMessage(translations.confirmMakePaymentLaterTitle)}
        titleTypographyProps={{
          variant: 'h3',
        }}
      >
        <Box sx={{ display: 'flex', gap: 3, justifyContent: 'center' }}>
          <Button
            loading={isProcessing}
            onClick={onProceed}
            size="large"
            sx={{ maxWidth: 240 }}
            fullWidth
          >
            <FormattedMessage {...translations.proceed} />
          </Button>
          <Button
            color="secondary"
            disabled={isProcessing}
            onClick={onCancel}
            size="large"
            variant="outlined"
            sx={{ maxWidth: 240 }}
            fullWidth
          >
            <FormattedMessage {...translations.cancel} />
          </Button>
        </Box>
      </FeedbackMessage>
    </Box>
  );
};
