import { CancelOrderItemsFormDialog } from '../../components/CancelOrderItemsFormDialog';
import { CheckoutDialog } from '../../components/CheckoutDialog';
import { ManageContactDialog } from '../../components/ManageContactDialog';
import { ManageOrganisationDialog } from '../../components/ManageOrganisationDialog';
import { ManageOrderDiscountFormDialog } from '../../components/ManageOrderDiscountFormDialog';
import { ManageOrderItemFormDialog } from '../../components/ManageOrderItemFormDialog';
import { ManageOrderItemAdjustmentFormDialog } from '../../components/ManageOrderItemAdjustmentFormDialog';
import { ManuallyAddProductFormDialog } from '../../components/ManuallyAddProductFormDialog';
import { OrderNoteFormDialog } from '../../components/OrderNoteFormDialog';
import { Receipt } from '../../components/Receipt';
import { SelectCustomerFormDialog } from '../../components/SelectCustomerFormDialog';
import { Search } from '../../components/Search';
import { SearchToolbar } from '../../components/SearchToolbar';
import { Shortcuts } from '../../components/Shortcuts';
import { useApp } from '../../components/AppProvider/hooks/useApp';
import { useCategory } from '../../components/CategoryProvider/hooks/useCategory';
import { useChannel } from '../../components/ChannelProvider/hooks/useChannel';
import { ConfirmDeleteOrderDialog } from '../../components/ConfirmDeleteOrderDialog';
import { OrderActionsDialog } from '../../components/OrderActionsDialog';
import { useOrder } from '../../components/OrderProvider/hooks/useOrder';
import { useScanner } from '../../hooks/useScanner';
import { convertScanInput } from '../../utils/convertScanInput';
import { beepError, beepSuccess } from '../../utils';
import * as Styled from './Start.styles';

export const Start = () => {
  const { showSearch } = useApp();
  const { showSelectCategoryDialog } = useCategory();
  const { showSelectChannelDialog } = useChannel();
  const {
    addItemsToOrder,
    addItemToOrderByGtin,
    closeAddCustomerDialog,
    closeCancelOrderItemsDialog,
    closeCheckoutDialog,
    closeConfirmDeleteOrderDialog,
    closeManageContactDialog,
    closeManageOrganisationDialog,
    closeManageOrderDiscountDialog,
    closeManageOrderItemDialog,
    closeManageOrderItemAdjustmentDialog,
    closeManuallyAddProductDialog,
    closeOrderActionsDialog,
    closeOrderNoteDialog,
    exitedManageContactDialog,
    exitedManageOrganisationDialog,
    exitedManageOrderItemDialog,
    exitedManageOrderItemAdjustmentDialog,
    selectedContact,
    selectedOrganisation,
    selectedItemAdjustment,
    selectedOrderItem,
    showAddCustomerDialog,
    showCancelOrderItemsDialog,
    showCheckoutDialog,
    showConfirmDeleteOrderDialog,
    showManageContactDialog,
    showManageOrganisationDialog,
    showManageOrderDiscountDialog,
    showManageOrderItemDialog,
    showManageOrderItemAdjustmentDialog,
    showManuallyAddProductDialog,
    showOrderActionsDialog,
    showOrderNoteDialog,
  } = useOrder();
  const enableScanner =
    !showAddCustomerDialog &&
    !showCancelOrderItemsDialog &&
    !showCheckoutDialog &&
    !showConfirmDeleteOrderDialog &&
    !showManuallyAddProductDialog &&
    !showManageContactDialog &&
    !showManageOrganisationDialog &&
    !showManageOrderDiscountDialog &&
    !showManageOrderItemDialog &&
    !showManageOrderItemAdjustmentDialog &&
    !showOrderActionsDialog &&
    !showOrderNoteDialog &&
    !showSelectCategoryDialog &&
    !showSelectChannelDialog;

  const handleScanInput = (input: string) => {
    const { gtin, sku, ...itemValues } = convertScanInput(input);

    if (sku) {
      beepSuccess();
      addItemsToOrder({
        items: [
          {
            sku,
            quantity: 1,
            ...(gtin ? { gtin: [gtin] } : {}),
            ...itemValues,
          },
        ],
      }).catch(() => {
        beepError();
      });
    } else if (gtin) {
      addItemToOrderByGtin(gtin, itemValues, { beepEnabled: true }).catch(
        () => {
          // Do nothing
        }
      );
    }
  };

  useScanner({
    enabled: enableScanner,
    onScan: handleScanInput,
  });

  return (
    <>
      <Styled.Container>
        <div>
          {!showSearch && <SearchToolbar />}
          {!showSearch && <Shortcuts />}
          {showSearch && <Search />}
        </div>
        <Styled.RightLayout>
          <Receipt />
        </Styled.RightLayout>
      </Styled.Container>
      <CancelOrderItemsFormDialog
        onCancel={closeCancelOrderItemsDialog}
        open={showCancelOrderItemsDialog}
      />
      <ConfirmDeleteOrderDialog
        onClose={closeConfirmDeleteOrderDialog}
        open={showConfirmDeleteOrderDialog}
      />
      <CheckoutDialog onClose={closeCheckoutDialog} open={showCheckoutDialog} />
      <ManageContactDialog
        contact={selectedContact}
        onClose={closeManageContactDialog}
        onExited={exitedManageContactDialog}
        open={showManageContactDialog}
      />
      <ManageOrganisationDialog
        organisation={selectedOrganisation}
        onClose={closeManageOrganisationDialog}
        onExited={exitedManageOrganisationDialog}
        open={showManageOrganisationDialog}
      />
      <ManageOrderDiscountFormDialog
        onCancel={closeManageOrderDiscountDialog}
        open={showManageOrderDiscountDialog}
      />
      <ManageOrderItemFormDialog
        item={selectedOrderItem}
        onCancel={closeManageOrderItemDialog}
        onExited={exitedManageOrderItemDialog}
        open={showManageOrderItemDialog}
      />
      <ManageOrderItemAdjustmentFormDialog
        adjustment={selectedItemAdjustment}
        item={selectedOrderItem}
        onCancel={closeManageOrderItemAdjustmentDialog}
        onExited={exitedManageOrderItemAdjustmentDialog}
        open={showManageOrderItemAdjustmentDialog}
      />
      <ManuallyAddProductFormDialog
        onCancel={closeManuallyAddProductDialog}
        open={showManuallyAddProductDialog}
      />
      <OrderActionsDialog
        onClose={closeOrderActionsDialog}
        open={showOrderActionsDialog}
      />
      <OrderNoteFormDialog
        onCancel={closeOrderNoteDialog}
        open={showOrderNoteDialog}
      />
      <SelectCustomerFormDialog
        onCancel={closeAddCustomerDialog}
        open={showAddCustomerDialog}
      />
    </>
  );
};
