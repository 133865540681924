import {
  Avatar,
  Badge,
  Card,
  CardActionArea,
  Typography,
} from '@afosto/components';
import { camelize } from '@afosto/utils';
import { Currency } from '../Currency';
import { convertImageUrl } from '../../utils';
import * as Styled from './ReceiptItem.styles';
import type { ReceiptItemProps } from './types';

export const ReceiptItem = (props: ReceiptItemProps) => {
  const { currency, item, onClick } = props;
  const imageUrl = convertImageUrl(item?.image);
  const type = item?.type ? camelize(item.type?.toLowerCase()) : '';
  const isCancelItem = type === 'cancel';
  const isReturnItem = ['return', 'looseReturn'].includes(type);
  const quantity = item.quantity;
  const subtotal = item.subtotal;
  const total = item.total;

  return (
    <Card>
      <Styled.Container
        {...(onClick
          ? {
              component: CardActionArea,
              onClick: onClick,
            }
          : {})}
        sx={{
          ...(isCancelItem || isReturnItem
            ? {
                backgroundColor: (theme) => theme.palette.gray[50],
                borderLeft: (theme) => `3px solid ${theme.palette.error.main}`,
              }
            : {}),
        }}
      >
        <Badge
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          badgeContent={quantity}
          color={isCancelItem || isReturnItem ? 'error' : undefined}
          overlap="circular"
          size="small"
        >
          <Avatar src={imageUrl} variant="rounded" />
        </Badge>
        <Styled.ProductInformation>
          <Typography
            variant="h6"
            sx={
              isCancelItem || isReturnItem
                ? {
                    textDecoration: 'line-through',
                  }
                : {}
            }
          >
            {item.label || item.sku}
          </Typography>
          <Typography variant="h6" sx={{ justifySelf: 'flex-end' }}>
            <Currency currency={currency} value={total} />
          </Typography>
          {(item.adjustments || []).map((adjustment, idx) => (
            <>
              <Styled.AdjustmentContainer>
                <Typography
                  color="gray.700"
                  key={adjustment.description}
                  sx={idx !== 0 ? { columns: 'span 2' } : {}}
                >
                  {adjustment.description}
                  {adjustment.isPercentage ? ` (${adjustment.amount}%)` : ''}
                </Typography>
                <Typography color="gray.700">
                  <Currency
                    currency={currency}
                    value={
                      adjustment.isDiscount
                        ? adjustment.outcome?.amount * -1
                        : adjustment.outcome?.amount
                    }
                  />
                </Typography>
              </Styled.AdjustmentContainer>
              {idx === 0 && (
                <Typography
                  color="gray.700"
                  sx={{
                    justifySelf: 'flex-end',
                    textDecoration: 'line-through',
                  }}
                >
                  <Currency currency={currency} value={subtotal} />
                </Typography>
              )}
            </>
          ))}
        </Styled.ProductInformation>
      </Styled.Container>
    </Card>
  );
};
