import { defineMessages } from 'react-intl';

export const translations = defineMessages({
  title: {
    id: 'components.selectCustomerFormDialog.title',
    defaultMessage: 'Add customer',
    description: 'Title of the select customer form dialog',
  },
  add: {
    id: 'actions.add',
    defaultMessage: 'Add',
    description: 'Common label for the add action',
  },
});
