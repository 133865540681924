import { Outlet } from 'react-router-dom';
import { AuthenticationLayout } from '@afosto/auth-react';

export const AuthLayout = () => {
  return (
    <AuthenticationLayout>
      <Outlet />
    </AuthenticationLayout>
  );
};
