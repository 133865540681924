import { styled } from '@afosto/components';

export const Container = styled('div')`
  display: flex;
  flex: 1;
  align-items: center;
  gap: ${(props) => props.theme.spacing(2)};
  justify-content: space-between;
`;

export const ProductInformation = styled('div')`
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.spacing(2)};
`;
