import { useIntl } from 'react-intl';
import { Fields, Grid } from '@afosto/components';
import { translations } from './translations';

export const CouponFormSection = () => {
  const intl = useIntl();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Fields.TextField
          name="coupon"
          label={intl.formatMessage(translations.discountCode)}
          fullWidth
        />
      </Grid>
    </Grid>
  );
};
