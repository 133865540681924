import { defineMessages } from 'react-intl';

export const translations = defineMessages({
  actions: {
    id: 'common.actions',
    defaultMessage: 'Actions',
    description: 'Common label for actions',
  },
  addToOrder: {
    id: 'actions.addToOrder',
    defaultMessage: 'Add to order',
    description: 'Common label for the add to order action',
  },
  removeFromOrder: {
    id: 'actions.removeFromOrder',
    defaultMessage: 'Remove from order',
    description: 'Common label for the remove from order action',
  },
});
