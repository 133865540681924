import apiClient from '@afosto/api-client';
import { Account } from '@afosto/print-service';

export interface PrintJob {
  id?: string;
  printerId: number;
  contentType: string;
  content: string;
}

export interface EnqueuePrintJobInput {
  await: string;
  jobs: PrintJob[];
}

export const enqueuePrintJob = async (printJob: EnqueuePrintJobInput) => {
  const accountClient = new Account(apiClient);
  await accountClient.enqueuePrintJob({
    data: printJob,
  });
};
