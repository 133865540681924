import type { CurrencyProps } from './types';

export const Currency = (props: CurrencyProps) => {
  const { value, currency = 'EUR', locale = 'nl-NL' } = props;

  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency,
  }).format((value || 0) / 100);
};
