import { FormattedMessage } from 'react-intl';
import { List, Typography } from '@afosto/components';
import { DefaultSearchResultListItem } from '../DefaultSearchResultListItem';
import { translations } from './translations';
import type { SearchResultsItem } from '../SearchResultsListItem';
import type { SearchResultsListProps } from './types';

export const SearchResultsList = <T extends SearchResultsItem>(
  props: SearchResultsListProps<T>
) => {
  const {
    items,
    onSelectSearchResult,
    SearchResultsListItemComponent = DefaultSearchResultListItem<T>,
    showSelector,
  } = props;

  if (!items.length) {
    return (
      <Typography variant="bodyLarge" mt={4} sx={{ alignSelf: 'center' }}>
        <FormattedMessage {...translations.noResultsFound} />
      </Typography>
    );
  }

  return (
    <List sx={{ overflow: 'hidden' }}>
      {(items || []).map((item) => (
        <SearchResultsListItemComponent
          item={item}
          onSelectSearchResult={onSelectSearchResult}
          showSelector={showSelector}
        />
      ))}
    </List>
  );
};
