import { SvgIcon, type SvgIconProps } from '@afosto/components';

export const PodiumCadeaukaart = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 30 20">
    <rect width="28" height="20" fill="#EC1D24" rx="4" />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M16.62 9.86V7.34C16.57 6.04 15.43 5 14.02 5h-2.63v2.38c0 .66.57 1.2 1.28 1.22h1.17c-1.36.09-2.44 1.13-2.46 2.42v2.72c0 .7.61 1.26 1.36 1.26.75 0 1.36-.57 1.36-1.26v-1.55c1.37-.04 2.47-1.06 2.53-2.33Z"
      clipRule="evenodd"
    />
  </SvgIcon>
);
