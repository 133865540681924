export const CARD_SELECTOR_COLORS = {
  default: {
    icon: 'gray.600',
    label: 'gray.900',
  },
  error: {
    icon: 'error.main',
    label: 'error.main',
  },
  success: {
    icon: 'success.main',
    label: 'success.main',
  },
};
