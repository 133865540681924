import dayjs, { type OpUnitType } from 'dayjs';

export const getUnixTimestamp = (
  value = undefined,
  options?: { startOf?: OpUnitType; endOf?: OpUnitType }
) => {
  let dateValue = dayjs(value);

  if (options?.startOf) {
    dateValue = dateValue.startOf(options.startOf);
  }

  if (options?.endOf) {
    dateValue = dateValue.endOf(options.endOf);
  }

  return dateValue.valueOf();
};
