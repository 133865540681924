import { IconButton } from '@afosto/components';
import type { ReceiptHeaderActionProps } from './types';

export const ReceiptHeaderAction = (props: ReceiptHeaderActionProps) => {
  const { children, ...otherProps } = props;

  return (
    <IconButton
      size="large"
      {...otherProps}
      sx={{
        borderRadius: '0 !important',
        borderLeft: (theme) => `1px solid ${theme.palette.divider}`,
        '&.size-sizeLarge': {
          pl: `15px !important`,
          pr: `16px !important`,
        },
        '&.Mui-disabled': {
          backgroundColor: 'inherit !important',
          color: (theme) => `${theme.palette.gray[300]} !important`,
        },
        ...(otherProps?.sx || {}),
      }}
    >
      {children}
    </IconButton>
  );
};
