import { SvgIcon, type SvgIconProps } from '@afosto/components';

export const WeChat = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 30 20">
    <rect
      width="27"
      height="19"
      x=".5"
      y=".5"
      fill="#fff"
      stroke="#DBE2EA"
      rx="3.5"
    />
    <path
      fill="#1AAD19"
      d="M12.51 11.55a.4.4 0 0 1-.19.04.4.4 0 0 1-.36-.22l-.02-.06-1.13-2.65a.24.24 0 0 1-.02-.09c0-.12.09-.22.2-.22a.2.2 0 0 1 .13.04l1.33 1.02a.59.59 0 0 0 .55.06l6.26-2.97a6.45 6.45 0 0 0-5.06-2.33c-3.42 0-6.2 2.46-6.2 5.5a5.3 5.3 0 0 0 2.14 4.17.45.45 0 0 1 .15.5l-.28 1.11c0 .05-.03.1-.03.16 0 .12.1.22.2.22a.2.2 0 0 0 .13-.04l1.35-.83a.59.59 0 0 1 .51-.07c.64.19 1.32.3 2.03.3 3.42 0 6.2-2.47 6.2-5.51a5 5 0 0 0-.7-2.56l-7.14 4.4-.05.03Z"
    />
  </SvgIcon>
);
