import {
  graphQLClient,
  gql,
  type GraphQLRequestOptions,
} from '@afosto/graphql-client';
import { CoreOrderFragment } from '../fragments';
import type { CustomerInput } from './createOrder';
import type { Order } from '../types';

export interface AddCustomerToOrderResponse {
  addCustomerToOrder: {
    order: Order;
  };
}

export interface AddCustomerToOrderInput {
  customer: CustomerInput;
  orderId: string;
  requestOptions?: GraphQLRequestOptions<AddCustomerToOrderResponse>;
}

export const addCustomerToOrder = async ({
  customer,
  orderId,
  requestOptions = {},
}: AddCustomerToOrderInput) => {
  const mutation = gql`
    ${CoreOrderFragment}
    mutation AddCustomerToOrder($input: AddCustomerToOrderInput!) {
      addCustomerToOrder(input: $input) {
        order {
          ...CoreOrderFragment
        }
      }
    }
  `;

  return graphQLClient.request<AddCustomerToOrderResponse>(
    mutation,
    {
      input: {
        customer,
        orderId,
      },
    },
    requestOptions
  );
};
