import { useState } from 'react';
import { useCategory } from '../../CategoryProvider/hooks/useCategory';
import { useChannel } from '../../ChannelProvider/hooks/useChannel';
import { useInvoiceActions } from './useInvoiceActions';
import { useOrderActions } from './useOrderActions';
import { useOrderCheckout } from './useOrderCheckout';
import { useOrderDiscount } from './useOrderDiscount';
import { useOrderItems } from './useOrderItems';
import { usePrintActions } from './usePrintActions';
import type { Order } from '../../../types';

export const useOrderProvider = () => {
  const { category } = useCategory();
  const { channel } = useChannel();

  const [order, setOrder] = useState<Order | null>(null);

  const orderActions = useOrderActions({ channel, order, setOrder });
  const { createOrder, loadOrder } = orderActions;

  const orderCheckout = useOrderCheckout({ order });
  const orderDiscount = useOrderDiscount({ order, setOrder });
  const orderItems = useOrderItems({
    category,
    createOrder,
    loadOrder,
    order,
    setOrder,
  });
  const invoiceActions = useInvoiceActions();
  const printActions = usePrintActions({ channel, order });

  return {
    ...invoiceActions,
    ...orderActions,
    ...orderCheckout,
    ...orderDiscount,
    ...orderItems,
    ...printActions,
    order,
    setOrder,
  };
};
