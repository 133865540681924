import { Typography } from '@afosto/components';
import { Currency } from '../Currency';
import type { ReceiptSummaryRowProps } from './types';

export const ReceiptSummaryRow = (props: ReceiptSummaryRowProps) => {
  const { currency, description, label, total } = props;

  return (
    <>
      <Typography fontWeight={500} variant="bodyLarge">
        {label}
        {description && (
          <Typography color="gray.800" fontWeight={400} component="span" ml={1}>
            ({description})
          </Typography>
        )}
      </Typography>
      <Typography fontWeight={500} textAlign="right" variant="bodyLarge">
        <Currency currency={currency} value={total || 0} />
      </Typography>
    </>
  );
};
