import { defineMessages } from 'react-intl';

export const translations = defineMessages({
  addCustomer: {
    id: 'actions.addCustomer',
    defaultMessage: 'Add customer',
    description: 'Common label for add customer action',
  },
  customer: {
    id: 'common.customer',
    defaultMessage: 'Customer',
    description: 'Common label for customer',
  },
});
