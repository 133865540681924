import { useIntl } from 'react-intl';
import { Fields } from '@afosto/components';
import { Fieldset } from '../Fieldset';
import { translations } from './translations';

export const QuantityFormSection = () => {
  const intl = useIntl();

  return (
    <Fieldset title={intl.formatMessage(translations.quantity)}>
      <Fields.Counter name="quantity" min={1} size="medium" fullWidth />
    </Fieldset>
  );
};
