import { useCallback, useState } from 'react';

export type SearchType = 'products' | 'orders' | 'contacts' | 'organisations';

export const useSearch = () => {
  const [searchType, setSearchType] = useState<SearchType>('products');
  const [showSearch, setShowSearch] = useState(false);

  const closeSearch = useCallback(() => {
    setSearchType('products');
    setShowSearch(false);
  }, []);

  const openSearch = useCallback((type?: SearchType) => {
    if (type) {
      setSearchType(type);
    }

    setShowSearch(true);
  }, []);

  return {
    closeSearch,
    openSearch,
    searchType,
    setSearchType,
    showSearch,
  };
};
