import { defineMessages } from 'react-intl';

export const translations = defineMessages({
  close: {
    id: 'common.close',
    defaultMessage: 'Close',
    description: 'Common label for close',
  },
  title: {
    id: 'common.orderActions',
    defaultMessage: 'Order actions',
    description: 'Common label for order actions',
  },
});
