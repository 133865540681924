import { Tooltip } from '@afosto/components';
import { isDefined } from '@afosto/utils';
import type { DateTimeProps } from './types';

export const DateTime = (props: DateTimeProps) => {
  const {
    value,
    dateTimeOptions: providedDateTimeOptions,
    fallbackValue,
    locale,
    showMilliseconds,
    showSeconds,
  } = props;

  if (
    !isDefined(value) ||
    value === undefined ||
    value === null ||
    value <= 0 ||
    Number.isNaN(value)
  ) {
    return fallbackValue || '-';
  }

  const dateValue = value;

  // Check if value is a string unix timestamp
  // if (/^[0-9]+$/.test(dateValue)) {
  //   dateValue = Number(dateValue);
  // }

  const formatOptions = {
    replaceDash: true,
  };

  const dateTimeOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
    ...(showSeconds ? { second: '2-digit' } : {}),
  } as Intl.DateTimeFormatOptions;

  const dateTimeValue = new Intl.DateTimeFormat(
    locale || navigator.language,
    providedDateTimeOptions || dateTimeOptions
  )
    .formatToParts(new Date(dateValue))
    .map(({ value: part }) => part)
    .reduce((string, part) => {
      if (formatOptions.replaceDash) {
        return `${string}${part}`;
      }

      return `${string}${part}`;
    });

  if (showMilliseconds) {
    const millisecondValue = Intl.DateTimeFormat(locale, {
      ...(providedDateTimeOptions || dateTimeOptions),
      fractionalSecondDigits: 3,
    } as Intl.DateTimeFormatOptions)
      .formatToParts(new Date(dateValue))
      .map(({ value: part }) => part)
      .reduce((string, part) => {
        if (formatOptions.replaceDash) {
          return `${string}${part}`;
        }

        return `${string}${part}`;
      });

    return (
      <Tooltip title={millisecondValue}>
        <span>{dateTimeValue}</span>
      </Tooltip>
    );
  }

  return dateTimeValue;
};
