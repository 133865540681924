import { defineMessages } from 'react-intl';

export const translations = defineMessages({
  addNote: {
    id: 'common.addNote',
    defaultMessage: 'Add note',
    description: 'Common label for add note',
  },
  cancel: {
    id: 'common.cancel',
    defaultMessage: 'Cancel',
    description: 'Common label for cancel',
  },
  confirmDeleteOrderTitle: {
    id: 'feedback.confirmDeleteOrder.title',
    defaultMessage: 'Delete order',
    description: 'Title for the confirm delete order feedback',
  },
  confirmDeleteOrderDescription: {
    id: 'feedback.confirmDeleteOrder.description',
    defaultMessage: 'Are you sure you would like to delete this order?',
    description: 'Description for the confirm delete order feedback',
  },
  delete: {
    id: 'common.delete',
    defaultMessage: 'Delete',
    description: 'Common label for delete',
  },
  editNote: {
    id: 'common.editNote',
    defaultMessage: 'Edit note',
    description: 'Common label for edit note',
  },
  parkReceipt: {
    id: 'common.parkReceipt',
    defaultMessage: 'Park receipt',
    description: 'Common label for park receipt',
  },
  printReceipt: {
    id: 'common.printReceipt',
    defaultMessage: 'Print receipt',
    description: 'Common label for print receipt',
  },
  deleteOrder: {
    id: 'common.deleteOrder',
    defaultMessage: 'Delete order',
    description: 'Common label for delete order',
  },
});
