import { useIntl } from 'react-intl';
import { useFormContext } from '@afosto/components';
import { ContactDetailsFormSection } from '../ContactDetailsFormSection';
import { OrganisationDetailsFormSection } from '../OrganisationDetailsFormSection';
import { SelectExistingCustomerFormSection } from '../SelectExistingCustomerFormSection';
import { translations } from './translations';
import type { SelectCustomerFormSectionProps } from './types';

export const SelectCustomerFormSection = (
  props: SelectCustomerFormSectionProps
) => {
  const { onShowSearch } = props;

  const intl = useIntl();
  const { watch } = useFormContext();
  const customerType = watch('customerType');
  const placeholderTranslationKey: keyof typeof translations =
    customerType === 'organisation'
      ? 'searchAnExistingOrganisation'
      : 'searchAnExistingContact';
  const titleTranslationKey: keyof typeof translations =
    customerType === 'organisation'
      ? 'existingOrganisation'
      : 'existingContact';

  return (
    <>
      <SelectExistingCustomerFormSection
        onShowSearch={onShowSearch}
        placeholder={intl.formatMessage(
          translations[placeholderTranslationKey]
        )}
        title={intl.formatMessage(translations[titleTranslationKey])}
      />
      {customerType === 'contact' && <ContactDetailsFormSection />}
      {customerType === 'organisation' && <OrganisationDetailsFormSection />}
    </>
  );
};
