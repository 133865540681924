import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import apiClient from '@afosto/api-client';
import {
  Box,
  Button,
  IconStatusWrapper,
  Link,
  Typography,
} from '@afosto/components';
import { wait } from '@afosto/utils';
import { useQuery } from '@tanstack/react-query';
import { Currency } from '../Currency';
import { Fieldset } from '../Fieldset';
import { FullscreenDialog } from '../FullscreenDialog';
import { Loader } from '../Loader';
import { useOrder } from '../OrderProvider/hooks/useOrder';
import { PaymentMethodsList } from '../PaymentMethodsList';
import { usePrint } from '../PrintProvider/hooks/usePrint';
import { CheckoutCompletedFormSection } from './components/CheckoutCompletedFormSection';
import { ConfirmMakePaymentLaterFormSection } from './components/ConfirmMakePaymentLaterFormSection';
import { PaymentFailedFormSection } from './components/PaymentFailedFormSection';
import { CheckCircle } from '../../icons/solid';
import { PAYMENT_METHOD_ICON_MAPPING } from '../../constants/iconMappings';
import { getOrderPaymentMethods, type OrderPayment } from '../../queries';
import { getErrorMessage } from '../../utils';
import { translations } from './translations';
import type { CheckoutDialogProps } from './types';
import type { PaymentMethod } from '../../types';

export const CheckoutDialog = (props: CheckoutDialogProps) => {
  const { open = false, onClose, ...otherProps } = props;

  const intl = useIntl();
  const {
    addPaymentMethodToOrder,
    // createInvoiceForItems,
    changeOrderStateToOpen,
    createOrderPayment,
    fetchOrder,
    getOrderPayment,
    markPaymentAsPaid,
    openCashDrawer,
    order,
    printOrder,
    setOrder,
  } = useOrder();
  const { printingEnabled } = usePrint();
  const [isProcessingPayment, setIsProcessingPayment] = useState(false);
  const [processedPayment, setProcessedPayment] = useState<OrderPayment | null>(
    null
  );
  const [checkoutCompleted, setCheckoutCompleted] = useState(false);
  const [isChangingOrderOpenState, setIsChangingOrderOpenState] =
    useState(false);
  const [orderCompleted, setOrderCompleted] = useState(false);
  const [paymentErrorMessage, setPaymentErrorMessage] = useState('');
  const [paymentFailed, setPaymentFailed] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod | null>(
    null
  );
  const [showConfirmMakePaymentLater, setShowConfirmMakePaymentLater] =
    useState(false);
  const paymentMethodCode = paymentMethod?.code?.toLowerCase();
  const PaymentMethodIcon =
    PAYMENT_METHOD_ICON_MAPPING[
      paymentMethodCode as keyof typeof PAYMENT_METHOD_ICON_MAPPING
    ];
  const orderBalance =
    (order?.total || 0) - (order?.acceptance?.amountCovered || 0);

  const { data: paymentMethods, isFetched } = useQuery({
    ...getOrderPaymentMethods(order?.id || ''),
    enabled: !!order?.id && open,
  });

  const handleBackToPaymentMethods = () => {
    setPaymentErrorMessage('');
    setPaymentFailed(false);
    setPaymentMethod(null);
    setProcessedPayment(null);
  };

  const waitForOrderInvoiceAction = async () => {
    const updatedOrder = await fetchOrder(order?.id || '');
    const invoiceAction = (updatedOrder?.actions || []).find(
      (orderAction) => orderAction.action?.toLowerCase() === 'invoice'
    );

    if (!invoiceAction) {
      await wait(100);
      return waitForOrderInvoiceAction();
    }

    return updatedOrder;
  };

  const handleStartPayment = async (selectedPaymentMethod: PaymentMethod) => {
    try {
      if (!order) {
        throw new Error('Order not found');
      }

      setIsProcessingPayment(true);
      setPaymentErrorMessage('');
      setCheckoutCompleted(false);
      setPaymentFailed(false);
      setPaymentMethod(selectedPaymentMethod);
      setProcessedPayment(null);

      const [firstIssuer] = selectedPaymentMethod?.issuers || [];

      const response = await addPaymentMethodToOrder({
        methodId: selectedPaymentMethod.id,
        ...(firstIssuer ? { issuerId: firstIssuer.id } : {}),
      });

      let createdPayment: OrderPayment | null = null;

      if (!selectedPaymentMethod.isManual) {
        const { proceedingStep } =
          response?.addPaymentMethodToOrder?.order?.options || {};
        const { options } = proceedingStep?.proceeding || {};
        const [firstOption] = options || [];

        if (!firstOption?.url) {
          throw new Error('No payment option available');
        }

        const paymentResponse = await apiClient.request({
          url: firstOption.url,
          method: 'get',
        });
        createdPayment = paymentResponse?.data?.data || null;
      } else {
        const response = await createOrderPayment({
          payment: {
            amount: orderBalance,
          },
        });
        const paymentId = response?.data?.id;

        if (!paymentId) {
          throw new Error('Something went wrong while creating the payment');
        }

        await markPaymentAsPaid({
          payment: {
            id: paymentId,
            amount: orderBalance,
          },
        });
        createdPayment = await getOrderPayment(paymentId);
      }

      setProcessedPayment(createdPayment);

      if (createdPayment?.isPaid) {
        if (
          printingEnabled &&
          selectedPaymentMethod?.code?.toLowerCase() === 'cash'
        ) {
          openCashDrawer().catch(() => {
            // Do nothing.
          });
        }

        const updatedOrder = await waitForOrderInvoiceAction();

        if (updatedOrder) {
          setOrder(updatedOrder);
        }

        // const remainingBalance =
        //   updatedOrder?.total - (updatedOrder?.acceptance?.amountCovered || 0);
        //
        // if (remainingBalance > 0) {
        //   //TODO: Another payment should be processed
        // } else {
        // const invoiceAction = (updatedOrder?.actions || []).find(
        //   (orderAction) => orderAction.action?.toLowerCase() === 'invoice'
        // );
        //
        // if (invoiceAction) {
        //   const invoice = await createInvoiceForItems({
        //     items: invoiceAction.ids,
        //   });
        //   console.log(invoice);
        // }
        setCheckoutCompleted(true);
        // }
      } else {
        setPaymentFailed(true);
      }

      setIsProcessingPayment(false);
    } catch (error) {
      const message = getErrorMessage(error as Error);

      setPaymentErrorMessage(message);
      setPaymentFailed(true);
      setIsProcessingPayment(false);
    }
  };

  const handlePrintOrder = async () => {
    try {
      await printOrder({ payment: processedPayment, paymentMethod });
    } catch {
      //TODO: Do something with error.
    }
  };

  const handleRetryPayment = () => {
    if (paymentMethod) {
      handleStartPayment(paymentMethod).catch(() => {
        // Do nothing.
      });
    }
  };

  const handleCancelMakePaymentLater = () => {
    setShowConfirmMakePaymentLater(false);
  };

  const handleCompleteOrder = () => {
    setOrderCompleted(true);
    setCheckoutCompleted(true);
  };

  const handleMakePaymentLater = async () => {
    try {
      setIsChangingOrderOpenState(true);

      await changeOrderStateToOpen();

      setShowConfirmMakePaymentLater(false);
      setCheckoutCompleted(true);
      setIsChangingOrderOpenState(false);
    } catch {
      //TODO: Do something with error.
      setIsChangingOrderOpenState(false);
    }
  };

  const handleShowConfirmMakePaymentLater = () => {
    setShowConfirmMakePaymentLater(true);
  };

  const handleClose = () => {
    if (checkoutCompleted) {
      setOrder(null);
    }

    if (onClose && typeof onClose === 'function') {
      onClose();
    }
  };

  const handleExited = () => {
    setIsChangingOrderOpenState(false);
    setIsProcessingPayment(false);
    setCheckoutCompleted(false);
    setOrderCompleted(false);
    setPaymentErrorMessage('');
    setPaymentFailed(false);
    setProcessedPayment(null);
    setShowConfirmMakePaymentLater(false);
  };

  return (
    <FullscreenDialog
      {...otherProps}
      description={intl.formatMessage(
        translations[orderBalance > 0 ? 'description' : 'noOutstandingBalance']
      )}
      dialogHeaderProps={
        checkoutCompleted
          ? {
              title: intl.formatMessage(
                translations[
                  processedPayment
                    ? 'paymentAccepted'
                    : orderCompleted
                    ? 'orderCompleted'
                    : 'orderOpened'
                ]
              ),
              description: (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Typography
                    color="gray.900"
                    component="span"
                    fontWeight={500}
                    variant="bodyLarge"
                  >
                    <Currency
                      currency={
                        processedPayment
                          ? processedPayment.currency
                          : order?.currency
                      }
                      value={
                        processedPayment
                          ? processedPayment?.amountPaid
                          : orderBalance
                      }
                    />
                  </Typography>
                  {!processedPayment && !paymentMethod && !orderCompleted && (
                    <Typography
                      color="gray.800"
                      component="span"
                      variant="bodyLarge"
                    >
                      {intl.formatMessage(translations.underpaid).toLowerCase()}
                    </Typography>
                  )}
                  {orderCompleted && (
                    <Typography
                      color="gray.800"
                      component="span"
                      variant="bodyLarge"
                    >
                      {intl
                        .formatMessage(translations.noPaymentRequired)
                        .toLowerCase()}
                    </Typography>
                  )}
                  {paymentMethod && (
                    <>
                      <Typography
                        color="gray.800"
                        component="span"
                        variant="bodyLarge"
                      >
                        {intl
                          .formatMessage(translations.paidWith)
                          .toLowerCase()}
                        :
                      </Typography>
                      <Box
                        sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}
                      >
                        {PaymentMethodIcon && (
                          <PaymentMethodIcon sx={{ fontSize: 24 }} />
                        )}
                        <Typography
                          color="gray.800"
                          component="span"
                          variant="bodyLarge"
                        >
                          {paymentMethod?.name}
                        </Typography>
                      </Box>
                    </>
                  )}
                </Box>
              ),
              startAdornment: (
                <IconStatusWrapper
                  color={
                    processedPayment || orderCompleted ? 'success' : 'warning'
                  }
                  IconComponent={CheckCircle}
                  size="medium"
                />
              ),
            }
          : {}
      }
      hideDialogHeader={
        isProcessingPayment || paymentFailed || showConfirmMakePaymentLater
      }
      hideDialogHeaderDivider={!checkoutCompleted}
      onClose={!isProcessingPayment ? handleClose : undefined}
      open={open}
      title={<Currency currency={order?.currency} value={orderBalance} />}
      topBarProps={{
        actions: !isProcessingPayment ? (
          <>
            {!checkoutCompleted && !paymentFailed && (
              <Link
                color="gray.900"
                component="button"
                disabled={isChangingOrderOpenState}
                onClick={handleClose}
                fontWeight={500}
                type="button"
                variant="bodyLarge"
              >
                <FormattedMessage {...translations.back} />
              </Link>
            )}
            {checkoutCompleted && (
              <Link
                component="button"
                onClick={handleClose}
                fontWeight={500}
                type="button"
                variant="bodyLarge"
              >
                <FormattedMessage {...translations.close} />
              </Link>
            )}
            {paymentFailed && !processedPayment?.isCancelled && (
              <Link
                component="button"
                onClick={handleRetryPayment}
                fontWeight={500}
                type="button"
                variant="bodyLarge"
              >
                <FormattedMessage {...translations.retry} />
              </Link>
            )}
          </>
        ) : undefined,
      }}
      TransitionProps={{ onExited: handleExited }}
    >
      {isProcessingPayment && (
        <Loader
          message={`${intl.formatMessage(translations.processingPayment)}...`}
          sx={{
            mt: 6,
          }}
        />
      )}
      {!isProcessingPayment &&
        !checkoutCompleted &&
        !paymentFailed &&
        !showConfirmMakePaymentLater && (
          <>
            {orderBalance > 0 && (
              <>
                <Button
                  color="secondary"
                  onClick={handleShowConfirmMakePaymentLater}
                  size="extraLarge"
                  variant="outlined"
                  sx={{ mb: 1 }}
                  fullWidth
                >
                  <FormattedMessage {...translations.makePaymentLater} />
                </Button>
                {isFetched && (
                  <Fieldset
                    title={intl.formatMessage(
                      translations.availablePaymentMethods
                    )}
                    sx={{ mb: 6, '&:first-of-type': { mt: 0 } }}
                  >
                    <PaymentMethodsList
                      onSelectPaymentMethod={handleStartPayment}
                      paymentMethods={paymentMethods || []}
                    />
                  </Fieldset>
                )}
              </>
            )}
            {orderBalance <= 0 && (
              <Button
                color="primary"
                onClick={handleCompleteOrder}
                size="extraLarge"
                sx={{ mb: 1 }}
                fullWidth
              >
                <FormattedMessage {...translations.completeOrder} />
              </Button>
            )}
          </>
        )}
      {showConfirmMakePaymentLater && (
        <ConfirmMakePaymentLaterFormSection
          isProcessing={isChangingOrderOpenState}
          onCancel={handleCancelMakePaymentLater}
          onProceed={handleMakePaymentLater}
        />
      )}
      {!isProcessingPayment && checkoutCompleted && (
        <CheckoutCompletedFormSection
          onPrint={handlePrintOrder}
          printingEnabled={printingEnabled}
          payments={processedPayment ? [processedPayment] : []}
          paymentMethod={paymentMethod}
          PaymentMethodIcon={PaymentMethodIcon}
        />
      )}
      {!isProcessingPayment && paymentFailed && (
        <PaymentFailedFormSection
          errorMessage={paymentErrorMessage}
          onBack={handleBackToPaymentMethods}
          onRetry={handleRetryPayment}
          payment={processedPayment}
        />
      )}
    </FullscreenDialog>
  );
};
