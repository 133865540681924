import { GS1DigitalLinkToolkit } from './GS1DigitalLinkToolkit';
import { convertGs1Values } from './convertGs1Values';
import { GTIN_REGEX } from '../constants/regexes';

export const convertScanInput = (input: string) => {
  let gtin = '';
  let sku = '';
  let itemValues = {};

  try {
    const toolkit = new GS1DigitalLinkToolkit();
    const gs1Values = toolkit.extractFromGS1elementStrings(input);
    const {
      gtin: convertedGtin,
      sku: convertedSku,
      ...otherItemValues
    } = convertGs1Values(gs1Values);

    if (convertedGtin) {
      gtin = convertedGtin;
    }

    if (convertedSku) {
      sku = convertedSku;
    }

    itemValues = otherItemValues;
  } catch {
    // No GS1 barcode, do nothing.
  }

  if (RegExp(GTIN_REGEX).test(input)) {
    gtin = input;
  }

  return {
    gtin,
    sku,
    ...itemValues,
  };
};
