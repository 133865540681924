export const APP_SCOPES = [
  'cat:channel:read',
  'doc:tables:read',
  'odr:businesses:read',
  'odr:businesses:write',
  'odr:coupons:read',
  'odr:coupons:write',
  'odr:orders:proceed',
  'odr:orders:read',
  'odr:orders:write',
  'odr:payment-methods:read',
  'odr:payments:create',
  'odr:payments:update',
  'odr:product:read',
  'odr:refunds:create',
  'odr:shipping-methods:read',
  'odr:supply:update',
  'rel:contacts:read',
  'rel:contacts:write',
];
