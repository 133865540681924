import { defineMessages } from 'react-intl';

export const translations = defineMessages({
  amount: {
    id: 'common.amount',
    defaultMessage: 'Amount',
    description: 'Common label for amount',
  },
  description: {
    id: 'common.description',
    defaultMessage: 'Description',
    description: 'Common label for description',
  },
  fixedAmount: {
    id: 'common.fixedAmount',
    defaultMessage: 'Fixed amount',
    description: 'Common label for fixed amount',
  },
  percentage: {
    id: 'common.percentage',
    defaultMessage: 'Percentage',
    description: 'Common label for percentage',
  },
});
