import { defineMessages } from 'react-intl';

export const translations = defineMessages({
  description: {
    id: 'components.selectPrinterDialog.description',
    defaultMessage: 'Select the printer you want to use',
    description: 'Description of the select printer dialog',
  },
  title: {
    id: 'components.selectPrinterDialog.title',
    defaultMessage: 'Select printer',
    description: 'Title of the select printer dialog',
  },
});
