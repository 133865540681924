import apiClient from '@afosto/api-client';
import { Account } from '@afosto/print-service';
import { queryOptions } from '@tanstack/react-query';

export interface PrintAccount {
  email: string;
  isActive: boolean;
  credentials: {
    isExpired: boolean;
    password: string;
  };
}

export interface GetPrintAccountResponse {
  data: PrintAccount;
}

export const GET_PRINT_ACCOUNT_QUERY_KEY = 'getPrintAccount';

export const fetchPrintAccount = async ({
  queryKey,
}: {
  queryKey: [string, object];
}): Promise<GetPrintAccountResponse> => {
  const [, requestOptions = {}] = queryKey;

  const accountClient = new Account(apiClient);
  const response = await accountClient.getPrintAccount(requestOptions);

  return response.data;
};

export const getPrintAccount = (requestOptions = {}) => {
  return queryOptions({
    queryKey: [GET_PRINT_ACCOUNT_QUERY_KEY, requestOptions],
    queryFn: fetchPrintAccount,
    select: (response) => response?.data || null,
  });
};
