import { useIntl } from 'react-intl';
import { Card, Typography } from '@afosto/components';
import { Fieldset } from '../../../Fieldset';
import { getFullName } from '../../../../utils';
import { translations } from './translations';
import type { ContactInformationSectionProps } from './types';

export const ContactInformationSection = (
  props: ContactInformationSectionProps
) => {
  const { contact } = props;

  const intl = useIntl();

  return (
    <Fieldset title={intl.formatMessage(translations.contactInformation)}>
      <Card sx={{ display: 'flex', flexDirection: 'column', gap: 1, p: 2.5 }}>
        <Typography variant="h6">{getFullName(contact)}</Typography>
        <Typography variant="bodyLarge">{contact?.email}</Typography>
        {contact?.phoneNumbers?.primary && (
          <Typography variant="bodyLarge">
            {contact.phoneNumbers.primary.number}
          </Typography>
        )}
      </Card>
    </Fieldset>
  );
};
