import {
  graphQLClient,
  gql,
  type GraphQLRequestOptions,
} from '@afosto/graphql-client';
import { CoreOrderFragment } from '../fragments';
import type { Order } from '../types';

export interface ApproveOrderItemsResponse {
  approveOrderItems: {
    order: Order;
  };
}

export interface ApproveOrderItemsInput {
  orderId: string;
  itemIds: string[];
  requestOptions?: GraphQLRequestOptions<ApproveOrderItemsResponse>;
}

export const approveOrderItems = async ({
  orderId,
  itemIds,
  requestOptions = {},
}: ApproveOrderItemsInput) => {
  const mutation = gql`
    ${CoreOrderFragment}
    mutation ApproveOrderItems($input: ApproveOrderItemsInput!) {
      approveOrderItems(input: $input) {
        order {
          ...CoreOrderFragment
        }
      }
    }
  `;

  return graphQLClient.request<ApproveOrderItemsResponse>(
    mutation,
    {
      input: {
        orderId,
        items: itemIds,
      },
    },
    requestOptions
  );
};
