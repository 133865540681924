import { styled } from '@afosto/components';

export const Container = styled('div')`
  display: grid;
  grid-template-rows: auto auto 1fr auto;
  overflow: hidden;
`;

export const ReceiptContent = styled('div')`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing(2.5)};
  padding: ${(props) => props.theme.spacing(2.5)};
`;

export const ReceiptHeader = styled('div')`
  border-bottom: 1px solid ${(props) => props.theme.palette.divider};
  display: flex;
`;
