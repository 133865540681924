import { SvgIcon, type SvgIconProps } from '@afosto/components';

export const Klarna = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 30 20">
    <rect width="28" height="20" fill="#FFB3C7" rx="4" />
    <path
      fill="#0A0B09"
      fillRule="evenodd"
      d="M4.34 12.82H3.2V7.5h1.14v5.32ZM7.18 7.5H6.07c0 .98-.42 1.88-1.15 2.46l-.44.36 1.7 2.5H7.6l-1.57-2.3A4.39 4.39 0 0 0 7.18 7.5ZM9 12.81H7.93v-5.3H9v5.3Zm3.25-3.67v.23a1.72 1.72 0 0 0-1.02-.33c-1 0-1.8.87-1.8 1.94 0 1.07.8 1.94 1.8 1.94.38 0 .73-.13 1.02-.34v.24h1.03V9.14h-1.03Zm0 1.84c0 .52-.42.94-.93.94a.94.94 0 0 1-.93-.94c0-.53.41-.95.93-.95.51 0 .93.42.93.95Zm10.84-1.6v-.24h1.03v3.68H23.1v-.24c-.29.21-.64.34-1.01.34-1 0-1.82-.87-1.82-1.94 0-1.07.81-1.94 1.82-1.94.37 0 .72.12 1.01.33Zm-.93 2.54c.51 0 .93-.42.93-.94a.94.94 0 0 0-.93-.95.94.94 0 0 0-.93.95c0 .52.41.94.93.94Zm2.4.28c0-.38.3-.69.65-.69.36 0 .65.31.65.7 0 .37-.3.68-.65.68-.35 0-.64-.3-.64-.69Zm-6.12-3.16c-.41 0-.8.14-1.06.51v-.41h-1.02v3.67h1.03v-1.93c0-.56.35-.83.77-.83.45 0 .71.29.71.83v1.93h1.03v-2.33a1.4 1.4 0 0 0-1.46-1.44Zm-3.57.1v.48c.2-.29.59-.48 1-.48v1.07c-.41 0-1 .31-1 .89v1.72h-1.05V9.14h1.05Z"
      clipRule="evenodd"
    />
  </SvgIcon>
);
