import { SvgIcon, type SvgIconProps } from '@afosto/components';

export const CarteBleue = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 30 20">
    <rect width="28" height="20" fill="#000" rx="4" />
    <path
      fill="url(#CarteBlueGradient)"
      d="M24.5 0h-21C1.57 0 0 1.5 0 3.33v13.34A3.42 3.42 0 0 0 3.5 20h21c1.93 0 3.5-1.5 3.5-3.33V3.33A3.42 3.42 0 0 0 24.5 0Z"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M21.88 8.39c0 .83-.7 1.5-1.57 1.5h-5.7V6.88h5.7c.86 0 1.57.68 1.57 1.52Zm0 3.2c0 .84-.7 1.51-1.57 1.51h-5.7v-3.02h5.7c.86 0 1.57.68 1.57 1.51ZM10.3 10.1h4.2v.29a2.8 2.8 0 0 1-2.86 2.74H8.98a2.8 2.8 0 0 1-2.86-2.74v-.74A2.8 2.8 0 0 1 8.98 6.9h2.66a2.8 2.8 0 0 1 2.85 2.74v.26H10.3v.18Z"
      clipRule="evenodd"
    />
    <defs>
      <linearGradient
        id="CarteBlueGradient"
        x1="42"
        x2="23.08"
        y1="10"
        y2="36.49"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#083969" />
        <stop offset=".49" stopColor="#007B9D" />
        <stop offset="1" stopColor="#00A84A" />
      </linearGradient>
    </defs>
  </SvgIcon>
);
