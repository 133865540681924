import { FormattedMessage } from 'react-intl';
import { Box } from '@afosto/components';
import { useApp } from '../AppProvider/hooks/useApp';
import { translations } from './translations';

export const SearchToolbar = () => {
  const { openSearch } = useApp();

  const handleClick = () => {
    openSearch();
  };

  return (
    <Box
      component="button"
      onClick={handleClick}
      sx={(theme) => ({
        cursor: 'text',
        display: 'flex',
        alignItems: 'flex-start',
        backgroundColor: theme.palette.gray[25],
        border: 0,
        borderBottom: `1px solid ${theme.palette.divider}`,
        color: theme.palette.gray[600],
        fontSize: theme.typography.bodyLarge.fontSize,
        lineHeight: theme.typography.bodyLarge.lineHeight,
        px: 2.5,
        py: 2,
        width: '100%',
        '&:hover': {
          backgroundColor: theme.palette.gray[50],
        },
      })}
    >
      <FormattedMessage {...translations.searchPlaceholder} />
    </Box>
  );
};
