import {
  graphQLClient,
  gql,
  type GraphQLRequestOptions,
} from '@afosto/graphql-client';
import { CoreOrderFragment } from '../fragments';
import type { Order } from '../types';

export interface SetNoteForOrderResponse {
  setNoteForOrder: {
    order: Order;
  };
}

export interface SetNoteForOrderInput {
  orderId: string;
  note: string;
  requestOptions?: GraphQLRequestOptions<SetNoteForOrderResponse>;
}

export const setNoteForOrder = async ({
  orderId,
  note,
  requestOptions = {},
}: SetNoteForOrderInput) => {
  const mutation = gql`
    ${CoreOrderFragment}
    mutation SetNoteForOrder($input: SetNoteForOrderInput!) {
      setNoteForOrder(input: $input) {
        order {
          ...CoreOrderFragment
        }
      }
    }
  `;

  return graphQLClient.request<SetNoteForOrderResponse>(
    mutation,
    {
      input: {
        orderId,
        note,
      },
    },
    requestOptions
  );
};
