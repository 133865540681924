import { useIntl } from 'react-intl';
import { ActionsList } from '../../../ActionsList';
import { CardSelector } from '../../../CardSelector';
import { Fieldset } from '../../../Fieldset';
import { Plus, Trash } from '../../../../icons/solid';
import { translations } from './translations';
import type { OrganisationActionsSectionProps } from './types';

export const OrganisationActionsSection = (
  props: OrganisationActionsSectionProps
) => {
  const { disabled, isOrderOrganisation, onAddToOrder, onRemoveFromOrder } =
    props;

  const intl = useIntl();

  return (
    <Fieldset title={intl.formatMessage(translations.actions)}>
      <ActionsList>
        {!isOrderOrganisation && (
          <CardSelector
            disabled={disabled}
            Icon={Plus}
            label={intl.formatMessage(translations.addToOrder)}
            onSelect={onAddToOrder}
          />
        )}
        {isOrderOrganisation && (
          <CardSelector
            color="error"
            disabled={disabled}
            Icon={Trash}
            label={intl.formatMessage(translations.removeFromOrder)}
            onSelect={onRemoveFromOrder}
          />
        )}
      </ActionsList>
    </Fieldset>
  );
};
