import { useIntl } from 'react-intl';
import { ActionsList } from '../ActionsList';
import { CardSelector } from '../CardSelector';
import { Fieldset } from '../Fieldset';
import { Trash } from '../../icons/solid';
import { translations } from './translations';
import type { AdjustmentActionsFormSectionProps } from './types';

export const AdjustmentActionsFormSection = (
  props: AdjustmentActionsFormSectionProps
) => {
  const { disabled, onRemove } = props;

  const intl = useIntl();

  return (
    <Fieldset title={intl.formatMessage(translations.actions)}>
      <ActionsList>
        <CardSelector
          color="error"
          disabled={disabled}
          Icon={Trash}
          label={intl.formatMessage(translations.removeDiscount)}
          onSelect={onRemove}
        />
      </ActionsList>
    </Fieldset>
  );
};
