import { useCallback } from 'react';
import { useMutation } from '@tanstack/react-query';
import {
  invoiceItems as invoiceItemsMutationRequest,
  type InvoiceItemsInput,
} from '../../../mutations';

export const useInvoiceActions = () => {
  const { mutateAsync: invoiceItemsMutation } = useMutation({
    mutationFn: invoiceItemsMutationRequest,
  });

  const createInvoiceForItems = useCallback(
    async (input: InvoiceItemsInput) => {
      return invoiceItemsMutation(input);
    },
    [invoiceItemsMutation]
  );

  return {
    createInvoiceForItems,
  };
};
