import {
  graphQLClient,
  gql,
  type GraphQLRequestOptions,
} from '@afosto/graphql-client';
import { CoreOrderFragment } from '../fragments';
import type { Adjustment, Order } from '../types';

export interface AddAdjustmentsToOrderResponse {
  addAdjustmentsToOrder: {
    order: Order;
  };
}

export interface AdjustmentInput
  extends Pick<Adjustment, 'amount' | 'description' | 'isPercentage'> {
  id?: string;
}

export interface AddAdjustmentsToOrderInput {
  orderId: string;
  adjustments: AdjustmentInput[];
  requestOptions?: GraphQLRequestOptions<AddAdjustmentsToOrderResponse>;
}

export const addAdjustmentsToOrder = async ({
  orderId,
  adjustments,
  requestOptions = {},
}: AddAdjustmentsToOrderInput) => {
  const mutation = gql`
    ${CoreOrderFragment}
    mutation AddAdjustmentsToOrder($input: AddAdjustmentsToOrderInput!) {
      addAdjustmentsToOrder(input: $input) {
        order {
          ...CoreOrderFragment
        }
      }
    }
  `;

  return graphQLClient.request<AddAdjustmentsToOrderResponse>(
    mutation,
    {
      input: {
        orderId,
        adjustments,
      },
    },
    requestOptions
  );
};
