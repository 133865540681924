import apiClient from '@afosto/api-client';
import { Account } from '@afosto/print-service';
import { queryOptions } from '@tanstack/react-query';
import type { Printer } from '../types';

export const LIST_PRINTERS_QUERY_KEY = 'listPrinters';

export interface ListPrintersResponse {
  data: Printer[];
}

export const fetchPrinters = async ({
  queryKey,
}: {
  queryKey: [string, object, object];
}): Promise<ListPrintersResponse> => {
  const [, query = {}, requestOptions = {}] = queryKey;

  const accountClient = new Account(apiClient);
  const response = await accountClient.listPrinters(query, requestOptions);

  return response.data;
};

export const listPrinters = (query = {}, requestOptions = {}) => {
  return queryOptions({
    queryKey: [LIST_PRINTERS_QUERY_KEY, query, requestOptions],
    queryFn: fetchPrinters,
    select: (response) => response?.data,
  });
};
