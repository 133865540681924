import {
  graphQLClient,
  gql,
  type GraphQLRequestOptions,
} from '@afosto/graphql-client';
import { queryOptions } from '@tanstack/react-query';
import type { OrderItemType } from '../types';

export interface SummarizedOrderItem {
  ids: string[];
  batchNumber?: string;
  gtin?: string[];
  label?: string;
  image?: string;
  quantity: number;
  rfid?: string;
  serialNumber?: string;
  sku: string;
  type: OrderItemType;
}

export interface GetCancelableOrderItemsResponse {
  items: SummarizedOrderItem[];
}

export const GET_CANCELABLE_ORDER_ITEMS_QUERY_KEY = 'getCancelableOrderItems';

const fetchCancelableOrderItems = async ({
  queryKey,
}: {
  queryKey: [
    string,
    { ids?: string[] },
    GraphQLRequestOptions<GetCancelableOrderItemsResponse>
  ];
}) => {
  const [, filters, requestOptions = {}] = queryKey;
  const graphQLQuery = gql`
    query getOrder($filters: CollectionItemFilterInput!) {
      items(filters: $filters) {
        ids
        batch_number
        gtin
        image
        label
        quantity
        rfid
        serial_number
        sku
        type
      }
    }
  `;

  return graphQLClient.request<GetCancelableOrderItemsResponse>(
    graphQLQuery,
    { filters },
    requestOptions
  );
};

export const getCancelableOrderItems = (
  filters: { ids: string[] },
  requestOptions = {} as GraphQLRequestOptions<GetCancelableOrderItemsResponse>
) => {
  return queryOptions({
    queryKey: [GET_CANCELABLE_ORDER_ITEMS_QUERY_KEY, filters, requestOptions],
    queryFn: fetchCancelableOrderItems,
    select: (response) => response?.items,
  });
};
