import * as yup from 'yup';
import type { DiscountType } from '../DiscountFormSection';

export const couponValidationSchema = yup.object().shape({
  coupon: yup.string().required(),
});

export const orderDiscountValidationSchema = yup.object().shape({
  type: yup.string().required(),
  amount: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .when('type', {
      is: (type: DiscountType) => type === 'percentage',
      then: (schema) => schema.min(0).max(100),
    })
    .nullable()
    .required(),
  description: yup.string().required(),
});
