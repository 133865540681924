import { FormattedMessage, useIntl } from 'react-intl';
import { Box, Button, FeedbackMessage } from '@afosto/components';
import { translations } from './translations';
import type { PaymentFailedFormSectionProps } from './types';

export const PaymentFailedFormSection = (
  props: PaymentFailedFormSectionProps
) => {
  const { errorMessage, onBack, onRetry, payment } = props;

  const intl = useIntl();
  const isCancelled = payment?.isCancelled;
  const isExpired = payment?.isExpired;
  let titleTranslationKey =
    'couldNotProcessPayment' as keyof typeof translations;
  let descriptionTranslationKey = '' as keyof typeof translations;

  if (isCancelled) {
    titleTranslationKey = 'paymentCancelledTitle';
    descriptionTranslationKey = 'paymentCancelledMessage';
  }

  if (isExpired) {
    titleTranslationKey = 'paymentExpiredTitle';
    descriptionTranslationKey = 'paymentExpiredMessage';
  }

  return (
    <Box sx={{ mt: 6 }}>
      <FeedbackMessage
        description={
          descriptionTranslationKey
            ? intl.formatMessage(translations[descriptionTranslationKey])
            : errorMessage || ''
        }
        descriptionTypographyProps={{
          variant: 'bodyLarge',
        }}
        title={intl.formatMessage(translations[titleTranslationKey])}
        titleTypographyProps={{
          variant: 'h3',
        }}
      >
        <Box sx={{ display: 'flex', gap: 3, justifyContent: 'center' }}>
          <Button
            color="secondary"
            onClick={onBack}
            size="large"
            variant="outlined"
          >
            <FormattedMessage {...translations.backToPaymentMethods} />
          </Button>
          {!isCancelled && (
            <Button onClick={onRetry} size="large">
              <FormattedMessage {...translations.retry} />
            </Button>
          )}
        </Box>
      </FeedbackMessage>
    </Box>
  );
};
