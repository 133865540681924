import { defineMessages } from 'react-intl';

export const translations = defineMessages({
  addItemDiscount: {
    id: 'actions.addItemDiscount',
    defaultMessage: 'Add item discount',
    description: 'Common label for add item discount action',
  },
  add: {
    id: 'actions.add',
    defaultMessage: 'Add',
    description: 'Common label for the add action',
  },
  editItemDiscount: {
    id: 'actions.editItemDiscount',
    defaultMessage: 'Edit item discount',
    description: 'Common label for edit item discount action',
  },
  save: {
    id: 'actions.save',
    defaultMessage: 'Save',
    description: 'Common label for the save action',
  },
});
