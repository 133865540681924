import { defineMessages } from 'react-intl';

export const translations = defineMessages({
  noPayment: {
    id: 'common.noPayment',
    defaultMessage: 'No payment',
    description: 'Common label for no payment',
  },
  notPaid: {
    id: 'common.notPaid',
    defaultMessage: 'Not paid',
    description: 'Common label for not paid',
  },
  payment: {
    id: 'common.payment',
    defaultMessage: 'Payment',
    description: 'Common label for payment',
  },
  paid: {
    id: 'paymentStatus.paid',
    defaultMessage: 'Paid',
    description: 'Label for paid payment status',
  },
});
