import { useIntl } from 'react-intl';
import { useCategory } from '../CategoryProvider/hooks/useCategory';
import { ShortcutCard } from '../ShortcutCard';
import { Gem } from '../../icons/solid';
import { translations } from './translations';

export const CategoryShortcut = () => {
  const intl = useIntl();
  const { category, openSelectCategoryDialog } = useCategory();

  return (
    <ShortcutCard
      color={category ? 'success' : undefined}
      Icon={Gem}
      label={
        category?.label || intl.formatMessage(translations.selectACategory)
      }
      onClick={openSelectCategoryDialog}
    />
  );
};
