import { useIntl } from 'react-intl';
import { Label } from '@afosto/components';
import { capitalize } from '@afosto/utils';
import { translations } from './translations';
import { ORDER_STATE_COLOR_MAPPING } from './constants';
import type { OrderStateLabelProps } from './types';

export const OrderStateLabel = (props: OrderStateLabelProps) => {
  const { size = 'medium', state = 'CONCEPT' } = props;

  const intl = useIntl();
  const stateLowerCase = state?.toLowerCase() as keyof typeof translations;
  const stateColor =
    ORDER_STATE_COLOR_MAPPING[
      stateLowerCase as keyof typeof ORDER_STATE_COLOR_MAPPING
    ];
  const stateLabel = translations[stateLowerCase]
    ? intl.formatMessage(translations[stateLowerCase])
    : capitalize(stateLowerCase);

  return <Label color={stateColor} label={stateLabel} size={size} />;
};
