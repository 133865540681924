import { SvgIcon, type SvgIconProps } from '@afosto/components';

export const Billink = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 30 20">
    <rect width="28" height="20" fill="#000" rx="4" />
    <path
      fill="#F28D20"
      d="M9.5 8.94H8.34v4h1.14v-4Zm-.04-1.75a.8.8 0 0 0-.54-.22.74.74 0 0 0-.54.22.77.77 0 0 0-.22.53.77.77 0 0 0 .46.7c.1.04.2.06.3.06a.8.8 0 0 0 .7-.46.73.73 0 0 0 0-.59.76.76 0 0 0-.16-.24Zm2.04-.03h-1.14v5.77h1.15V7.16Zm2.02 0h-1.15v5.77h1.15V7.16Zm1.98.03a.8.8 0 0 0-.54-.22.74.74 0 0 0-.54.22.77.77 0 0 0-.22.53.77.77 0 0 0 .46.7c.1.04.2.06.3.06a.8.8 0 0 0 .7-.46.73.73 0 0 0 0-.59.76.76 0 0 0-.16-.24Zm.03 1.75H14.4v4h1.14v-4Zm4.27.03a2 2 0 0 0-2.75-.07l-.38-.43h-.27v4.46h1.14v-2.55a.84.84 0 0 1 .25-.6.85.85 0 0 1 .6-.25.82.82 0 0 1 .6.25.86.86 0 0 1 .26.6v2.55h1.14v-2.55a1.95 1.95 0 0 0-.59-1.41Zm5.54-.49h-1.3l-1.7 2.15V7.16h-1.15v5.77h1.15v-.57l.8-1.05.9 1.62h1.3l-1.48-2.6 1.48-1.85Zm-20.21-.5c-.63 0-1.2.24-1.63.62.1-.26.31-.46.57-.58V7.8a.9.9 0 0 0-.91-.9h-.5v3.57a2.47 2.47 0 1 0 2.47-2.48Zm0 3.8c-.73 0-1.33-.59-1.33-1.32v-.01h2.66c0 .74-.6 1.34-1.33 1.34Z"
    />
  </SvgIcon>
);
