import { defineMessages } from 'react-intl';

export const translations = defineMessages({
  actions: {
    id: 'common.actions',
    defaultMessage: 'Actions',
    description: 'Common label for actions',
  },
  printReceipt: {
    id: 'actions.printReceipt',
    defaultMessage: 'Print receipt',
    description: 'Common label for the print receipt action',
  },
});
