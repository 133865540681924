import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  customerType: yup.string().required(),
  contact: yup.object().when('customerType', {
    is: (type: string) => type === 'contact',
    then: (schema) =>
      schema.shape({
        additionalName: yup.string().trim().nullable(),
        email: yup.string().email().trim().required(),
        givenName: yup.string().trim().required(),
        familyName: yup.string().trim().required(),
        phoneNumberNumber: yup.string().trim().phoneNumber().nullable(),
        phoneNumberCountry: yup.object().when('phoneNumberNumber', {
          is: (phoneNumberNumber: string) => phoneNumberNumber?.length > 0,
          then: (schema) => schema.required(),
          otherwise: (schema) => schema.nullable(),
        }),
      }),
    otherwise: (schema) => schema.nullable(),
  }),
  organisation: yup.object().when('customerType', {
    is: (type: string) => type === 'organisation',
    then: (schema) =>
      schema.shape({
        email: yup.string().trim().required(),
        name: yup.string().trim().required(),
        phoneNumberNumber: yup.string().trim().phoneNumber().nullable(),
        phoneNumberCountry: yup.object().when('phoneNumberNumber', {
          is: (phoneNumberNumber: string) => phoneNumberNumber?.length > 0,
          then: (schema) => schema.required(),
          otherwise: (schema) => schema.nullable(),
        }),
      }),
    otherwise: (schema) => schema.nullable(),
  }),
});
