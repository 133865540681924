import { defineMessages } from 'react-intl';

export const translations = defineMessages({
  actions: {
    id: 'common.actions',
    defaultMessage: 'Actions',
    description: 'Common label for actions',
  },
  removeDiscount: {
    id: 'actions.removeDiscount',
    defaultMessage: 'Remove discount',
    description: 'Common label for the remove discount action',
  },
});
