import { useIntl } from 'react-intl';
import { ShortcutCard } from '../ShortcutCard';
import { useOrder } from '../OrderProvider/hooks/useOrder';
import { CommentAltDots } from '../../icons/solid';
import { translations } from './translations';

export const OrderNoteShortcut = () => {
  const intl = useIntl();

  const { openOrderNoteDialog, order } = useOrder();
  const hasNote = (order?.customer?.notes || '').length > 0;

  return (
    <ShortcutCard
      color={hasNote ? 'success' : undefined}
      disabled={!order}
      label={intl.formatMessage(translations[hasNote ? 'editNote' : 'addNote'])}
      Icon={CommentAltDots}
      onClick={openOrderNoteDialog}
    />
  );
};
