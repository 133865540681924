import { camelize, isDefined } from '@afosto/utils';
import { getValueKeysFromString } from './getValueKeysFromString';

export const replaceValuesInString = (input: string, values = {}) => {
  const valueKeys = getValueKeysFromString(input);

  return valueKeys.reduce((acc: string, valueKey) => {
    const value = values[camelize(valueKey) as keyof typeof values];
    return acc.replace(`{${valueKey}}`, isDefined(value) ? value : '').trim();
  }, input);
};
