import { camelize } from '@afosto/utils';
import { queryOptions } from '@tanstack/react-query';
import { fetchTable, GET_TABLE_QUERY_KEY } from './getTable';
import { fetchQueryViewData, QUERY_VIEW_DATA_QUERY_KEY } from './queryViewData';

export interface SearchContactResult {
  id: string;
  [key: string]: unknown;
}

export interface SearchContactQuery {
  itemsPerPage?: number;
}

export const SEARCH_CONTACT_QUERY_KEY = 'searchContact';

export const fetchSearchContact = async ({
  queryKey,
}: {
  queryKey: [string, string, SearchContactQuery, object];
}): Promise<SearchContactResult[]> => {
  const [, q, query = {}] = queryKey || [];
  const tableResponse = await fetchTable({
    queryKey: [GET_TABLE_QUERY_KEY, 'CONTACTS', {}],
  });
  const { views = [] } = tableResponse?.table || {};
  const [firstView] = views;
  const defaultView = views.find(({ isDefault }) => isDefault);
  const viewId = defaultView?.id || firstView?.id;

  const response = await fetchQueryViewData({
    queryKey: [
      QUERY_VIEW_DATA_QUERY_KEY,
      viewId,
      {
        ...(q?.trim()
          ? {
              filters: [
                {
                  key: 'email',
                  operator: 'Q',
                  values: [q],
                },
                {
                  key: 'given_name',
                  operator: 'Q',
                  values: [q],
                },
                {
                  key: 'family_name',
                  operator: 'Q',
                  values: [q],
                },
              ],
            }
          : {}),
        fixedFilters: [
          {
            key: 'is_guest',
            operator: 'EQ',
            values: [false],
          },
        ],
        sorting: [
          {
            key: 'number',
            direction: 'desc',
          },
        ],
        itemsPerPage: query?.itemsPerPage || 10,
      },
      {},
    ],
  });

  return (response?.data || []).map((data) => ({
    ...Object.keys(data?.columns || {}).reduce(
      (acc, key) => ({
        ...acc,
        [camelize(key)]: data.columns[key],
      }),
      {}
    ),
    id: data.entity?.id,
  }));
};

export const searchContact = (
  q: string,
  query: SearchContactQuery = {},
  requestOptions = {}
) => {
  return queryOptions({
    queryKey: [SEARCH_CONTACT_QUERY_KEY, q, query, requestOptions],
    queryFn: fetchSearchContact,
  });
};
