import { defineMessages } from 'react-intl';

export const translations = defineMessages({
  concept: {
    id: 'order.status.concept',
    defaultMessage: 'Concept',
    description: 'Label for the order concept status',
  },
  open: {
    id: 'order.status.open',
    defaultMessage: 'Open',
    description: 'Label for the order open status',
  },
  closed: {
    id: 'order.status.closed',
    defaultMessage: 'Closed',
    description: 'Label for the order closed status',
  },
});
