import { useEffect } from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import * as Sentry from '@sentry/react';

const isRunningFromFrame = window.self !== window.top;

Sentry.init({
  dsn: 'https://a6903634979205eeb128828ba66573b4@o4507548490530816.ingest.de.sentry.io/4507859634880592',
  enabled: import.meta.env.MODE === 'production' && !isRunningFromFrame,
  environment: import.meta.env.MODE,
  release: __APP_VERSION__,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.httpClientIntegration(),
    Sentry.replayIntegration({
      blockAllMedia: false,
      maskAllInputs: false,
      maskAllText: false,
      networkDetailAllowUrls: window?.location
        ? [window.location.origin, /^https:\/\/afosto.app.*/]
        : [],
    }),
  ],
  // This option is required for capturing headers and cookies.
  sendDefaultPii: true,
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
