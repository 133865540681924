import { SvgIcon, type SvgIconProps } from '@afosto/components';

export const Giropay = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 30 20">
    <rect
      width="27"
      height="19"
      x=".5"
      y=".5"
      fill="#fff"
      stroke="#DBE2EA"
      rx="3.5"
    />
    <path
      fill="#0F3365"
      fillRule="evenodd"
      d="M5.1 6.45c-.5 0-.9.37-.9.83v5.5a.95.95 0 0 0 0 .09c.04.42.42.74.9.74h9.35V6.45H5.09Zm-.01-.62h17.9c.87 0 1.57.65 1.57 1.45v5.5c0 .8-.7 1.45-1.57 1.45H5.1c-.87 0-1.57-.64-1.57-1.44v-5.5c0-.8.7-1.46 1.57-1.46Z"
      clipRule="evenodd"
    />
    <path
      fill="#FFFFFE"
      fillRule="evenodd"
      d="M15.98 11.88h-.79V8.95h.72v.38h.01c.16-.3.5-.43.85-.43.63 0 .97.56.97 1.06 0 .62-.36 1.14-1.04 1.14-.27 0-.57-.1-.72-.34v1.13Zm.48-1.35c.3 0 .47-.23.47-.56 0-.28-.17-.52-.47-.52-.3 0-.48.23-.48.55 0 .3.21.53.48.53Zm4.3 1.35c.15.04.32.05.48.05.68 0 .87-.45 1.08-.95l.03-.08.84-1.96h-.79l-.46 1.37h-.01l-.5-1.37h-.84l.92 2.15a.4.4 0 0 1-.4.29 1 1 0 0 1-.3-.05l-.06.55Zm-.3-.77a2.37 2.37 0 0 1-.04-.5v-.78c0-.64-.5-.87-1.09-.87-.33 0-.62.05-.9.15v.5c.23-.12.48-.16.73-.16.29 0 .52.07.52.36-.1-.02-.24-.03-.36-.03-.42 0-1.18.08-1.18.72 0 .45.4.66.85.66a.8.8 0 0 0 .72-.38h.01l.01.19.01.14h.72Zm-1.58-.67c0-.2.2-.27.47-.27h.35c0 .25-.2.5-.5.5-.17 0-.31-.08-.31-.23Z"
      clipRule="evenodd"
    />
    <path
      fill="#D8232A"
      fillRule="evenodd"
      d="M8.36 11.04h.79v-2.1h-.79v2.1Zm0-2.44h.79v-.53h-.79v.53Zm2.71.3c.1 0 .19 0 .27.02l-.04.6c-.09-.02-.18-.02-.27-.02-.37 0-.57.24-.57.65v.89h-.79v-2.1h.72v.39c.14-.27.34-.44.68-.44Zm2.94 1.1c0 .69-.57 1.09-1.32 1.09-.75 0-1.32-.4-1.32-1.1 0-.7.57-1.1 1.32-1.1.75 0 1.32.4 1.32 1.1Zm-1.83 0c0 .3.15.58.5.58.37 0 .52-.28.52-.59 0-.31-.15-.59-.51-.59s-.51.28-.51.6ZM7.1 8.93h.71v1.85c0 .87-.45 1.14-1.37 1.14-.37 0-.68-.07-.87-.13l.04-.57c.24.1.44.17.77.17.45 0 .7-.2.7-.6v-.12h-.01a.91.91 0 0 1-.76.36c-.63 0-1.02-.43-1.02-1.05 0-.63.32-1.1 1.03-1.1.34 0 .6.17.77.43h.01v-.38Zm-.55.51c-.3 0-.44.24-.44.52 0 .33.2.51.44.51.28 0 .5-.21.5-.53 0-.24-.15-.5-.5-.5Z"
      clipRule="evenodd"
    />
  </SvgIcon>
);
