import { useIntl } from 'react-intl';
import { ActionsList } from '../../../ActionsList';
import { CardSelector } from '../../../CardSelector';
import { Fieldset } from '../../../Fieldset';
import { BadgePercent, Trash } from '../../../../icons/solid';
import { translations } from './translations';
import type { OrderItemActionsSectionProps } from './types';

export const OrderItemActionsSection = (
  props: OrderItemActionsSectionProps
) => {
  const { disabled, onAddDiscount, onRemove } = props;

  const intl = useIntl();

  return (
    <Fieldset title={intl.formatMessage(translations.actions)}>
      <ActionsList>
        <CardSelector
          disabled={disabled}
          Icon={BadgePercent}
          label={intl.formatMessage(translations.addDiscount)}
          onSelect={onAddDiscount}
        />
        <CardSelector
          color="error"
          disabled={disabled}
          Icon={Trash}
          label={intl.formatMessage(translations.removeFromOrder)}
          onSelect={onRemove}
        />
      </ActionsList>
    </Fieldset>
  );
};
