import { SvgIcon, type SvgIconProps } from '@afosto/components';

export const Fashioncheque = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 30 20">
    <rect width="28" height="20" fill="#EB008B" rx="4" />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M11.6 8v3.37h.92v-1.32h1v-.72h-1v-.56h1.62V8h-2.55Zm4.32 2.77c-.43 0-.81-.45-.81-1 0-.56.38-1 .81-1 .32 0 .6.24.73.6l.87-.24A1.72 1.72 0 0 0 15.92 8c-.94 0-1.7.8-1.7 1.77s.76 1.76 1.7 1.76c.73 0 1.34-.47 1.6-1.12l-.87-.24c-.13.35-.4.6-.73.6Z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M7 9.55c0 4.12 3.24 7.2 7.56 7.2a7.06 7.06 0 0 0 6.88-7.2C21.44 6.08 18.2 3 14.56 3 10.24 3 7 6.08 7 9.55Zm1.38 0c0-2.74 2.62-5.24 5.5-5.24 3.56 0 6.18 2.5 6.18 5.24 0 3.39-2.62 5.89-6.18 5.89-2.88 0-5.5-2.5-5.5-5.9Z"
      clipRule="evenodd"
    />
  </SvgIcon>
);
