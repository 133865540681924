import { InputTrigger, Typography } from '@afosto/components';
import { Fieldset } from '../../../Fieldset';
import { Search } from '../../../../icons/regular';
import type { SelectExistingCustomerFormSectionProps } from './types';

export const SelectExistingCustomerFormSection = (
  props: SelectExistingCustomerFormSectionProps
) => {
  const { onShowSearch, placeholder, title } = props;

  return (
    <Fieldset title={title}>
      <InputTrigger
        onClick={onShowSearch}
        size="large"
        sx={{ '> button': { gap: 2 } }}
        autoFocus
        fullWidth
      >
        <Search />
        <Typography color="gray.600" variant="inherit">
          {placeholder}
        </Typography>
      </InputTrigger>
    </Fieldset>
  );
};
