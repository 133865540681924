import * as Sentry from '@sentry/react';
import type { AxiosError } from 'axios';
import type { ClientError } from '@afosto/graphql-client';

export const getErrorMessage = (
  error: Error | AxiosError | ClientError,
  options = {}
) => {
  const errorOptions = {
    skipSentry: false,
    ...(options || {}),
  };
  const errorResponse = error?.response || {};
  const errorResponseData = errorResponse?.data || {};
  const errorResponseError = errorResponseData?.error || {};
  const gqlResponseErrors = errorResponse?.errors || [];
  const [firstGqlError] = gqlResponseErrors || [];
  const gqlErrorExtensions = firstGqlError?.extensions || {};
  const pointers =
    errorResponseError?.details?.pointers ||
    firstGqlError?.extensions?.pointers;
  const [firstPointer] = pointers || [];
  const errorMessage =
    firstPointer?.message ||
    errorResponseError?.message ||
    errorResponseError?.details?.reference ||
    errorResponseData?.message ||
    gqlErrorExtensions?.reference ||
    firstGqlError?.message ||
    error?.message;

  if (!errorOptions?.skipSentry) {
    try {
      if (error) {
        Sentry.withScope((scope) => {
          scope.setExtra('message', errorMessage || '');

          if (errorMessage) {
            scope.setFingerprint([errorMessage]);
          }

          Sentry.captureException(error);
        });
      }
    } catch {
      // Do nothing.
    }
  }

  return errorMessage;
};
