import { Times } from '../../icons/regular';
import { EllipsisV } from '../../icons/solid';
import { ReceiptHeaderAction } from '../ReceiptHeaderAction';
import { useOrder } from '../OrderProvider/hooks/useOrder';

export const ReceiptHeaderActions = () => {
  const {
    openConfirmDeleteOrderDialog,
    openOrderActionsDialog,
    order,
    parkOrder,
  } = useOrder();

  const handleDeleteOrder = async () => {
    if (order?.state?.toLowerCase() === 'concept') {
      openConfirmDeleteOrderDialog();
    } else {
      parkOrder();
    }
  };

  if (!order) {
    return null;
  }

  return (
    <>
      <ReceiptHeaderAction onClick={openOrderActionsDialog}>
        <EllipsisV />
      </ReceiptHeaderAction>
      <ReceiptHeaderAction onClick={handleDeleteOrder}>
        <Times sx={{ color: (theme) => theme.palette.error.main }} />
      </ReceiptHeaderAction>
    </>
  );
};
