import { useIntl } from 'react-intl';
import { Card, Typography } from '@afosto/components';
import { Fieldset } from '../../../Fieldset';
import { translations } from './translations';
import type { OrganisationInformationSectionProps } from './types';

export const OrganisationInformationSection = (
  props: OrganisationInformationSectionProps
) => {
  const { organisation } = props;

  const intl = useIntl();

  return (
    <Fieldset title={intl.formatMessage(translations.organisationInformation)}>
      <Card sx={{ display: 'flex', flexDirection: 'column', gap: 1, p: 2.5 }}>
        <Typography variant="h6">{organisation?.name}</Typography>
        <Typography variant="bodyLarge">
          {organisation?.administration?.email}
        </Typography>
        {organisation?.phoneNumbers?.primary && (
          <Typography variant="bodyLarge">
            {organisation.phoneNumbers.primary.number}
          </Typography>
        )}
      </Card>
    </Fieldset>
  );
};
