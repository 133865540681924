import { useIntl } from 'react-intl';
import { Fields, Grid } from '@afosto/components';
import { Fieldset } from '../../../Fieldset';
import { translations } from './translations';

export const OrganisationDetailsFormSection = () => {
  const intl = useIntl();

  return (
    <Fieldset title={intl.formatMessage(translations.details)}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Fields.TextField
            name="organisation.name"
            label={intl.formatMessage(translations.name)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Fields.TextField
            name="organisation.email"
            label={intl.formatMessage(translations.email)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Fields.PhoneNumberField
            countryFieldName="organisation.phoneNumberCountry"
            numberFieldName="organisation.phoneNumberNumber"
            label={intl.formatMessage(translations.phoneNumber)}
            size="large"
            fullWidth
          />
        </Grid>
      </Grid>
    </Fieldset>
  );
};
