import { ActionsList } from '../ActionsList';
import { CardSelector } from '../CardSelector';
import type { Printer } from '../../types';
import type { PrintersListProps } from './types';

export const PrintersList = (props: PrintersListProps) => {
  const { printers, onSelectPrinter } = props;

  const handleSelectPrinter = (printer: Printer) => () => {
    onSelectPrinter(printer);
  };

  return (
    <ActionsList>
      {printers.map((printer) => (
        <CardSelector
          key={printer.id}
          label={printer.name}
          onSelect={handleSelectPrinter(printer)}
        />
      ))}
    </ActionsList>
  );
};
