import applyCaseMiddleware from 'axios-case-converter';
import { camelCase, camelCaseTransformMerge } from 'camel-case';
import * as Sentry from '@sentry/react';
import apiClient from '@afosto/api-client';
import { graphQLClient } from '@afosto/graphql-client';
import { isDefined } from '@afosto/utils';
import { getErrorMessage } from './getErrorMessage';
import type { AxiosError, AxiosRequestConfig } from 'axios';

export const setupApiClient = () => {
  apiClient.client.defaults.baseURL = 'https://afosto.app';
  apiClient.setClient(
    applyCaseMiddleware(apiClient.client, {
      caseFunctions: {
        camel: (input, options) =>
          camelCase(input, { ...options, transform: camelCaseTransformMerge }),
      },
      caseOptions: {
        splitRegexp: [
          /([a-z0-9])([A-Z])/g,
          /([A-Z])([A-Z][a-z])/g,
          /([0-9])([A-Za-z])/g,
          /([A-Za-z])([0-9])/g,
        ],
        stripRegexp: /[^A-Z0-9[\].]+/gi,
      },
      preservedKeys: (key) =>
        ['i18n'].includes(key) ||
        key?.startsWith('filter[') ||
        key?.startsWith('b2b') ||
        key?.startsWith('b2c') ||
        key?.startsWith('ga4'),
    })
  );

  apiClient.registerInterceptor(
    (config: AxiosRequestConfig, error: AxiosError) => {
      if (error) {
        return Promise.reject(error);
      }

      const hasCredentials = config.withCredentials === true;
      const enableProxy = import.meta.env.VITE_CREDENTIALS_PROXY === 'true';

      return {
        ...config,
        ...(hasCredentials
          ? {
              baseURL: enableProxy
                ? window.location.origin
                : apiClient.client.defaults.baseURL,
            }
          : {}),
      };
    },
    'request'
  );

  graphQLClient.setExcludeConversionKeys(['i18n']);
  graphQLClient.setOnErrorHandler((error, context) => {
    const message = getErrorMessage(error, { skipSentry: true }) || '';
    const statusCode = error?.response?.status?.toString() || '';

    Sentry.withScope((scope) => {
      scope.setExtras({
        message: message,
        query: context?.query,
        variables: isDefined(context?.variables)
          ? JSON.stringify(context.variables)
          : '',
      });
      scope.setFingerprint([context?.query, statusCode, message]);

      Sentry.captureException(error, {
        tags: {
          requestType: 'gql',
        },
      });
    });
  });
};
