import { useIntl } from 'react-intl';
import { ActionsList } from '../../../ActionsList';
import { CardSelector } from '../../../CardSelector';
import { Fieldset } from '../../../Fieldset';
import { Plus, Trash } from '../../../../icons/solid';
import { translations } from './translations';
import type { ContactActionsSectionProps } from './types';

export const ContactActionsSection = (props: ContactActionsSectionProps) => {
  const { disabled, isOrderContact, onAddToOrder, onRemoveFromOrder } = props;

  const intl = useIntl();

  return (
    <Fieldset title={intl.formatMessage(translations.actions)}>
      <ActionsList>
        {!isOrderContact && (
          <CardSelector
            disabled={disabled}
            Icon={Plus}
            label={intl.formatMessage(translations.addToOrder)}
            onSelect={onAddToOrder}
          />
        )}
        {isOrderContact && (
          <CardSelector
            color="error"
            disabled={disabled}
            Icon={Trash}
            label={intl.formatMessage(translations.removeFromOrder)}
            onSelect={onRemoveFromOrder}
          />
        )}
      </ActionsList>
    </Fieldset>
  );
};
