import {
  graphQLClient,
  gql,
  type GraphQLRequestOptions,
} from '@afosto/graphql-client';
import type { AdjustmentInput } from './addAdjustmentsToOrder';

export interface UpdateAdjustmentForOrderItemsResponse {
  [key: string]: {
    item: {
      id: string;
    };
  };
}

export interface UpdateAdjustmentForOrderItemsInput {
  itemIds: string[];
  adjustment: AdjustmentInput;
  requestOptions?: GraphQLRequestOptions<UpdateAdjustmentForOrderItemsResponse>;
}

export const updateAdjustmentForOrderItems = async ({
  itemIds,
  adjustment,
  requestOptions = {},
}: UpdateAdjustmentForOrderItemsInput) => {
  const mutation = gql`
    mutation UpdateAdjustmentForOrderItems(${itemIds
      .map((itemId, idx) => `$input${idx}: UpdateAdjustmentForOrderItemInput!`)
      .join(', ')}) {
      ${itemIds
        .map((itemId, idx) => {
          return `item${idx}: updateAdjustmentForOrderItem(input: $input${idx}) {
            item {
              id
            }
          }`;
        })
        .join('\n')}
    }
  `;

  return graphQLClient.request<UpdateAdjustmentForOrderItemsResponse>(
    mutation,
    itemIds.reduce((acc, itemId, idx) => {
      return {
        ...acc,
        [`input${idx}`]: {
          itemId,
          discountId: adjustment.id,
          description: adjustment.description,
          isPercentage: adjustment.isPercentage,
          amount: adjustment.amount,
        },
      };
    }, {}),
    requestOptions
  );
};
