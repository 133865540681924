import { SvgIcon, type SvgIconProps } from '@afosto/components';

export const EPSUberweisung = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 30 20">
    <rect
      width="27"
      height="19"
      x=".5"
      y=".5"
      fill="#fff"
      stroke="#DBE2EA"
      rx="3.5"
    />
    <path
      fill="#71706F"
      d="M19.92 9.64h-1.38a.28.28 0 0 1-.28-.28c0-.15.12-.29.28-.29h2.1V8.04h-2.1a1.33 1.33 0 0 0 0 2.65h1.36c.15 0 .27.13.27.28 0 .15-.12.26-.27.26h-2.92c-.25.48-.49.88-.97 1.08h3.9a1.34 1.34 0 0 0 0-2.68Z"
    />
    <path
      fill="#71706F"
      d="M14.63 8.04c-1.16 0-2.12.96-2.12 2.13v4.44h1.06v-2.3h1.06a2.14 2.14 0 0 0 0-4.27Zm0 3.2h-1.06v-1.07c0-.6.48-1.08 1.06-1.08a1.07 1.07 0 0 1 0 2.14Z"
    />
    <path
      fill="#C8036F"
      d="M9.17 12.31c-1 0-1.84-.7-2.07-1.64 0 0-.07-.3-.07-.51 0-.2.07-.51.07-.51a2.13 2.13 0 0 1 4.2.5v.52H8.18c.18.36.56.56.99.56h2.8V8.2a.83.83 0 0 0-.82-.83H7.18a.82.82 0 0 0-.83.82v3.97c0 .46.37.84.83.84h3.97c.4 0 .75-.3.81-.68h-2.8Z"
    />
    <path
      fill="#C8036F"
      d="M9.16 9.04c-.43 0-.8.26-.98.6h1.97c-.19-.34-.56-.6-.99-.6Zm1.8-2.97c0-.97-.8-1.75-1.8-1.75-.98 0-1.77.76-1.8 1.7v.54c0 .07.05.13.12.13h.66c.07 0 .12-.06.12-.13v-.49c0-.48.4-.88.9-.88s.9.4.9.88v.5c0 .06.05.12.12.12h.66c.07 0 .12-.06.12-.13v-.49Z"
    />
    <path
      fill="#71706F"
      d="m15.82 13.69-.1.54c-.04.27-.22.4-.47.4-.2 0-.36-.14-.32-.4l.1-.54h.15l-.1.54c-.03.17.05.26.2.26a.3.3 0 0 0 .3-.26l.1-.54h.14Zm-.58-.17c0 .04.03.06.07.06.05 0 .1-.03.1-.1 0-.04-.03-.06-.07-.06-.05 0-.1.03-.1.1Zm.32 0c0 .04.03.06.07.06.04 0 .1-.03.1-.1 0-.04-.03-.06-.07-.06-.05 0-.1.03-.1.1Zm.62.17-.07.37a.34.34 0 0 1 .25-.12c.17 0 .28.1.28.28 0 .25-.18.4-.4.4-.09 0-.16-.03-.21-.1l-.03.09h-.12l.16-.92h.14Zm-.1.63c-.01.1.06.18.16.18.13 0 .25-.1.26-.25 0-.11-.06-.18-.16-.18-.13 0-.26.1-.27.25Zm.82.02c0 .09.07.16.2.16.06 0 .15-.02.2-.07l.07.09a.46.46 0 0 1-.3.1c-.19 0-.3-.1-.3-.28 0-.23.18-.4.41-.4.21 0 .33.13.26.4h-.53Zm.43-.11c.01-.12-.06-.16-.17-.16-.1 0-.19.04-.23.16h.4Zm.47-.27v.08c.06-.08.14-.1.21-.1.07 0 .12.02.15.06l-.08.12a.14.14 0 0 0-.1-.04.2.2 0 0 0-.22.18l-.06.35h-.14l.12-.65h.13Zm1.02 0 .07.51.25-.51h.16l-.35.65h-.16l-.06-.43-.1.21-.12.22h-.16l-.12-.65h.16l.06.51.26-.51h.11Zm.65.38c0 .09.07.16.19.16.06 0 .15-.02.2-.07l.07.09a.46.46 0 0 1-.3.1c-.19 0-.3-.1-.3-.28 0-.23.18-.4.41-.4.22 0 .34.13.26.4h-.53Zm.42-.11c.01-.12-.05-.16-.16-.16-.1 0-.2.04-.24.16h.4Zm.49-.27-.12.65h-.13l.11-.65h.14Zm-.12-.18c0 .04.03.07.07.07a.1.1 0 0 0 .1-.1.07.07 0 0 0-.07-.07c-.05 0-.1.03-.1.1Zm.73.34c-.05-.05-.1-.06-.17-.06-.09 0-.15.03-.15.08s.05.07.13.08c.12 0 .27.05.24.22-.02.12-.15.2-.32.2a.3.3 0 0 1-.27-.13l.09-.1c.04.07.13.1.2.1s.15-.02.16-.08c.01-.06-.04-.08-.13-.1-.11 0-.24-.05-.24-.17 0-.16.18-.22.31-.22.1 0 .18.02.24.09l-.1.09Zm.43-.16-.06.35c-.02.1.03.19.14.19.1 0 .2-.1.22-.2l.06-.34h.14l-.12.65h-.12v-.1a.34.34 0 0 1-.24.11c-.16 0-.26-.11-.22-.31l.06-.35h.14Zm1.1.64.06-.34c.02-.11-.02-.19-.14-.19-.11 0-.2.09-.22.2l-.06.34h-.14l.12-.65h.12v.1a.3.3 0 0 1 .23-.11c.16 0 .26.1.23.3l-.06.36h-.14Zm.46.1c0 .09.06.12.18.12.1 0 .2-.05.23-.2l.02-.1a.31.31 0 0 1-.25.11c-.16 0-.28-.1-.28-.28 0-.26.19-.4.4-.4.1 0 .18.04.21.11l.02-.1h.14l-.12.67c-.04.25-.23.32-.4.32-.2 0-.3-.1-.28-.25h.13Zm.04-.38c0 .11.07.18.18.18.28 0 .36-.43.07-.43-.13 0-.25.1-.25.25Z"
    />
  </SvgIcon>
);
