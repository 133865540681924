import { useCallback, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  addPaymentMethodToOrder as addPaymentMethodToOrderMutationRequest,
  type AddPaymentMethodToOrderInput,
  createOrderPayment as createOrderPaymentMutationRequest,
  type CreateOrderPaymentInput,
  markPaymentAsPaid as markPaymentAsPaidMutationRequest,
  type MarkPaymentAsPaidInput,
} from '../../../mutations';
import { getOrderPayment as getOrderPaymentOptions } from '../../../queries';
import type { Order } from '../../../types';

export interface UseOrderCheckoutOptions {
  order: Order | null;
}

export const useOrderCheckout = (options: UseOrderCheckoutOptions) => {
  const { order } = options || {};

  const queryClient = useQueryClient();

  const [showCheckoutDialog, setShowCheckoutDialog] = useState(false);

  const { mutateAsync: addPaymentMethodToOrderMutation } = useMutation({
    mutationFn: addPaymentMethodToOrderMutationRequest,
  });

  const { mutateAsync: createOrderPaymentMutation } = useMutation({
    mutationFn: createOrderPaymentMutationRequest,
  });

  const { mutateAsync: markPaymentAsPaidMutation } = useMutation({
    mutationFn: markPaymentAsPaidMutationRequest,
  });

  const closeCheckoutDialog = useCallback(() => {
    setShowCheckoutDialog(false);
  }, []);

  const openCheckoutDialog = useCallback(() => {
    setShowCheckoutDialog(true);
  }, []);

  const addPaymentMethodToOrder = useCallback(
    async (input: Omit<AddPaymentMethodToOrderInput, 'orderId'>) => {
      if (!order) {
        throw new Error('Order not found!');
      }

      const { methodId, issuerId, requestOptions } = input || {};

      return addPaymentMethodToOrderMutation({
        orderId: order.id,
        methodId,
        issuerId: issuerId || '',
        requestOptions,
      });
    },
    [addPaymentMethodToOrderMutation, order]
  );

  const createOrderPayment = useCallback(
    async (input: Omit<CreateOrderPaymentInput, 'orderId'>) => {
      if (!order) {
        throw new Error('Order not found!');
      }

      const { payment, requestOptions } = input || {};

      return createOrderPaymentMutation({
        orderId: order.id,
        payment,
        requestOptions,
      });
    },
    [createOrderPaymentMutation, order]
  );

  const getOrderPayment = useCallback(
    async (id: string) => {
      const response = await queryClient.fetchQuery(getOrderPaymentOptions(id));
      return response?.data;
    },
    [queryClient]
  );

  const markPaymentAsPaid = useCallback(
    async (input: MarkPaymentAsPaidInput) => {
      return markPaymentAsPaidMutation(input);
    },
    [markPaymentAsPaidMutation]
  );

  return {
    addPaymentMethodToOrder,
    closeCheckoutDialog,
    createOrderPayment,
    getOrderPayment,
    markPaymentAsPaid,
    openCheckoutDialog,
    showCheckoutDialog,
  };
};
