import { useCallback, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import {
  addAdjustmentsToOrder as addAdjustmentsToOrderMutationRequest,
  type AddAdjustmentsToOrderInput,
  addCouponToOrder as addCouponToOrderMutationRequest,
  type AddCouponToOrderInput,
  removeAdjustmentsFromOrder as removeAdjustmentsFromOrderMutationRequest,
  type RemoveAdjustmentsFromOrderInput,
  removeCouponFromOrder as removeCouponFromOrderMutationRequest,
  type RemoveCouponFromOrderInput,
  updateAdjustmentForOrder as updateAdjustmentForOrderMutationRequest,
  type UpdateAdjustmentForOrderInput,
} from '../../../mutations';
import type { Order } from '../../../types';

export interface UseOrderDiscountOptions {
  order: Order | null;
  setOrder: (order: Order | null) => void;
}

export const useOrderDiscount = (options: UseOrderDiscountOptions) => {
  const { order, setOrder } = options || {};

  const [showManageOrderDiscountDialog, setShowManageOrderDiscountDialog] =
    useState(false);

  const { mutateAsync: addAdjustmentsToOrderMutation } = useMutation({
    mutationFn: addAdjustmentsToOrderMutationRequest,
  });

  const { mutateAsync: addCouponToOrderMutation } = useMutation({
    mutationFn: addCouponToOrderMutationRequest,
  });

  const { mutateAsync: removeAdjustmentsFromOrderMutation } = useMutation({
    mutationFn: removeAdjustmentsFromOrderMutationRequest,
  });

  const { mutateAsync: removeCouponFromOrderMutation } = useMutation({
    mutationFn: removeCouponFromOrderMutationRequest,
  });

  const { mutateAsync: updateAdjustmentForOrderMutation } = useMutation({
    mutationFn: updateAdjustmentForOrderMutationRequest,
  });

  const closeManageOrderDiscountDialog = useCallback(() => {
    setShowManageOrderDiscountDialog(false);
  }, []);

  const openManageOrderDiscountDialog = useCallback(() => {
    setShowManageOrderDiscountDialog(true);
  }, []);

  const addAdjustmentsToOrder = useCallback(
    async (input: Omit<AddAdjustmentsToOrderInput, 'orderId'>) => {
      if (!order) {
        throw new Error('Order not found');
      }

      const { adjustments, requestOptions } = input || {};

      const response = await addAdjustmentsToOrderMutation({
        adjustments,
        orderId: order.id,
        requestOptions,
      });

      setOrder(response?.addAdjustmentsToOrder?.order || null);
    },
    [addAdjustmentsToOrderMutation, order, setOrder]
  );

  const addCouponToOrder = useCallback(
    async (input: Omit<AddCouponToOrderInput, 'orderId'>) => {
      if (!order) {
        throw new Error('Order not found');
      }

      const { coupon, requestOptions } = input || {};

      const response = await addCouponToOrderMutation({
        orderId: order.id || '',
        coupon,
        requestOptions,
      });

      setOrder(response?.addCouponToOrder?.order || null);
    },
    [addCouponToOrderMutation, order, setOrder]
  );

  const removeAdjustmentsFromOrder = useCallback(
    async (input: Omit<RemoveAdjustmentsFromOrderInput, 'orderId'>) => {
      if (!order) {
        throw new Error('Order not found');
      }

      const { adjustmentIds, requestOptions } = input || {};

      const response = await removeAdjustmentsFromOrderMutation({
        adjustmentIds,
        orderId: order.id,
        requestOptions,
      });

      setOrder(response?.removeAdjustmentsFromOrder?.order || null);
    },
    [removeAdjustmentsFromOrderMutation, order, setOrder]
  );

  const removeCouponFromOrder = useCallback(
    async (input: Omit<RemoveCouponFromOrderInput, 'orderId'>) => {
      if (!order) {
        throw new Error('Order not found');
      }

      const { coupon, requestOptions } = input || {};

      const response = await removeCouponFromOrderMutation({
        orderId: order.id || '',
        coupon,
        requestOptions,
      });

      setOrder(response?.removeCouponFromOrder?.order || null);
    },
    [removeCouponFromOrderMutation, order, setOrder]
  );

  const updateAdjustmentForOrder = useCallback(
    async (input: Omit<UpdateAdjustmentForOrderInput, 'orderId'>) => {
      if (!order) {
        throw new Error('Order not found');
      }

      const { adjustment, requestOptions } = input || {};

      const response = await updateAdjustmentForOrderMutation({
        adjustment,
        orderId: order.id,
        requestOptions,
      });

      setOrder(response?.updateAdjustmentForOrder?.order || null);
    },
    [updateAdjustmentForOrderMutation, order, setOrder]
  );

  return {
    addAdjustmentsToOrder,
    addCouponToOrder,
    closeManageOrderDiscountDialog,
    openManageOrderDiscountDialog,
    removeAdjustmentsFromOrder,
    removeCouponFromOrder,
    showManageOrderDiscountDialog,
    updateAdjustmentForOrder,
  };
};
