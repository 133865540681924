import { SvgIcon, type SvgIconProps } from '@afosto/components';

export const Bank = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 30 20">
    <rect
      width="27"
      height="19"
      x=".5"
      y=".5"
      fill="#fff"
      stroke="#DAE1E7"
      rx="3.5"
    />
    <path
      fill="#4B6A81"
      d="m13.67 3.32-5.9 2.53a.85.85 0 0 0 .33 1.62v.21c0 .35.28.63.63.63h10.54c.35 0 .64-.28.64-.63v-.21a.84.84 0 0 0 .33-1.62l-5.9-2.53a.85.85 0 0 0-.67 0Zm-3.04 5.84h-1.7v5.17l-.04.03-1.27.84a.84.84 0 0 0 .47 1.55h11.82a.84.84 0 0 0 .46-1.55l-1.26-.84c-.02 0-.03-.02-.05-.03V9.16h-1.68v5.06h-1.06V9.16h-1.69v5.06h-1.26V9.16h-1.69v5.06h-1.05V9.16ZM14 4.94a.84.84 0 1 1 0 1.68.84.84 0 0 1 0-1.68Z"
    />
  </SvgIcon>
);
