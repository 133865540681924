import { defineMessages } from 'react-intl';

export const translations = defineMessages({
  existingContact: {
    id: 'common.existingContact',
    defaultMessage: 'Existing contact',
    description: 'Common label for existing contact',
  },
  existingOrganisation: {
    id: 'common.existingOrganisation',
    defaultMessage: 'Existing organisation',
    description: 'Common label for existing organisation',
  },
  searchAnExistingContact: {
    id: 'common.searchAnExistingContact',
    defaultMessage: 'Search an existing contact',
    description: 'Common label for search an existing contact',
  },
  searchAnExistingOrganisation: {
    id: 'common.searchAnExistingOrganisation',
    defaultMessage: 'Search an existing organisation',
    description: 'Common label for search an existing organisation',
  },
});
