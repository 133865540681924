import { defineMessages } from 'react-intl';

export const translations = defineMessages({
  actions: {
    id: 'common.actions',
    defaultMessage: 'Actions',
    description: 'Common label for actions',
  },
  coupon: {
    id: 'common.coupon',
    defaultMessage: 'Coupon',
    description: 'Common label for coupon',
  },
  discountOnTheOrder: {
    id: 'common.discountOnTheOrder',
    defaultMessage: 'Discount on the order',
    description: 'Common label for discount on order',
  },
});
