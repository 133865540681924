import { FormattedMessage } from 'react-intl';
import { Button, useFormContext } from '@afosto/components';
import { translations } from './translations';

export const ClearFieldButton = () => {
  const { setValue, watch } = useFormContext();
  const noteValue = watch('note');

  const handleClearField = () => {
    setValue('note', '');
  };

  if (!noteValue?.length) {
    return null;
  }

  return (
    <Button
      color="error"
      onClick={handleClearField}
      size="large"
      variant="outlined"
      fullWidth
    >
      <FormattedMessage {...translations.clearField} />
    </Button>
  );
};
