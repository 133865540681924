import {
  graphQLClient,
  gql,
  type GraphQLRequestOptions,
} from '@afosto/graphql-client';
import { CoreOrderFragment } from '../fragments';
import type { Order } from '../types';

export interface RemoveCouponFromOrderResponse {
  removeCouponFromOrder: {
    order: Order;
  };
}

export interface RemoveCouponFromOrderInput {
  orderId: string;
  coupon: string;
  requestOptions?: GraphQLRequestOptions<RemoveCouponFromOrderResponse>;
}

export const removeCouponFromOrder = async ({
  orderId,
  coupon,
  requestOptions = {},
}: RemoveCouponFromOrderInput) => {
  const mutation = gql`
    ${CoreOrderFragment}
    mutation RemoveCouponFromOrder($input: RemoveCouponFromOrderInput!) {
      removeCouponFromOrder(input: $input) {
        order {
          ...CoreOrderFragment
        }
      }
    }
  `;

  return graphQLClient.request<RemoveCouponFromOrderResponse>(
    mutation,
    {
      input: {
        orderId,
        coupon,
      },
    },
    requestOptions
  );
};
