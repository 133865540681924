import { styled } from '@afosto/components';

export const Container = styled('main')`
  background-color: ${(props) => props.theme.palette.common.white};
  display: grid;
  grid-template-rows: calc(-64px + -88px + 100vh);
  grid-template-columns: 60% 40%;
  overflow: hidden;
`;

export const RightLayout = styled('div')`
  border-left: 1px solid ${(props) => props.theme.palette.divider};
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  overflow: hidden;
`;
