import dayjs, { type ManipulateType, type OpUnitType } from 'dayjs';
import { isDefined } from '@afosto/utils';
import { isRelativeDate } from './isRelativeDate';

export const convertRelativeDateToUnixTimestamp = (
  value: unknown,
  options?: { startOf?: OpUnitType; endOf?: OpUnitType; subtract?: boolean }
) => {
  if (!isDefined(value) || !isRelativeDate(value)) {
    return null;
  }

  const convertOptions = {
    subtract: false,
    ...(options || {}),
  };
  const [amount, unit] = (value as string).split('_');
  const formattedAmount = Number(amount);
  const shouldSubtract = formattedAmount < 0 || convertOptions.subtract;

  let dateValue = shouldSubtract
    ? dayjs().subtract(Math.abs(formattedAmount), unit as ManipulateType)
    : dayjs().add(Math.abs(formattedAmount), unit as ManipulateType);

  if (convertOptions.startOf) {
    dateValue = dateValue.startOf(convertOptions.startOf);
  }

  if (convertOptions.endOf) {
    dateValue = dateValue.endOf(convertOptions.endOf);
  }

  return dateValue.valueOf();
};
