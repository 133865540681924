import { OrderContext } from './OrderContext';
import { useOrderProvider } from './hooks/useOrderProvider';
import type { OrderProviderProps } from './types';

export const OrderProvider = (props: OrderProviderProps) => {
  const { children } = props;

  const orderProviderState = useOrderProvider();

  return (
    <OrderContext.Provider value={orderProviderState}>
      {children}
    </OrderContext.Provider>
  );
};
