import {
  graphQLClient,
  gql,
  type GraphQLRequestOptions,
} from '@afosto/graphql-client';
import { CoreOrderFragment } from '../fragments';
import type { Order, OrderProceedingStep } from '../types';

export interface AddPaymentMethodToOrderResponse {
  addPaymentMethodToOrder: {
    order: Order & {
      options: {
        proceedingStep: OrderProceedingStep;
      };
    };
  };
}

export interface AddPaymentMethodToOrderInput {
  orderId: string;
  methodId: string;
  issuerId?: string;
  requestOptions?: GraphQLRequestOptions<AddPaymentMethodToOrderResponse>;
}

export const addPaymentMethodToOrder = async ({
  orderId,
  methodId,
  issuerId,
  requestOptions = {},
}: AddPaymentMethodToOrderInput) => {
  const mutation = gql`
    ${CoreOrderFragment}
    mutation AddPaymentMethodToOrder($input: AddPaymentMethodToOrderInput!) {
      addPaymentMethodToOrder(input: $input) {
        order {
          ...CoreOrderFragment
          options {
            proceeding_step {
              is_action_required
              proceeding {
                action
                options {
                  type
                  method
                  url
                }
              }
            }
          }
        }
      }
    }
  `;

  return graphQLClient.request<AddPaymentMethodToOrderResponse>(
    mutation,
    {
      input: {
        orderId,
        methodId,
        issuerId,
      },
    },
    requestOptions
  );
};
