import { SvgIcon, type SvgIconProps } from '@afosto/components';

export const Walley = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 30 20">
    <rect width="28" height="20" fill="#0D2127" rx="4" />
    <path
      fill="#fff"
      d="M9.21 8.4v2.08L7.1 9.43a.33.33 0 0 0-.3 0l-2.12 1.05V8.4H3v.01l.98.57v2.1c0 .19.25.31.44.21l2.52-1.25 2.51 1.25c.2.1.45-.02.45-.22V8.4h-.7Zm5.01 1.48v1.45h-.72v-.36c-.55.25-1.16.44-1.85.44-.86 0-1.34-.3-1.34-.96 0-.75.8-1 1.7-1 .53 0 1.04.1 1.5.22-.03-.62-.78-.7-1.21-.7a5 5 0 0 0-1.55.24l-.2-.54a6.5 6.5 0 0 1 1.84-.28c1.2 0 1.82.38 1.83 1.49Zm-.72.6v-.28a5.5 5.5 0 0 0-1.42-.2c-.63 0-1.01.12-1.01.43 0 .33.3.4.8.4a5.9 5.9 0 0 0 1.63-.35Zm1.15-2.98h.7v3.83h-.7V7.49Zm1.16 0h.71v3.83h-.7V7.49Zm4.24 2.86h.74c-.17.65-.83 1.04-1.88 1.04-1.18 0-2-.48-2-1.5 0-1.01.85-1.5 1.96-1.5 1.12 0 1.96.43 1.96 1.66h-3.17c.07.54.5.76 1.26.76.7 0 1-.19 1.13-.46Zm-2.35-.8h2.35c-.13-.42-.53-.6-1.18-.6-.62 0-1.04.17-1.17.6Z"
    />
    <path
      fill="#fff"
      d="M24.2 8.4h.8l-1.71 3.12c-.38.7-.75 1.1-1.5 1.1-.38 0-.7-.05-1-.11v-.62c.37.09.64.11.86.11.43 0 .65-.12.92-.63l.02-.04h-.39L20.6 8.4h.8l1.4 2.55 1.4-2.55Z"
    />
  </SvgIcon>
);
