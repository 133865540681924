import { IconButton, Toolbar } from '@afosto/components';
import { Times } from '../../icons/regular';
import * as Styled from './DialogTopBar.styles';
import type { DialogTopBarProps } from './types';

export const DialogTopBar = (props: DialogTopBarProps) => {
  const {
    actions,
    disableCloseButton = false,
    hideActions = false,
    onClose,
  } = props || {};

  const handleClose = () => {
    if (onClose && typeof onClose === 'function') {
      onClose({}, 'escapeKeyDown');
    }
  };

  return (
    <Styled.TopBar>
      <Toolbar disableGutters>
        {!!onClose && (
          <Styled.CloseButton>
            <IconButton
              disabled={disableCloseButton}
              onClick={handleClose}
              variant="minimal"
              size="large"
            >
              <Times />
            </IconButton>
          </Styled.CloseButton>
        )}
        {!hideActions && !!actions && (
          <Styled.ActionsContainer>{actions}</Styled.ActionsContainer>
        )}
      </Toolbar>
    </Styled.TopBar>
  );
};
