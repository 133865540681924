import { FormattedMessage } from 'react-intl';
import { Card, CardActionArea, Typography } from '@afosto/components';
import { useOrder } from '../OrderProvider/hooks/useOrder';
import { getFullName } from '../../utils';
import { translations } from './translations';

export const ReceiptCustomer = () => {
  const {
    openAddCustomerDialog,
    openManageContactDialog,
    openManageOrganisationDialog,
    order,
  } = useOrder();
  const { contact, organisation } = order?.customer || {};
  const hasCustomer = !!contact?.id || !!organisation?.id;

  const handleClick = () => {
    if (hasCustomer) {
      if (contact) {
        openManageContactDialog(contact);
      } else if (organisation) {
        openManageOrganisationDialog(organisation);
      }
    } else {
      openAddCustomerDialog();
    }
  };

  return (
    <Card sx={{ borderRadius: 0, borderLeft: 0, borderRight: 0, borderTop: 0 }}>
      <CardActionArea onClick={handleClick} sx={{ px: 2.5, py: 2 }}>
        {!hasCustomer && (
          <Typography color="primary.main" fontWeight={500} variant="h6">
            <FormattedMessage {...translations.addCustomer} />
          </Typography>
        )}
        {hasCustomer && contact && !organisation && (
          <>
            <Typography fontWeight={500} variant="h6">
              {getFullName(contact)}
            </Typography>
            <Typography color="gray.800">{contact.email}</Typography>
          </>
        )}
        {hasCustomer && organisation && (
          <>
            <Typography fontWeight={500} variant="h6">
              {organisation.name}
            </Typography>
            <Typography color="gray.800">
              {organisation.administration?.email || '-'}
            </Typography>
          </>
        )}
      </CardActionArea>
    </Card>
  );
};
