import { Avatar, Fields, Typography } from '@afosto/components';
import { ActionsList } from '../../../ActionsList';
import { CardSelector } from '../../../CardSelector';
import { convertImageUrl } from '../../../../utils';
import * as Styled from './CancelableItemsFormSection.styles';
import type { CancelableOrderItemsFormSectionProps } from './types';

export const CancelableOrderItemsFormSection = (
  props: CancelableOrderItemsFormSectionProps
) => {
  const { items = [] } = props;

  return (
    <ActionsList>
      {(items || []).map((item) => {
        const [firstItemId] = item.ids || [];
        const imageUrl = convertImageUrl(item?.image);

        return (
          <CardSelector key={firstItemId}>
            <Styled.Container>
              <Styled.ProductInformation>
                <Avatar src={imageUrl} variant="rounded" />
                <Typography fontWeight={500} variant="bodyLarge">
                  {item.label || item.sku}
                </Typography>
              </Styled.ProductInformation>
              <Fields.Counter
                name={`items.${firstItemId}.quantity`}
                max={item.quantity}
              />
            </Styled.Container>
          </CardSelector>
        );
      })}
    </ActionsList>
  );
};
