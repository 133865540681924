import { SvgIcon, type SvgIconProps } from '@afosto/components';

export const Alipay = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 30 20">
    <rect
      width="27"
      height="19"
      x=".5"
      y=".5"
      fill="#fff"
      stroke="#DBE2EA"
      rx="3.5"
    />
    <path
      fill="#1677FF"
      d="M7.5 7.19H3.7c-.5 0-.9.42-.9.94v4c0 .52.4.94.9.94h3.8c.5 0 .9-.42.9-.94v-4c0-.52-.4-.94-.9-.94Z"
    />
    <path
      fill="#fff"
      d="m7.41 10.88-.86-.32c.2-.37.36-.79.47-1.24h-1.1V8.9h1.35v-.23H5.92v-.7h-.56c-.1 0-.1.1-.1.1v.6H3.9v.23h1.37v.42H4.14v.23h2.19c-.08.29-.2.56-.32.8-.7-.24-1.47-.44-1.94-.31-.3.07-.5.21-.62.36-.53.68-.15 1.7.97 1.7.66 0 1.3-.38 1.79-1.02.73.37 2.19 1.01 2.19 1.01v-.9s-.18-.02-.99-.3Zm-3.1.83c-.87 0-1.13-.72-.7-1.11.15-.14.41-.2.55-.22.52-.05 1 .16 1.56.45-.4.54-.9.88-1.4.88Z"
    />
    <path
      fill="#1677FF"
      d="M14.96 8.05c0 .3.21.5.51.5.3 0 .51-.2.51-.5a.5.5 0 0 0-.5-.5.5.5 0 0 0-.52.5Z"
    />
    <path
      fill="#000"
      d="M14.42 7.67h-.89v4.27h.9V7.67Zm-2.44.15h-1.2l-1.33 4.12h.82l.23-.8h1.41l.21.8h1.05l-1.2-4.12Zm-1.3 2.64.53-1.9h.02l.5 1.9h-1.05Zm5.24-1.66h-.9v3.14h.9V8.8Zm9.28.01h-.84l-.53 1.9h-.02l-.61-1.9h-1l1.2 3.14-.5.96v.03h.78L25.2 8.8Zm-7.07-.07c-.33 0-.58.13-.87.37v-.3h-.89v4.13h.89V11.9c.17.05.33.07.52.07.79 0 1.5-.6 1.5-1.69 0-.97-.51-1.55-1.15-1.55Zm-.57 2.66c-.1 0-.2 0-.3-.04v-1.7c.18-.14.34-.2.53-.2.33 0 .6.28.6.86 0 .75-.4 1.08-.83 1.08Zm4.47-.24V9.85c0-.72-.4-1.11-1.12-1.11-.45 0-.77.08-1.34.26l.16.72c.52-.24.75-.35 1-.35.28 0 .41.22.41.55v.03c-1.02.2-1.33.3-1.53.51a.87.87 0 0 0-.2.62c0 .6.45.93.87.93.31 0 .56-.13.9-.4l.07.33h.89l-.11-.78Zm-.89.02c-.23.14-.37.19-.53.19-.2 0-.34-.15-.34-.38 0-.09.01-.18.08-.25.1-.1.31-.2.8-.3v.74Z"
    />
  </SvgIcon>
);
