import { useIntl } from 'react-intl';
import { FullscreenDialog } from '../FullscreenDialog';
import { ChannelsList } from '../ChannelsList';
import { useChannel } from '../ChannelProvider/hooks/useChannel';
import { translations } from './translations';
import type { SelectChannelDialogProps } from './types';

export const SelectChannelDialog = (props: SelectChannelDialogProps) => {
  const { appear = true, onSelectChannel, ...otherProps } = props;

  const intl = useIntl();
  const { channelOptions } = useChannel();

  return (
    <FullscreenDialog
      {...otherProps}
      title={intl.formatMessage(translations.title)}
      description={intl.formatMessage(translations.description)}
      TransitionProps={{ appear }}
    >
      <ChannelsList
        channels={channelOptions}
        onSelectChannel={onSelectChannel}
      />
    </FullscreenDialog>
  );
};
