import { ScrollContainer } from '@afosto/components';
import { ReceiptCustomer } from '../ReceiptCustomer';
import { ReceiptHeaderActions } from '../ReceiptHeaderActions';
import { ReceiptItems } from '../ReceiptItems';
import { ReceiptSummary } from '../ReceiptSummary';
import { ReceiptTitle } from '../ReceiptTitle';
import * as Styled from './Receipt.styles';

export const Receipt = () => {
  return (
    <Styled.Container>
      <Styled.ReceiptHeader>
        <ReceiptTitle />
        <ReceiptHeaderActions />
      </Styled.ReceiptHeader>
      <ReceiptCustomer />
      <ScrollContainer>
        <Styled.ReceiptContent>
          <ReceiptItems />
        </Styled.ReceiptContent>
      </ScrollContainer>
      <ReceiptSummary />
    </Styled.Container>
  );
};
