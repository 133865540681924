import { Box, styled } from '@afosto/components';

export const Container = styled(Box)`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: ${(props) => props.theme.spacing(2)};
  padding: ${(props) =>
    `${props.theme.spacing(2)} ${props.theme.spacing(2.5)}`};
`;

export const ProductInformation = styled('div')`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: ${(props) => props.theme.spacing(4)};
  width: 100%;
`;

export const AdjustmentContainer = styled('div')`
  align-items: center;
  display: flex;
  gap: ${(props) => props.theme.spacing(1)};
`;
