import { SvgIcon, type SvgIconProps } from '@afosto/components';

export const Bacs = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 30 20">
    <rect width="28" height="20" fill="#E61C44" rx="4" />
    <path
      fill="#fff"
      d="M8.69 8.53c.09.79-.66 1.55-1.42 1.96.73-.21 2.4-1.48 1.57-2.68a3.45 3.45 0 0 0-2.13-1.4l-.06-.02h-.11c1.43.52 2.06 1.39 2.15 2.14Z"
    />
    <path
      fill="#fff"
      d="M5.4 10.87c2.3-.2 3.29-1.63 2.88-2.79-.4-1.15-2.05-1.67-2.05-1.67L6.2 6.4h-.57c.47.13 2.58.77 2.41 2.27-.2 1.69-1.66 2.03-2.63 2.21Z"
    />
    <path
      fill="#fff"
      d="M7.3 8.77c.32-1.8-2.53-2.37-2.53-2.37h-.08l-.8 4.18c.87.02 3.09 0 3.4-1.8Z"
    />
    <path
      fill="#fff"
      d="M6.84 6.4c1.55.23 3.53 1.98 1.8 3.82-1.63 1.73-4.53 1.23-4.9 1.15l-.23 1.25h3.46c1.23 0 2.29-.94 2.52-2.15l.8-4.07H6.83Zm6.4 4.35c-.46.96-.9 1.21-1.56 1.28-.66.07-.63-.72-.63-.72-.03-.51.26-1.54.7-2 .44-.44.78-.49 1.17-.42.4.07.78.9.32 1.86Zm0-2.44c-1.11-.24-1.8.8-1.8.8L12 6.4h-.71l-.96 4.86s-.23 1.32 1.18 1.32c1.4 0 1.97-.82 2.37-1.6.4-.8.45-2.43-.65-2.67Zm3.94 2.23c-.32.78-.81 1.51-1.4 1.45-.6-.05-.84-.7-.62-1.62a2.05 2.05 0 0 1 2.35-1.5s-.02.89-.33 1.67Zm1.15-2.05s-.32-.25-1.2-.25c-.88 0-1.91.4-2.54 1.76-.63 1.36 0 2.2.33 2.4.34.18 1 .36 1.49-.09.49-.44.7-.87.7-.87l-.04 1.14h.7s-.05-1.2.07-1.82c.12-.62.5-2.27.5-2.27Zm3.47-.05-.23.57s-.6-.24-1.1-.1a2 2 0 0 0-1.27 1.59c-.07.8.12 1.45.86 1.5.74.04 1.08-.24 1.08-.24l.04.6s-.41.25-1.11.24c-.7-.02-1.65-.28-1.66-1.55 0-1.26.6-2.24 1.56-2.6.95-.37 1.82 0 1.82 0Zm2.75.06-.22.51s-.5-.22-.81-.17c-.3.05-.63.16-.64.6 0 .44.94.98.94.98s.46.4.38.98c-.07.57-.66 1.2-1.26 1.2s-1.33-.27-1.33-.27l.18-.57s.56.28 1 .28c.43 0 .7-.32.68-.7-.02-.36-.35-.58-.68-.8-.32-.21-.7-.58-.63-1.2.06-.6.82-1.14 1.5-1.1.68.05.89.25.89.25Z"
    />
  </SvgIcon>
);
