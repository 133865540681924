import apiClient from '@afosto/api-client';
import { Suggest } from '@afosto/lookup-service';
import { queryOptions } from '@tanstack/react-query';
import type { LookupProduct, LookupResponseItem } from '../types';

export const SEARCH_PRODUCT_SUGGEST_QUERY = 'searchProductSuggest';

export const fetchSearchProductSuggest = async ({
  queryKey,
}: {
  queryKey: [string, string, object];
}): Promise<LookupProduct[]> => {
  const [, q, requestOptions] = queryKey;
  const suggestApi = new Suggest(apiClient);
  const response = await suggestApi.suggestQuery(
    { ...(q ? { filterDocQ: q } : {}) },
    requestOptions
  );
  return (response?.data?.data || []).map((data: LookupResponseItem) => {
    const { attributes, label, ...otherData } = data || {};
    const { sku: skuAttribute, ...otherAttributes } = attributes || {};
    const [sku] = skuAttribute || [];

    return {
      ...otherData,
      ...otherAttributes,
      label,
      sku,
    };
  });
};

export const searchProductSuggest = (q: string, requestOptions = {}) => {
  return queryOptions({
    queryKey: [SEARCH_PRODUCT_SUGGEST_QUERY, q, requestOptions],
    queryFn: fetchSearchProductSuggest,
  });
};
