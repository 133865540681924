import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { addQueryParamsToUrl, isDefined, isRelativeUri } from '@afosto/utils';
import { useAuthentication } from '@afosto/auth-react';
import type { LinkProps } from './types';

const Link = forwardRef<HTMLAnchorElement, LinkProps>((props, ref) => {
  const { children, href, to, ...otherProps } = props;

  const { tenant } = useAuthentication();
  const tenantId = tenant?.id;
  const url = href || (to && typeof to === 'object' ? to.pathname : to);
  const path = addQueryParamsToUrl(url, tenantId ? { t: tenantId } : {});
  const isRelative = isDefined(path) && isRelativeUri(path);
  const toProps = typeof to === 'object' ? to : {};
  const { state } = toProps || {};

  if (!isRelative) {
    return (
      <a {...otherProps} href={href}>
        {children}
      </a>
    );
  }

  return (
    <RouterLink ref={ref} to={path} state={state} {...otherProps}>
      {children}
    </RouterLink>
  );
});

export default Link;
