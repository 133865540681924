import { defineMessages } from 'react-intl';

export const translations = defineMessages({
  add: {
    id: 'actions.add',
    defaultMessage: 'Add',
    description: 'Common label for the add action',
  },
  addNote: {
    id: 'actions.addNote',
    defaultMessage: 'Add note',
    description: 'Common label for the add note action',
  },
  editNote: {
    id: 'actions.editNote',
    defaultMessage: 'Edit note',
    description: 'Common label for the edit note action',
  },
  typeANote: {
    id: 'common.typeANote',
    defaultMessage: 'Type a note',
    description: 'Common label for type a note',
  },
  save: {
    id: 'actions.save',
    defaultMessage: 'Save',
    description: 'Common label for the save action',
  },
});
