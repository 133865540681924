import apiClient from '@afosto/api-client';
import { Management } from '@afosto/payment-service';

export interface MarkPaymentAsPaidInputPayment {
  amount: number;
  id: string;
}

export interface MarkPaymentAsPaidInput {
  payment: MarkPaymentAsPaidInputPayment;
  requestOptions?: object;
}

export const markPaymentAsPaid = async ({
  payment,
  requestOptions = {},
}: MarkPaymentAsPaidInput) => {
  const { id, amount } = payment || {};
  const managementClient = new Management(apiClient);

  await managementClient.markPaymentAsPaid(
    id,
    { data: { amount } },
    requestOptions
  );
};
