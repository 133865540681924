import { ForgotPassword as ForgotPasswordForm } from '@afosto/auth-react';
import { Grid } from '@afosto/components';

export const Forgot = () => {
  return (
    <Grid item xs={12} sm={8} md={6}>
      <ForgotPasswordForm />
    </Grid>
  );
};
