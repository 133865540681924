import { useState } from 'react';
import { useIntl } from 'react-intl';
import { uuid } from '@afosto/utils';
import { AdjustmentActionsFormSection } from '../AdjustmentActionsFormSection';
import { FormDialog } from '../FormDialog';
import { DiscountFormSection } from '../DiscountFormSection';
import { useOrder } from '../OrderProvider/hooks/useOrder';
import { validationSchema } from './validationSchema';
import { translations } from './translations';
import type {
  ManageOrderItemAdjustmentFormData,
  ManageOrderItemAdjustmentFormDialogProps,
} from './types';

export const ManageOrderItemAdjustmentFormDialog = (
  props: ManageOrderItemAdjustmentFormDialogProps
) => {
  const { adjustment, formProps, item, onExited, open, ...otherProps } = props;

  const intl = useIntl();
  const {
    addAdjustmentsToOrderItems,
    closeManageOrderItemAdjustmentDialog,
    order,
    removeAdjustmentsFromOrderItems,
    updateAdjustmentForOrderItems,
  } = useOrder();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const submitLabelTranslationKey = (
    adjustment ? 'save' : 'add'
  ) as keyof typeof translations;
  const titleTranslationKey = (
    adjustment ? 'editItemDiscount' : 'addItemDiscount'
  ) as keyof typeof translations;

  const defaultValues = {
    amount: adjustment?.isPercentage
      ? adjustment?.amount || ''
      : adjustment?.outcome?.amount || '',
    description: adjustment?.description || '',
    type: adjustment
      ? adjustment.isPercentage
        ? 'percentage'
        : 'amount'
      : 'percentage',
  };

  const handleRemoveAdjustment = async () => {
    try {
      // TODO: Do something with loading state.

      if (!adjustment) {
        return;
      }

      await removeAdjustmentsFromOrderItems({
        adjustmentIds: [adjustment.id],
        itemIds: item?.ids || [],
      });

      closeManageOrderItemAdjustmentDialog();
    } catch {
      // TODO: Do something with error.
    }
  };

  const handleSubmit = async (data: ManageOrderItemAdjustmentFormData) => {
    try {
      setIsSubmitting(true);

      const discountAmount =
        typeof data.amount === 'string'
          ? parseInt(data.amount, 10)
          : data.amount;

      if (adjustment) {
        await updateAdjustmentForOrderItems({
          adjustment: {
            ...adjustment,
            amount: discountAmount,
            description: data.description,
            isPercentage: data.type === 'percentage',
          },
          itemIds: item?.ids || [],
        });
      } else {
        await addAdjustmentsToOrderItems({
          adjustments: [
            {
              id: uuid(),
              amount: discountAmount,
              description: data.description,
              isPercentage: data.type === 'percentage',
            },
          ],
          itemIds: item?.ids || [],
        });
      }

      closeManageOrderItemAdjustmentDialog();
      setIsSubmitting(false);
    } catch (error) {
      // TODO: Do something with error.
      setIsSubmitting(false);
      return Promise.reject(error);
    }
  };

  return (
    <FormDialog
      {...otherProps}
      formProps={{
        ...(formProps || {}),
        defaultValues,
        isSubmitting,
        submitLabel: intl.formatMessage(
          translations[submitLabelTranslationKey]
        ),
        validationSchema,
      }}
      onSubmit={handleSubmit}
      open={open}
      title={intl.formatMessage(translations[titleTranslationKey])}
      TransitionProps={{ onExited }}
    >
      <DiscountFormSection currency={order?.currency} />
      {adjustment && (
        <AdjustmentActionsFormSection onRemove={handleRemoveAdjustment} />
      )}
    </FormDialog>
  );
};
