import { SvgIcon, type SvgIconProps } from '@afosto/components';

export const YourGift = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 30 20">
    <rect width="28" height="20" fill="#005CAE" rx="4" />
    <path fill="#fff" d="M19.31 5.31 7 7.17l1.33 8.02 12.32-1.85L19.3 5.3Z" />
    <path
      fill="#005CAE"
      fillRule="evenodd"
      d="m9.94 8.86.41-1.13.77-.11-.72 1.86.15.94-.73.11-.15-.94-1.28-1.56.8-.12.75.95Zm3.65.1c.1.6-.25 1.09-.87 1.18-.62.1-1.12-.26-1.22-.86-.1-.6.26-1.08.88-1.17a1 1 0 0 1 1.21.86Zm-.7.11c-.04-.27-.2-.44-.42-.4-.22.03-.3.24-.26.5.04.27.2.44.41.4.22-.03.32-.23.27-.5Zm2.78-1.41.32 1.94-.7.1-.04-.16a.74.74 0 0 1-.5.3c-.46.06-.77-.18-.84-.61l-.21-1.28.7-.1.19 1.11c.03.17.13.27.3.24a.3.3 0 0 0 .26-.26l-.2-1.18.72-.1Zm1.75-.28.1.66a.65.65 0 0 0-.3-.02c-.2.03-.3.13-.32.27l.19 1.15-.72.1-.32-1.94.71-.1.03.16a.56.56 0 0 1 .43-.28.43.43 0 0 1 .2 0Zm-4.43 4.92-.43.06-.09-.52 1.16-.17.04.26c.12.74-.3 1.28-1.05 1.39-.8.12-1.47-.36-1.6-1.16a1.3 1.3 0 0 1 1.14-1.58c.71-.1 1.2.22 1.39.75l-.75.1c-.1-.18-.28-.28-.54-.24-.4.06-.58.41-.51.85.07.46.37.73.77.67.28-.04.44-.2.47-.41Zm1.55-1.31.32 1.94-.7.11-.33-1.94.71-.11Zm-.04-.53c.04.22-.12.39-.34.42-.22.03-.43-.08-.46-.3-.04-.23.12-.4.34-.43.22-.03.43.08.46.3Zm1.58-.58.1.56a.36.36 0 0 0-.18-.02c-.16.03-.24.12-.21.28l.01.1.4-.06.08.53-.39.06.24 1.41-.7.11-.24-1.42-.3.05-.1-.53.3-.04-.01-.15c-.08-.46.16-.8.66-.88.14-.02.25-.01.34 0Zm.74 2.07-.12-.74-.3.04-.09-.53.3-.04-.09-.54.7-.1.1.53.42-.06.09.52-.43.07.1.65c.03.15.12.2.26.18a.64.64 0 0 0 .22-.08l.1.58c-.08.04-.23.1-.39.12-.48.07-.79-.1-.87-.6Z"
      clipRule="evenodd"
    />
  </SvgIcon>
);
