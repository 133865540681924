import {
  graphQLClient,
  gql,
  type GraphQLRequestOptions,
} from '@afosto/graphql-client';
import { queryOptions } from '@tanstack/react-query';
import type { PaymentMethod } from '../types';

export interface GetOrderPaymentMethodsResponse {
  order: {
    id: string;
    options: {
      payment: {
        methods: PaymentMethod[];
      };
    };
  };
}

export const GET_ORDER_PAYMENT_METHODS_QUERY_KEY = 'getOrderPaymentMethods';

const fetchOrderPaymentMethods = async ({
  queryKey,
}: {
  queryKey: [
    string,
    string,
    GraphQLRequestOptions<GetOrderPaymentMethodsResponse>
  ];
}) => {
  const [, id, requestOptions = {}] = queryKey;
  const graphQLQuery = gql`
    query getOrderPaymentMethods($id: String!) {
      order(id: $id) {
        id
        options {
          payment {
            methods {
              id
              code
              description
              instruction
              is_manual
              name
              pricing {
                fixed
                percentage
              }
              issuers {
                id
                label
              }
            }
          }
        }
      }
    }
  `;

  return graphQLClient.request<GetOrderPaymentMethodsResponse>(
    graphQLQuery,
    { id },
    requestOptions
  );
};

export const getOrderPaymentMethods = (
  id: string,
  requestOptions = {} as GraphQLRequestOptions<GetOrderPaymentMethodsResponse>
) => {
  return queryOptions({
    queryKey: [GET_ORDER_PAYMENT_METHODS_QUERY_KEY, id, requestOptions],
    queryFn: fetchOrderPaymentMethods,
    select: (response) => response?.order?.options?.payment?.methods || [],
  });
};
