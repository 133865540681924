import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from '@afosto/components';
import { ActionsList } from '../ActionsList';
import { CardSelector } from '../CardSelector';
import { Fieldset } from '../Fieldset';
import { translations } from './translations';
import type { CouponsFormSectionProps } from './types';

export const CouponsFormSection = (props: CouponsFormSectionProps) => {
  const { coupons = [], onRemove } = props;

  const intl = useIntl();

  if (!coupons.length) {
    return null;
  }

  return (
    <Fieldset title={intl.formatMessage(translations.coupons)}>
      <ActionsList>
        {coupons.map((coupon) => (
          <CardSelector key={coupon.id} label={coupon.code}>
            <Button
              color="error"
              onClick={onRemove(coupon)}
              size="large"
              variant="text"
              sx={{ ml: 'auto', my: -0.5, mr: -0.5 }}
            >
              <FormattedMessage {...translations.remove} />
            </Button>
          </CardSelector>
        ))}
      </ActionsList>
    </Fieldset>
  );
};
