import { Box, ScrollContainer, Typography } from '@afosto/components';
import { Loader } from '../Loader';
import {
  SearchResultsList,
  type SearchResultsListItem,
} from '../SearchResultsList';
import { FormattedMessage } from 'react-intl';
import { translations } from '../Search/translations';
import type { SearchResultsProps } from './types';

export const SearchResults = <T extends SearchResultsListItem>(
  props: SearchResultsProps<T>
) => {
  const {
    isSearching,
    onSelectSearchResult,
    searchResults,
    SearchResultsListItemComponent,
    searchQuery,
    searchQueryRequired,
  } = props;

  return (
    <ScrollContainer>
      {isSearching && <Loader sx={{ mt: 4 }} />}
      {!isSearching && (
        <Box sx={{ display: 'flex', flexDirection: 'column', px: 2.5 }}>
          {(!searchQueryRequired ||
            (searchQueryRequired &&
              searchQuery &&
              searchQuery?.length > 0)) && (
            <SearchResultsList
              items={searchResults}
              onSelectSearchResult={onSelectSearchResult}
              SearchResultsListItemComponent={SearchResultsListItemComponent}
            />
          )}
          {searchQueryRequired && !searchQuery?.length && (
            <Typography variant="bodyLarge" mt={4} sx={{ alignSelf: 'center' }}>
              <FormattedMessage {...translations.startASearch} />
            </Typography>
          )}
        </Box>
      )}
    </ScrollContainer>
  );
};
