import {
  graphQLClient,
  gql,
  type GraphQLRequestOptions,
} from '@afosto/graphql-client';
import { CoreOrderFragment } from '../fragments';
import type { Order } from '../types';

export interface RemoveItemsFromOrderResponse {
  removeItemsFromOrder: {
    order: Order;
  };
}

export interface RemoveItemsFromOrderInput {
  orderId: string;
  ids: string[];
  requestOptions?: GraphQLRequestOptions<RemoveItemsFromOrderResponse>;
}

export const removeItemsFromOrder = async ({
  orderId,
  ids,
  requestOptions = {},
}: RemoveItemsFromOrderInput) => {
  const mutation = gql`
    ${CoreOrderFragment}
    mutation RemoveItemsFromOrder($input: RemoveItemsFromOrderInput!) {
      removeItemsFromOrder(input: $input) {
        order {
          ...CoreOrderFragment
        }
      }
    }
  `;

  return graphQLClient.request<RemoveItemsFromOrderResponse>(
    mutation,
    {
      input: {
        orderId,
        ids,
      },
    },
    requestOptions
  );
};
