import { clsx } from 'clsx';
import { Card, Typography } from '@afosto/components';
import * as Styled from './ShortcutCard.styles';
import type { ShortcutCardProps } from './types';

export const ShortcutCard = (props: ShortcutCardProps) => {
  const {
    color = 'default',
    disabled = false,
    Icon,
    label,
    onClick,
    variant = 'filled',
  } = props;

  return (
    <Card>
      <Styled.ActionArea
        className={clsx({
          [`color-${color}`]: color,
          [`variant-${variant}`]: variant,
        })}
        disabled={disabled}
        onClick={onClick}
        sx={{ height: '100%' }}
      >
        {Icon && <Icon sx={{ fontSize: 24 }} />}
        <Typography variant="bodyLarge" fontWeight={500}>
          {label}
        </Typography>
      </Styled.ActionArea>
    </Card>
  );
};
