import { FormattedMessage, useIntl } from 'react-intl';
import { useOrder } from '../OrderProvider/hooks/useOrder';
import { Currency } from '../Currency';
import { ReceiptSummaryRow } from '../ReceiptSummaryRow';
import * as Styled from './ReceiptSummary.styles';
import { translations } from './translations';

export const ReceiptSummary = () => {
  const intl = useIntl();
  const { openCheckoutDialog, order } = useOrder();
  const { payment: paymentFees = [], shipping: shippingFees = [] } =
    order?.fees || {};
  const hasItems = (order?.items || []).length > 0;
  const totalItemQuantity = (order?.items || []).reduce((acc, item) => {
    return acc + item.quantity;
  }, 0);

  return (
    <Styled.Container>
      <Styled.Summary>
        <ReceiptSummaryRow
          currency={order?.currency}
          description={intl.formatMessage(translations.numberOfItems, {
            count: totalItemQuantity,
          })}
          label={intl.formatMessage(translations.subtotal)}
          total={order?.subtotal}
        />
        {[...shippingFees, ...paymentFees]
          .filter((fee) => fee.total !== 0)
          .map((fee) => (
            <ReceiptSummaryRow
              key={fee.description}
              currency={order?.currency}
              label={fee.description}
              total={fee.total}
            />
          ))}
        {(order?.adjustments || []).map((adjustment) => (
          <ReceiptSummaryRow
            key={adjustment.description}
            currency={order?.currency}
            description={
              adjustment.isPercentage ? `${adjustment.amount}%` : undefined
            }
            label={adjustment.description}
            total={adjustment.outcome?.amount}
          />
        ))}
        {(order?.vat || [])
          .filter((vat) => vat.rate !== 0 && vat.amount !== 0)
          .map((vat) => (
            <ReceiptSummaryRow
              key={vat.rate}
              currency={order?.currency}
              description={`${vat.rate}%`}
              label={intl.formatMessage(
                translations[order?.isVatShifted ? 'vatShifted' : 'vat']
              )}
              total={vat?.amount}
            />
          ))}
      </Styled.Summary>
      <Styled.CheckoutButton
        disabled={!hasItems}
        onClick={openCheckoutDialog}
        size="extraLarge"
        fullWidth
      >
        <FormattedMessage {...translations.checkout} />
        <span>
          <Currency currency={order?.currency} value={order?.total} />
        </span>
      </Styled.CheckoutButton>
    </Styled.Container>
  );
};
