import { SvgIcon, type SvgIconProps } from '@afosto/components';

export const Multibanco = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 30 20">
    <rect width="28" height="20" fill="#246BB4" rx="4" />
    <path
      fill="#fff"
      d="M19.17 6a.5.5 0 0 1-.5-.45l-.04-.43a.5.5 0 0 0-.5-.45H9.87a.5.5 0 0 0-.5.45l-.04.43a.5.5 0 0 1-1-.1l.05-.42a1.5 1.5 0 0 1 1.49-1.36h8.26c.78 0 1.42.58 1.5 1.36l.03.42a.5.5 0 0 1-.5.55Zm-.07 10.33H8.9a1.5 1.5 0 0 1-1.5-1.59l.02-.27a.5.5 0 1 1 1 .06l-.02.27c-.01.14.04.27.13.38.1.1.23.15.37.15h10.2a.5.5 0 0 0 .37-.15.5.5 0 0 0 .13-.38l-.02-.27a.5.5 0 0 1 1-.06l.02.27a1.5 1.5 0 0 1-1.5 1.6ZM14.17 13a.5.5 0 0 1-.5-.44l-.46-4.02-1.58 3.82a.5.5 0 0 1-.93 0L9.13 8.54l-.46 4.02a.5.5 0 1 1-.99-.12l.53-4.7a.82.82 0 0 1 1.57-.23l1.4 3.35 1.39-3.35a.82.82 0 0 1 1.57.23l.53 4.7a.5.5 0 0 1-.44.56h-.05Zm5.31-3.4a1.63 1.63 0 0 0-1.36-2.52h-1.95a.5.5 0 0 0-.5.5v4.84c0 .27.22.5.5.5h2.37a1.8 1.8 0 0 0 .94-3.32Zm-.94 2.32h-1.87V8.08h1.45a.63.63 0 0 1 0 1.25h-.29a.5.5 0 1 0 0 1h.71a.8.8 0 0 1 0 1.59Z"
    />
  </SvgIcon>
);
