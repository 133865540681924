import { camelize } from '@afosto/utils';
import { queryOptions } from '@tanstack/react-query';
import { fetchTable, GET_TABLE_QUERY_KEY } from './getTable';
import { fetchQueryViewData, QUERY_VIEW_DATA_QUERY_KEY } from './queryViewData';
import type { Channel } from '../types';

export interface SearchOrderResult {
  id: string;
  [key: string]: unknown;
}

export interface SearchOrderQuery {
  channel?: Channel;
  itemsPerPage?: number;
  type?: string;
}

export const SEARCH_ORDER_QUERY_KEY = 'searchOrder';

export const fetchSearchOrder = async ({
  queryKey,
}: {
  queryKey: [string, string, SearchOrderQuery, object];
}): Promise<SearchOrderResult[]> => {
  const [, q, query = {}] = queryKey || [];
  const tableResponse = await fetchTable({
    queryKey: [GET_TABLE_QUERY_KEY, 'ORDERS', {}],
  });
  const { views = [] } = tableResponse?.table || {};
  const [firstView] = views;
  const defaultView = views.find(({ isDefault }) => isDefault);
  const viewId = defaultView?.id || firstView?.id;

  const response = await fetchQueryViewData({
    queryKey: [
      QUERY_VIEW_DATA_QUERY_KEY,
      viewId,
      {
        ...(q?.trim()
          ? {
              filters: [
                {
                  key: 'number',
                  operator: 'Q',
                  values: [q],
                },
                {
                  key: 'contact',
                  operator: 'Q',
                  values: [q],
                },
                {
                  key: 'organisation',
                  operator: 'Q',
                  values: [q],
                },
                {
                  key: 'notes',
                  operator: 'Q',
                  values: [q],
                },
              ],
            }
          : {}),
        fixedFilters: [
          ...(query?.channel?.id
            ? [
                {
                  key: 'channel_id',
                  operator: 'IN',
                  values: [query.channel.id],
                },
              ]
            : []),
          ...(query?.type
            ? [
                {
                  key: 'state',
                  operator: 'IN',
                  values: [query.type.toUpperCase()],
                },
              ]
            : []),
        ],
        itemsPerPage: query?.itemsPerPage || 10,
        ...(!q
          ? {
              sorting: [
                {
                  key: 'number',
                  direction: 'desc',
                },
              ],
            }
          : {}),
      },
      {},
    ],
  });

  return (response?.data || []).map((data) => ({
    ...Object.keys(data?.columns || {}).reduce(
      (acc, key) => ({
        ...acc,
        [camelize(key)]: data.columns[key],
      }),
      {}
    ),
    id: data.entity?.id,
  }));
};

export const searchOrder = (
  q: string,
  query: SearchOrderQuery = {},
  requestOptions = {}
) => {
  return queryOptions({
    queryKey: [SEARCH_ORDER_QUERY_KEY, q, query, requestOptions],
    queryFn: fetchSearchOrder,
  });
};
