import { useIntl } from 'react-intl';
import { ActionsList } from '../../../ActionsList';
import { CommentAltDots, HourGlassEmpty, Print } from '../../../../icons/solid';
import { CardSelector } from '../../../CardSelector';
import { useOrder } from '../../../OrderProvider/hooks/useOrder';
import { translations } from './translations';

export const OrderActionsSection = () => {
  const intl = useIntl();

  const {
    closeOrderActionsDialog,
    openOrderNoteDialog,
    order,
    parkOrder,
    printOrder,
  } = useOrder();
  const hasNote = (order?.customer?.notes || '').length > 0;

  const handleParkReceipt = () => {
    parkOrder();
    closeOrderActionsDialog();
  };

  const handlePrintOrder = () => {
    printOrder().catch(() => {
      // TODO: Do something with error.
    });
  };

  return (
    <ActionsList>
      <CardSelector
        Icon={Print}
        label={intl.formatMessage(translations.printReceipt)}
        onSelect={handlePrintOrder}
      />
      <CardSelector
        Icon={HourGlassEmpty}
        label={intl.formatMessage(translations.parkReceipt)}
        onSelect={handleParkReceipt}
      />
      <CardSelector
        Icon={CommentAltDots}
        label={intl.formatMessage(
          translations[hasNote ? 'editNote' : 'addNote']
        )}
        onSelect={openOrderNoteDialog}
      />
    </ActionsList>
  );
};
