import { addQueryParamsToUrl } from '@afosto/utils';

export const convertImageUrl = (
  url?: string,
  options: { size?: number } = {}
) => {
  const { size = 40 } = options;

  if (url?.startsWith('https://cdn.quicq.io')) {
    return addQueryParamsToUrl(url, {
      h: size,
      w: size,
    });
  }

  return url;
};
