import { useIntl } from 'react-intl';
import { FullscreenDialog } from '../FullscreenDialog';
import { PrintersList } from '../PrintersList';
import { usePrint } from '../PrintProvider/hooks/usePrint';
import { translations } from './translations';
import type { SelectPrinterDialogProps } from './types';

export const SelectPrinterDialog = (props: SelectPrinterDialogProps) => {
  const { appear = true, onSelectPrinter, ...otherProps } = props;

  const intl = useIntl();
  const { printerOptions } = usePrint();

  return (
    <FullscreenDialog
      {...otherProps}
      title={intl.formatMessage(translations.title)}
      description={intl.formatMessage(translations.description)}
      TransitionProps={{ appear }}
    >
      <PrintersList
        printers={printerOptions}
        onSelectPrinter={onSelectPrinter}
      />
    </FullscreenDialog>
  );
};
