import { QueryClient } from '@tanstack/react-query'

export const setupQueryClient = () => {
  return new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: (failureCount: number, error: Error & { response?: { status: number } }) => {
          const statusCode = error?.response?.status;

          if (statusCode && [404, 403].includes(statusCode)) {
            return false;
          }

          return failureCount < 1;
        },
        retryDelay: (attemptIndex: number, error: Error & { response?: { headers: { [key: string]: string }, status: number } }) => {
          const statusCode = error?.response?.status;

          if (statusCode === 429) {
            const rateLimitResetHeader = error?.response?.headers['x-ratelimit-reset'];
            const rateLimit =  rateLimitResetHeader ? parseInt(rateLimitResetHeader, 10) : undefined;

            if (rateLimit && !Number.isNaN(rateLimit)) {
              return (rateLimit + 1) * 1000;
            }
          }

          return Math.min(1000 * 2 ** attemptIndex, 30000);
        },
      },
    },
  });
};
