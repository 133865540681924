export const formatActiveSortToUrlParams = (
  activeSort: { direction: 'asc' | 'desc'; key: string }[]
) =>
  (activeSort || [])
    .reduce(
      (acc, { direction, key }) => [
        ...acc,
        direction === 'asc' ? `${key}` : `-${key}`,
      ],
      [] as string[]
    )
    .join(',');
