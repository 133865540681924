import { defineMessages } from 'react-intl';

export const translations = defineMessages({
  fetchingCategories: {
    id: 'feedback.fetchingCategories.message',
    defaultMessage: 'Fetching categories',
    description: 'Feedback message for fetching categories',
  },
  title: {
    id: 'components.selectCategoryDialog.title',
    defaultMessage: 'Select a category',
    description: 'Title of the select category dialog',
  },
});
