import { useIntl } from 'react-intl';
import { ActionsList } from '../ActionsList';
import { CardSelector } from '../CardSelector';
import { Currency } from '../Currency';
import { Fieldset } from '../Fieldset';
import { translations } from './translations';
import type { AdjustmentsFormSectionProps } from './types';

export const AdjustmentsFormSection = (props: AdjustmentsFormSectionProps) => {
  const { adjustments = [], currency, onEdit } = props;

  const intl = useIntl();

  if (!adjustments.length) {
    return null;
  }

  return (
    <Fieldset title={intl.formatMessage(translations.discounts)}>
      <ActionsList>
        {adjustments.map((adjustment) => (
          <CardSelector
            key={adjustment.description}
            onSelect={
              onEdit && !adjustment.outcome?.ruleId
                ? onEdit(adjustment)
                : undefined
            }
            label={`${adjustment.description} ${
              adjustment.isPercentage ? ` (${adjustment.amount}%)` : ''
            }`}
            description={
              <Currency
                currency={currency}
                value={
                  adjustment.isDiscount
                    ? adjustment.outcome?.amount * -1
                    : adjustment.outcome?.amount
                }
              />
            }
          />
        ))}
      </ActionsList>
    </Fieldset>
  );
};
