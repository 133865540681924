import { SvgIcon, type SvgIconProps } from '@afosto/components';

export const GivaCard = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 30 20">
    <rect width="28" height="20" fill="#095686" rx="4" />
    <path
      fill="#fff"
      d="M2.85 15.89A1.68 1.68 0 0 1 2 14.4a1.67 1.67 0 0 1 .85-1.47 1.67 1.67 0 0 1 1.06-.22.09.09 0 0 1 .08.11l-.07.42c-.01.05-.04.07-.1.07H3.7c-.2 0-.38.04-.55.14a1.1 1.1 0 0 0 .55 2.05c.23 0 .43-.07.6-.2.17-.13.3-.3.38-.51h-.6a.1.1 0 0 1-.07-.03.09.09 0 0 1-.03-.07v-.34a.1.1 0 0 1 .03-.07.09.09 0 0 1 .07-.02h1c.07 0 .13 0 .17.03.04.03.06.06.06.11 0 .31-.07.6-.2.86-.14.26-.33.46-.58.61-.25.16-.52.23-.83.23-.3 0-.6-.07-.85-.22Zm3.17.14a.08.08 0 0 1-.03-.06v-3.11c0-.03 0-.05.03-.07a.09.09 0 0 1 .06-.02h.49c.02 0 .04 0 .05.02a.1.1 0 0 1 .03.07v3.11c0 .02 0 .04-.02.06a.08.08 0 0 1-.06.02h-.5c-.02 0-.04 0-.05-.02Zm3.96-3.2v.04l-.86 3.12c0 .03-.02.04-.03.05a.17.17 0 0 1-.07.01h-.65c-.02-.02-.03-.03-.03-.06l-.85-3.12v-.04c0-.04.01-.06.05-.06h.43a.2.2 0 0 1 .08 0c.01.02.03.03.03.06a215.2 215.2 0 0 0 .65 2.34l.04-.09a112.56 112.56 0 0 1 .65-2.3l.07-.01h.43c.04 0 .06.02.06.06Z"
    />
    <path
      fill="#ED6965"
      d="M12.35 16.03a.1.1 0 0 1-.04-.05l-.23-.87a.14.14 0 0 0-.06-.07.13.13 0 0 0-.08-.03h-.7c-.03 0-.04 0-.06-.02a.07.07 0 0 1-.02-.05v-.36c0-.02 0-.03.02-.05a.07.07 0 0 1 .06-.02h.56a.1.1 0 0 0 .07-.03c.01-.02.02-.05 0-.08l-.25-.95a.12.12 0 0 0-.05-.07.13.13 0 0 0-.08-.03h-.03c-.03 0-.05.01-.08.03a.12.12 0 0 0-.05.07l-.17.63-.03.04a.08.08 0 0 1-.06 0l-.44-.1a.06.06 0 0 1-.05-.04.06.06 0 0 1 0-.05l.3-1.09c0-.02.01-.03.03-.05a.1.1 0 0 1 .06-.02h1c.03 0 .05 0 .07.02a.1.1 0 0 1 .04.05l.86 3.15c0 .04-.02.06-.05.06h-.48a.1.1 0 0 1-.06-.02Z"
    />
    <path
      fill="#fff"
      d="M14.13 15.87a1.66 1.66 0 0 1-.83-1.46c0-.3.08-.58.23-.84a1.68 1.68 0 0 1 1.43-.84 1.6 1.6 0 0 1 1.22.54c.02.02.02.03.02.05 0 .02 0 .04-.02.05l-.34.3a.07.07 0 0 1-.05.02c-.02 0-.03-.01-.05-.03a1.07 1.07 0 0 0-.78-.35 1.1 1.1 0 0 0-1.07 1.1 1.09 1.09 0 0 0 1.07 1.1 1.04 1.04 0 0 0 .75-.32.13.13 0 0 1 .09-.03c.03 0 .05 0 .07.02l.08.07.2.17a.1.1 0 0 1 .04.1c0 .01 0 .03-.02.05a1.62 1.62 0 0 1-1.2.52 1.6 1.6 0 0 1-.84-.22Zm2.7.17a.05.05 0 0 1 0-.04l.83-3.16c0-.02.01-.03.03-.05a.09.09 0 0 1 .06-.02h.97a.1.1 0 0 1 .06.02.1.1 0 0 1 .04.05l.83 3.15v.02c0 .03-.02.05-.05.05h-.46a.1.1 0 0 1-.06-.02.08.08 0 0 1-.03-.05l-.23-.87a.12.12 0 0 0-.05-.07.13.13 0 0 0-.08-.03h-.9c-.04 0-.06 0-.09.03a.12.12 0 0 0-.04.07l-.23.86c0 .03-.02.04-.04.06a.1.1 0 0 1-.06.02h-.46c-.01 0-.03 0-.04-.02Zm1.72-1.52c.03 0 .05-.01.07-.04a.09.09 0 0 0 .01-.07l-.26-.97a.1.1 0 0 0-.04-.06.13.13 0 0 0-.08-.03h-.03c-.03 0-.05 0-.08.03a.1.1 0 0 0-.04.06l-.26.97v.02c0 .03 0 .05.02.06a.1.1 0 0 0 .07.03h.62Zm1.78 1.51a.08.08 0 0 1-.03-.06v-3.11c0-.03.01-.05.03-.06a.09.09 0 0 1 .06-.03h.99a1.07 1.07 0 0 1 .92.52 1 1 0 0 1 .03.98c-.07.14-.17.26-.3.36a.08.08 0 0 0-.03.06v.03l.01.01.54 1.22.02.06c0 .03-.02.05-.06.05h-.41l-.08-.01c-.02 0-.03-.02-.05-.05l-.49-1.1a.06.06 0 0 0-.03-.03.38.38 0 0 0-.1-.02h-.37l-.1.02-.02.07v1.03c0 .03-.01.05-.03.06a.09.09 0 0 1-.06.03h-.38l-.06-.03Zm1.05-1.78c.12 0 .22-.04.31-.13a.42.42 0 0 0 .13-.3.41.41 0 0 0-.13-.32.44.44 0 0 0-.31-.12h-.44c-.05 0-.08.03-.08.09v.7c0 .03 0 .04.02.06l.05.02h.45Zm2 1.75a.13.13 0 0 1-.04-.1v-3.01a.1.1 0 0 1 .03-.07.1.1 0 0 1 .07-.03h.94A1.61 1.61 0 0 1 26 14.42a1.63 1.63 0 0 1-1.62 1.62h-.9a.13.13 0 0 1-.1-.05Zm1-.57a1.01 1.01 0 0 0 .88-1.53 1.04 1.04 0 0 0-.88-.5h-.42a.05.05 0 0 0-.01.05v1.98h.43Z"
    />
    <path
      fill="#ED6965"
      d="M15.4 9.1a.13.13 0 0 1-.06-.06l-.4-1.15a.2.2 0 0 0-.19-.11l-.95.07a.1.1 0 0 1-.07-.02.09.09 0 0 1-.04-.07l-.03-.48c0-.02 0-.05.02-.07a.09.09 0 0 1 .07-.04l.75-.06c.04 0 .07-.01.1-.04v-.1l-.45-1.25a.16.16 0 0 0-.07-.09.17.17 0 0 0-.11-.03h-.04a.17.17 0 0 0-.1.05.16.16 0 0 0-.06.1l-.16.85a.1.1 0 0 1-.05.06.1.1 0 0 1-.08.02l-.6-.1a.08.08 0 0 1-.06-.04.09.09 0 0 1-.02-.08l.29-1.48.04-.07a.13.13 0 0 1 .08-.04l1.35-.1c.02 0 .05 0 .08.02.03.01.06.03.07.06l1.48 4.13v.01c0 .05-.02.08-.07.08l-.64.05c-.03 0-.06 0-.08-.02Z"
    />
    <path
      fill="#fff"
      d="m19.58 9.07-.39-5A1.16 1.16 0 0 0 17.94 3l-8.06.63c-.64.05-1.12.61-1.07 1.26l.39 5a1.17 1.17 0 0 0 1.25 1.07l8.06-.63a1.17 1.17 0 0 0 1.07-1.26Zm-1.11.73-8.06.63a.64.64 0 0 1-.68-.59l-.4-5a.64.64 0 0 1 .13-.42l.15 1.94h.06l.14-.02-.16-1.98v-.11a.65.65 0 0 1 .26-.09l.01.18.12 1.51.52-.04-.12-1.51-.02-.18.26-.02.02.18.21 2.75.46-.04-.21-2.75-.01-.18.2-.01v.18l.14 1.72h.07l-.14-1.73-.01-.18.2-.01v.18l.16 2 .27-.01-.16-2.02-.01-.17.37-.03v.18l.17 2h.1l.24-1.24a.46.46 0 0 1 .08-.17l-.04-.63-.02-.18.33-.03.01.18.04.47a.4.4 0 0 1 .03 0l.17-.02-.04-.47-.01-.17.26-.03.02.18.03.47.52-.04L14.1 4v-.18l.32-.02.01.18.04.46h.16l-.03-.48-.02-.17.28-.03.02.18.05.67.07.11.01.02.42 1.18h.1l-.16-2.02-.02-.17.17-.02v.18l.14 1.73.16-.01-.13-1.73-.02-.18.22-.02.01.18.13 1.62h.07l-.13-1.62-.01-.18.21-.02.01.18.16 2.01.34-.02-.16-2.02-.01-.17.27-.03v.18l.15 1.8H17l-.14-1.8-.02-.18.32-.03.01.18.14 1.73h.16l-.13-1.74-.02-.18.32-.02.01.17.22 2.76.54-.05-.21-2.75-.01-.14a.64.64 0 0 1 .28.16l.12 1.61h.07l-.12-1.53c.07.1.12.21.13.33l.38 5a.64.64 0 0 1-.58.68Z"
    />
  </SvgIcon>
);
