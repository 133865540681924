import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuthentication } from '@afosto/auth-react';
import { BottomNavigation } from '../BottomNavigation';
import { TopBar } from '../TopBar';
import * as Styled from './Layout.styles';

export const Layout = () => {
  const { isAuthenticated, routeMapping } = useAuthentication();
  const location = useLocation();

  if (!isAuthenticated) {
    return <Navigate to={routeMapping.signIn} state={{ referrer: location }} />;
  }

  return (
    <Styled.Container>
      <TopBar />
      <Outlet />
      <BottomNavigation />
    </Styled.Container>
  );
};
