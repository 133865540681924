import { ActionsList } from '../ActionsList';
import { CardSelector } from '../CardSelector';
import type { ChannelsListProps } from './types';
import type { Channel } from '../../types';

export const ChannelsList = (props: ChannelsListProps) => {
  const { channels, onSelectChannel } = props;

  const handleSelectChannel = (channel: Channel) => () => {
    onSelectChannel(channel);
  };

  return (
    <ActionsList>
      {channels.map((channel) => (
        <CardSelector
          key={channel.id}
          label={channel.name}
          onSelect={handleSelectChannel(channel)}
        />
      ))}
    </ActionsList>
  );
};
