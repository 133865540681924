import { SvgIcon, type SvgIconProps } from '@afosto/components';

export const CashOnDelivery = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 30 20">
    <rect
      width="27"
      height="19"
      x=".5"
      y=".5"
      fill="#fff"
      stroke="#DBE2EA"
      rx="3.5"
    />
    <path
      fill="#4B6373"
      d="M9.53 10h8.89c.49 0 .89-.4.89-.88V3.88c0-.49-.4-.88-.9-.88h-2.63v3.5L14 5.62l-1.8.88V3H9.53c-.46 0-.86.4-.86.88v5.25c0 .48.4.87.86.87Zm12.25 2.2a1.12 1.12 0 0 0-1.55-.24l-3.33 2.41h-3.35a.42.42 0 0 1-.41-.4c0-.22.2-.42.41-.42h2.18c.42 0 .85-.3.93-.73a.9.9 0 0 0-.88-1.02H11.3c-.73 0-1.48.25-2.06.72l-1.3 1.03-1.53-.07c-.24 0-.42.2-.42.43v2.65c0 .24.2.44.42.44h9.61a3 3 0 0 0 1.72-.55l3.75-2.73c.54-.35.65-1.04.28-1.52Z"
    />
  </SvgIcon>
);
