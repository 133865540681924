import { defineMessages } from 'react-intl';

export const translations = defineMessages({
  contacts: {
    id: 'common.contacts',
    defaultMessage: 'Contacts',
    description: 'Common label for contacts',
  },
  homePage: {
    id: 'common.homePage',
    defaultMessage: 'Home page',
    description: 'Common label for home page',
  },
  organisations: {
    id: 'common.organisations',
    defaultMessage: 'Organisations',
    description: 'Common label for organisations',
  },
  orders: {
    id: 'common.orders',
    defaultMessage: 'Orders',
    description: 'Common label for orders',
  },
  products: {
    id: 'common.products',
    defaultMessage: 'Products',
    description: 'Common label for products',
  },
  settings: {
    id: 'common.settings',
    defaultMessage: 'Settings',
    description: 'Common label for settings',
  },
});
