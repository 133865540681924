import { ref, string, addMethod } from 'yup';
import { type CountryCode, parsePhoneNumber } from 'libphonenumber-js';
import { isDefined } from '@afosto/utils';
import type { CountryOption } from '@afosto/components';

export const setupCustomValidation = () => {
  addMethod(string, 'phoneNumber', function (name = 'phoneNumberCountry') {
    return this.test({
      name: 'phoneNumber',
      message: { key: 'phoneNumber' },
      test: (value = '', context) => {
        if (!isDefined(value)) {
          return true;
        }

        const countryValue: CountryOption | string = context.resolve(ref(name));
        let countryCode = '';

        if (
          typeof countryValue !== 'string' &&
          'alpha2Code' in countryValue &&
          countryValue?.alpha2Code
        ) {
          countryCode = countryValue.alpha2Code;
        } else if (typeof countryValue === 'string') {
          countryCode = countryValue;
        }

        try {
          const phoneNumber =
            parsePhoneNumber(value, countryCode as CountryCode) ?? {};
          return phoneNumber.isValid() ?? false;
        } catch {
          return false;
        }
      },
    });
  });
};
