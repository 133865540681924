import { SvgIcon, type SvgIconProps } from '@afosto/components';

export const Payconiq = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 30 20">
    <rect width="28" height="20" fill="#F64885" rx="4" />
    <path
      fill="#fff"
      d="M9.47 8.92h.59v.1l.05-.02a1.05 1.05 0 0 1 1.28 1.53c-.27.42-.8.59-1.33.38v.56h-.6V8.92Zm1.05 1.48a.45.45 0 1 0 0-.9.44.44 0 0 0-.45.45c0 .25.19.45.46.45Zm2.67-1.42.06.03v-.09h.6V11h-.6v-.09l-.06.03a1.05 1.05 0 0 1-1.24-1.56c.13-.2.38-.4.65-.45.29-.05.59.06.59.06Zm.06.99c0-.27-.2-.47-.44-.47-.34 0-.45.32-.45.45a.45.45 0 0 0 .43.45c.23 0 .46-.17.46-.43Zm7.18 0c.01.54-.41 1.04-1.06 1.03-.6 0-1.05-.45-1.05-1.05 0-.59.44-1.04 1.07-1.04.58 0 1.04.47 1.04 1.06Zm-1.06-.46a.44.44 0 0 0-.43.46c0 .26.21.44.46.43.24 0 .44-.21.44-.45-.01-.23-.2-.46-.47-.44ZM21.3 11h-.6V8.92h.6V9l.03-.01a.97.97 0 0 1 1.32.87v1.12h-.6V9.94c0-.14-.02-.22-.1-.3a.4.4 0 0 0-.27-.11.4.4 0 0 0-.27.09c-.1.07-.11.2-.12.24v1.13Zm-5.67-2.07h.6l-1 2.54h-.62l.29-.72-.83-1.82h.65l.47 1.1.44-1.1Zm2.03 1.27.5.34c-.28.36-.68.55-1.15.42a1.05 1.05 0 1 1 1.15-1.58l-.5.33a.47.47 0 0 0-.42-.2.46.46 0 0 0-.3.16.4.4 0 0 0-.1.24.45.45 0 0 0 .13.36c.11.12.26.16.42.12.08-.02.14-.04.27-.18Zm5.37-1.28h.6V11h-.6V8.92Zm2.97 0h-.6v.1L25.37 9a1.05 1.05 0 0 0-1.29 1.53c.28.42.8.59 1.34.38v.56H26V8.92Zm-1.06 1.48a.45.45 0 1 1 0-.9c.26 0 .45.2.46.45 0 .25-.2.45-.46.45ZM23.34 8a.35.35 0 1 1 0 .7.35.35 0 0 1 0-.7ZM2 9.85a1.34 1.34 0 0 1 2.49-.59c.1.16.15.32.2.49l1.23-.87a1.34 1.34 0 1 1 .73 2.42 1.3 1.3 0 0 1-1.24-.96l-.05-.17-1.25.9a1.34 1.34 0 0 1-1.95-.49A1.34 1.34 0 0 1 2 9.85Zm5.31.1c0-.2-.18-.58-.6-.58-.33 0-.63.25-.62.58a.61.61 0 1 0 1.22 0Zm-3.35 0a.61.61 0 0 0-1.22 0c0 .34.26.61.6.62.16 0 .63-.13.62-.61Z"
    />
  </SvgIcon>
);
