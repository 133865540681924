import { defineMessages } from 'react-intl';

export const translations = defineMessages({
  cancel: {
    id: 'actions.cancel',
    defaultMessage: 'Cancel',
    description: 'Common label for the cancel action',
  },
  confirmMakePaymentLaterMessage: {
    id: 'feedback.confirmMakePaymentLater.message',
    defaultMessage:
      'Are you sure you want to proceed with this order without a payment?',
    description: 'Message of the make payment later feedback',
  },
  confirmMakePaymentLaterTitle: {
    id: 'feedback.confirmMakePaymentLater.title',
    defaultMessage: 'Make payment later',
    description: 'Title of the make payment later feedback',
  },
  proceed: {
    id: 'actions.proceed',
    defaultMessage: 'Proceed',
    description: 'Common label for the proceed action',
  },
});
