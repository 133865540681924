import { useIntl } from 'react-intl';
import { Fields, Grid } from '@afosto/components';
import { Fieldset } from '../../../Fieldset';
import { translations } from './translations';

export const ProductDetailsFormSection = () => {
  const intl = useIntl();

  return (
    <Fieldset title={intl.formatMessage(translations.details)}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Fields.TextField
            name="sku"
            label={intl.formatMessage(translations.sku)}
            autoFocus
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Fields.CurrencyField
            name="price"
            label={intl.formatMessage(translations.price)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Fields.TextField
            name="label"
            label={intl.formatMessage(translations.productName)}
            fullWidth
          />
        </Grid>
      </Grid>
    </Fieldset>
  );
};
