import { createBrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { routes } from '../routes';

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

export const setupRouter = () => {
  return sentryCreateBrowserRouter(routes);
};
