import { defineMessages } from 'react-intl';

export const translations = defineMessages({
  addCustomer: {
    id: 'actions.addCustomer',
    defaultMessage: 'Add customer',
    description: 'Common label for add customer action',
  },
  editCustomer: {
    id: 'actions.editCustomer',
    defaultMessage: 'Edit customer',
    description: 'Common label for edit customer action',
  },
});
