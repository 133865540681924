import { defineMessages } from 'react-intl';

export const translations = defineMessages({
  contacts: {
    id: 'common.contacts',
    defaultMessage: 'Contacts',
    description: 'Common label for contacts',
  },
  orders: {
    id: 'common.orders',
    defaultMessage: 'Orders',
    description: 'Common label for orders',
  },
  organisations: {
    id: 'common.organisations',
    defaultMessage: 'Organisations',
    description: 'Common label for organisations',
  },
  products: {
    id: 'common.products',
    defaultMessage: 'Products',
    description: 'Common label for products',
  },
  startASearch: {
    id: 'feedback.startASearch.message',
    defaultMessage: 'Start a search',
    description: 'Feedback message for start a search',
  },
});
