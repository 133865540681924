import {
  graphQLClient,
  gql,
  type GraphQLRequestOptions,
} from '@afosto/graphql-client';
import { CoreOrderFragment } from '../fragments';
import type { AdjustmentInput } from './addAdjustmentsToOrder';
import type { Order } from '../types';

export interface UpdateAdjustmentForOrderResponse {
  updateAdjustmentForOrder: {
    order: Order;
  };
}

export interface UpdateAdjustmentForOrderInput {
  orderId: string;
  adjustment: AdjustmentInput;
  requestOptions?: GraphQLRequestOptions<UpdateAdjustmentForOrderResponse>;
}

export const updateAdjustmentForOrder = async ({
  orderId,
  adjustment,
  requestOptions = {},
}: UpdateAdjustmentForOrderInput) => {
  const mutation = gql`
    ${CoreOrderFragment}
    mutation UpdateAdjustmentForOrder($input: UpdateAdjustmentForOrderInput!) {
      updateAdjustmentForOrder(input: $input) {
        order {
          ...CoreOrderFragment
        }
      }
    }
  `;

  return graphQLClient.request<UpdateAdjustmentForOrderResponse>(
    mutation,
    {
      input: {
        orderId,
        discountId: adjustment.id,
        description: adjustment.description,
        isPercentage: adjustment.isPercentage,
        amount: adjustment.amount,
      },
    },
    requestOptions
  );
};
