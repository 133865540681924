import { defineMessages } from 'react-intl';

export const translations = defineMessages({
  details: {
    id: 'common.details',
    defaultMessage: 'Details',
    description: 'Common label for details',
  },
  email: {
    id: 'common.email',
    defaultMessage: 'Email',
    description: 'Common label for email',
  },
  firstName: {
    id: 'common.firstName',
    defaultMessage: 'First name',
    description: 'Common label for first name',
  },
  lastName: {
    id: 'common.lastName',
    defaultMessage: 'Last name',
    description: 'Common label for last name',
  },
  phoneNumber: {
    id: 'common.phoneNumber',
    defaultMessage: 'Phone number',
    description: 'Common label for phone number',
  },
  prefix: {
    id: 'common.prefix',
    defaultMessage: 'Prefix',
    description: 'Common label for prefix',
  },
});
