import {
  graphQLClient,
  gql,
  type GraphQLRequestOptions,
} from '@afosto/graphql-client';
import { CoreOrderFragment } from '../fragments';
import type { AdjustmentInput } from './addAdjustmentsToOrder';
import type { Order, OrderState } from '../types';

export interface CreateOrderResponse {
  createOrder: {
    order: Order;
  };
}

export interface AddressInput {
  additionalName?: string;
  addressLine1: string;
  addressLine2?: string;
  administrativeArea?: string;
  countryCode: string;
  dependentLocality?: string;
  familyName?: string;
  givenName?: string;
  locality: string;
  organisation?: string;
  postalCode: string;
  premiseNumber: number;
  premiseNumberSuffix?: string;
  sortingCode: string;
  thoroughfare: string;
}

export type AddressType = 'ADDRESS' | 'LOCATION' | 'SERVICE_POINT';

export interface AddressingInput {
  billing?: AddressInput;
  shipping?: AddressInput;
}

export interface PhoneNumberInput {
  countryCode?: string;
  number: string;
}

export interface ContactInput {
  id?: string;
  additionalName?: string;
  addressing?: AddressingInput;
  channelId?: string;
  isGuest: boolean;
  email: string;
  familyName: string;
  givenName: string;
  password?: string;
  phoneNumber?: PhoneNumberInput;
}

export interface AdministrationInput {
  email: string;
}

export interface RegistrationInput {
  countryCode: string;
  number: string;
}

export interface OrganisationInput {
  id?: string;
  addressing?: AddressingInput;
  administration: AdministrationInput;
  channelId?: string;
  cocNumber?: string;
  isGuest: boolean;
  name: string;
  phoneNumber?: PhoneNumberInput;
  registration?: RegistrationInput;
}

export interface AddExistingContactInput {
  contact?: never;
  contactId: string;
}

export interface AddNewContactInput {
  contact: ContactInput;
  contactId?: never;
}

export interface AddExistingOrganisationInput {
  organisation?: never;
  organisationId: string;
}

export interface AddNewOrganisationInput {
  organisation: OrganisationInput;
  organisationId?: never;
}

export type CustomerInput =
  | AddExistingContactInput
  | AddNewContactInput
  | AddExistingOrganisationInput
  | AddNewOrganisationInput;

export interface CreateOrderShippingAddressInput {
  addressId?: string;
  address?: AddressInput;
  type: AddressType;
}

export interface CreateOrderBillingInput {
  addressId?: string;
  address?: AddressInput;
}

export interface CreateOrderDeliveryInput {
  methodId?: string;
  addresses: CreateOrderShippingAddressInput[];
}

export interface CreateOrderInput {
  adjustments?: AdjustmentInput[];
  billing?: CreateOrderBillingInput;
  channelId: string;
  countryCode?: string;
  currency?: string;
  customer?: CustomerInput;
  delivery?: CreateOrderDeliveryInput;
  id?: string;
  metaData?: Record<string, unknown>;
  number?: string;
  phoneNumber?: PhoneNumberInput;
  phoneNumberId?: string;
  sessionId?: string;
  state?: OrderState;
  requestOptions?: GraphQLRequestOptions<CreateOrderResponse>;
}

export const createOrder = async ({
  id,
  adjustments,
  billing,
  channelId,
  countryCode,
  currency,
  customer,
  delivery,
  metaData,
  number,
  phoneNumber,
  phoneNumberId,
  sessionId,
  state,
  requestOptions = {},
}: CreateOrderInput) => {
  const mutation = gql`
    ${CoreOrderFragment}
    mutation CreateOrder($input: CreateOrderInput!) {
      createOrder(input: $input) {
        order {
          ...CoreOrderFragment
        }
      }
    }
  `;

  return graphQLClient.request<CreateOrderResponse>(
    mutation,
    {
      input: {
        id,
        adjustments,
        billing,
        channelId,
        countryCode,
        currency,
        customer,
        delivery,
        metaData,
        number,
        phoneNumber,
        phoneNumberId,
        sessionId,
        state,
      },
    },
    requestOptions
  );
};
