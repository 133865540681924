import { Box, CircularProgress, Typography } from '@afosto/components';
import type { LoaderProps } from './types';

export const Loader = (props: LoaderProps) => {
  const { message, sx = {} } = props;

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'space-between',
        gap: 5,
        ...sx,
      }}
    >
      <CircularProgress size={48} />
      {message && <Typography variant="h3">{message}</Typography>}
    </Box>
  );
};
