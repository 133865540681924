import { AppContext } from './AppContext';
import { useAppProvider } from './hooks/useAppProvider';
import type { AppProviderProps } from './types';

export const AppProvider = (props: AppProviderProps) => {
  const { children, ...otherProps } = props;

  const appProviderState = useAppProvider(otherProps);

  return (
    <AppContext.Provider value={appProviderState}>
      {children}
    </AppContext.Provider>
  );
};
