import { useIntl } from 'react-intl';
import { Fields } from '@afosto/components';
import { capitalize } from '@afosto/utils';
import { Fieldset } from '../../../Fieldset';
import { CUSTOMER_TYPES } from './constants';
import { translations } from './translations';

export const TypeFormSection = () => {
  const intl = useIntl();

  return (
    <Fieldset title={intl.formatMessage(translations.type)}>
      <Fields.OptionSwitch
        name="customerType"
        options={CUSTOMER_TYPES.map((customerType) => {
          const translationKey =
            `customerTypes.${customerType}` as keyof typeof translations;

          return {
            label: translations[translationKey]
              ? intl.formatMessage(translations[translationKey])
              : capitalize(customerType),
            value: customerType,
          };
        })}
      />
    </Fieldset>
  );
};
