import { SvgIcon, type SvgIconProps } from '@afosto/components';

export const OnAccount = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 30 20">
    <rect
      width="27"
      height="19"
      x=".5"
      y=".5"
      fill="#fff"
      stroke="#D8E0E7"
      rx="3.5"
    />
    <path
      fill="#4A6579"
      d="M15.67 3.33v3.34H19l-3.33-3.34Zm-.84 3.34V3.33h-4.58C9.56 3.33 9 3.9 9 4.58v10.84c0 .69.56 1.25 1.25 1.25h7.5c.69 0 1.25-.56 1.25-1.25V7.5h-3.31a.84.84 0 0 1-.86-.83ZM10.67 5.2c0-.12.1-.21.2-.21h2.09c.11 0 .2.1.2.2v.42a.2.2 0 0 1-.2.21h-2.09c-.1 0-.2-.1-.2-.2V5.2Zm0 1.67c0-.12.1-.21.2-.21h2.09c.11 0 .2.09.2.2v.42a.2.2 0 0 1-.2.21h-2.09a.2.2 0 0 1-.2-.2v-.42Zm6.25 6.45c.23 0 .41.19.41.42 0 .23-.18.42-.41.42h-1.23c-.43 0-.82-.24-1.01-.63a.29.29 0 0 0-.26-.16.28.28 0 0 0-.26.15l-.2.4a.44.44 0 0 1-.38.24h-.03a.41.41 0 0 1-.36-.29l-.44-1.31-.28.83c-.15.46-.58.77-1.06.77h-.33a.42.42 0 0 1-.41-.42c0-.23.18-.42.41-.42h.33a.3.3 0 0 0 .27-.2l.48-1.42a.62.62 0 0 1 1.18 0l.36 1.09a1.16 1.16 0 0 1 1.72.37c.05.1.16.16.27.16h1.23Z"
    />
  </SvgIcon>
);
