import { SvgIcon, type SvgIconProps } from '@afosto/components';

export const Cash = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 30 20">
    <rect
      width="27"
      height="19"
      x=".5"
      y=".5"
      fill="#fff"
      stroke="#DBE2EA"
      rx="3.5"
    />
    <path
      fill="#4B6373"
      d="M20 5.88c0 .42-.34.8-.9 1.12-.68.38-1.7.64-2.87.72a9.27 9.27 0 0 0-4.3-.71L11.9 7c-.56-.31-.9-.7-.9-1.13C11 4.84 13 4 15.5 4c2.48 0 4.5.84 4.5 1.88Zm-8.23 1.9c.24-.02.48-.03.73-.03 1.46 0 2.75.29 3.57.74.59.31.93.7.93 1.13 0 .08-.02.19-.05.28-.1.3-.42.6-.82.83l-.03.02c-.82.45-2.13.75-3.6.75-1.4 0-2.65-.26-3.47-.68-.05-.03-.09-.07-.13-.07-.57-.31-.9-.7-.9-1.13 0-.81 1.25-1.5 3-1.76.25-.04.5-.07.77-.08Zm5.42.6a7.8 7.8 0 0 0 1.78-.49A4.1 4.1 0 0 0 20 7.3v.83c0 .46-.39.87-1.03 1.2-.34.17-.76.32-1.22.43v-.13c0-.5-.25-.93-.56-1.25Zm-.19 3.5c0 .42-.34.8-.9 1.12-.04 0-.08.04-.13.07-.82.42-2.07.68-3.47.68-1.47 0-2.78-.3-3.6-.75-.57-.31-.9-.7-.9-1.13v-.82c.3.24.65.43 1.03.6.93.38 2.15.6 3.47.6 1.32 0 2.55-.22 3.47-.6a4.72 4.72 0 0 0 .93-.53l.1-.07v.82Zm.75-1.36c.42-.12.86-.22 1.22-.38a4.1 4.1 0 0 0 1.03-.6v.84c0 .24-.12.49-.35.72a4.1 4.1 0 0 1-1.9.9v-1.48ZM12.5 14.5c1.32 0 2.55-.22 3.47-.6a4.1 4.1 0 0 0 1.03-.6v.82c0 1.04-2.02 1.88-4.5 1.88-2.49 0-4.5-.84-4.5-1.88v-.82c.3.24.65.43 1.03.6.93.38 2.15.6 3.47.6Z"
    />
  </SvgIcon>
);
