import {
  Button,
  type FormProviderFormProps,
  useFormContext,
  type UseFormReturn,
} from '@afosto/components';
import { DialogTopBar } from '../DialogTopBar';
import type { FormDialogTopBarProps } from './types';

export const FormDialogTopBar = (props: FormDialogTopBarProps) => {
  const { actions, ...otherProps } = props;

  const { formProps } = useFormContext() as UseFormReturn & {
    formProps: FormProviderFormProps;
  };
  const { hideSubmitButton, onCancel, submitButtonProps } = formProps;
  const {
    label: submitButtonLabel,
    loading,
    ...otherSubmitButtonProps
  } = submitButtonProps || {};

  return (
    <DialogTopBar
      {...otherProps}
      actions={
        <>
          {actions}
          {!hideSubmitButton && (
            <Button
              type="submit"
              variant="text"
              size="extraLarge"
              loading={loading}
              {...otherSubmitButtonProps}
              sx={{
                fontSize: 16,
                mr: -3,
                ...(otherSubmitButtonProps?.sx || {}),
              }}
            >
              {submitButtonLabel}
            </Button>
          )}
        </>
      }
      disableCloseButton={loading}
      onClose={onCancel}
    />
  );
};
