import { LinearProgress, styled } from '@afosto/components';

export const Container = styled('div')`
  background-color: ${(props) => props.theme.palette.gray[50]};
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
`;

export const Progress = styled(LinearProgress)`
  margin-top: ${(props) => props.theme.spacing(4)};
  margin-bottom: ${(props) => props.theme.spacing(9)};
  width: 250px;

  &.has-message {
    margin-bottom: ${(props) => props.theme.spacing(4)};
  }
`;
