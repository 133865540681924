import { useCallback, useState } from 'react';
import type { Category } from '../types';

export const useCategoryProvider = () => {
  const [category, setCategory] = useState<Category | null>(null);
  const [showSelectCategoryDialog, setShowSelectCategoryDialog] =
    useState(false);

  const closeSelectCategoryDialog = useCallback(() => {
    setShowSelectCategoryDialog(false);
  }, []);

  const openSelectCategoryDialog = useCallback(() => {
    setShowSelectCategoryDialog(true);
  }, []);

  const selectCategory = useCallback((selectedCategory: Category) => {
    setCategory(selectedCategory);
  }, []);

  return {
    category,
    closeSelectCategoryDialog,
    openSelectCategoryDialog,
    selectCategory,
    showSelectCategoryDialog,
  };
};
