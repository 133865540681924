import { type ChangeEvent, type KeyboardEvent, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import {
  Box,
  Divider,
  IconButton,
  InputAdornment,
  TextField,
} from '@afosto/components';
import { useAsyncDebounce } from '@afosto/hooks';
import { Times } from '../../icons/light';
import { Search } from '../../icons/regular';
import { searchOrder } from '../../queries';
import { useChannel } from '../ChannelProvider/hooks/useChannel';
import { SearchResults } from '../SearchResults';
import { OrderSearchResultListItem } from '../OrderSearchResultListItem';
import { useOrder } from '../OrderProvider/hooks/useOrder';
import { translations } from './translations';
import type { OrderSearchResultItem } from '../Search/types';
import type { OrderState } from '../../types';
import type { OrderSearchProps } from './types';

export const OrdersSearch = (props: OrderSearchProps) => {
  const { type } = props;

  const intl = useIntl();
  const navigate = useNavigate();
  const [searchInput, setSearchInput] = useState('');
  const [searchQuery, setSearchQuery] = useState('');

  const { channel } = useChannel();
  const { loadOrder } = useOrder();

  const { data: ordersResponse, isFetching: isFetchingOrders } = useQuery({
    ...searchOrder(searchQuery, {
      ...(channel ? { channel } : {}),
      itemsPerPage: 50,
      type,
    }),
  });
  const orderResults: OrderSearchResultItem[] = (ordersResponse || []).map(
    (item) => ({
      ...item,
      createdAt: item.createdAt as number,
      currency: item.currency as string,
      number: item.number as string,
      description: item.number as string,
      label: item.number as string,
      state: item.state as OrderState,
      total: item.total as number,
      value: item.id,
    })
  );

  const startSearch = useAsyncDebounce((value: string) => {
    setSearchQuery(value);
  }, 250);

  const handleChangeSearchInput = (event: ChangeEvent<HTMLInputElement>) => {
    const searchValue = event.target.value || '';

    setSearchInput(searchValue);
    startSearch(searchValue);
  };

  const handleClearSearchInput = () => {
    setSearchInput('');
    setSearchQuery('');
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      return false;
    }
  };

  const handleSelectResult = (result: OrderSearchResultItem) => async () => {
    try {
      //TODO: Do something with loading state
      await loadOrder(result.id);
      navigate('/');
    } catch {
      //TODO: Do something with error.
    }
  };

  return (
    <div>
      <Box
        sx={{
          px: 2.5,
          pt: 2.5,
        }}
      >
        <TextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" sx={{ ml: 2 }}>
                <Search />
              </InputAdornment>
            ),
            endAdornment: searchInput ? (
              <InputAdornment position="end">
                <IconButton
                  onClick={handleClearSearchInput}
                  variant="minimalLight2"
                  size="large"
                >
                  <Times />
                </IconButton>
              </InputAdornment>
            ) : undefined,
          }}
          onChange={handleChangeSearchInput}
          onKeyDown={handleKeyDown}
          placeholder={intl.formatMessage(translations.searchAnOrder)}
          value={searchInput}
          fullWidth
        />
        <Divider sx={{ mt: 2.5 }} />
      </Box>
      <SearchResults<OrderSearchResultItem>
        isSearching={isFetchingOrders}
        onSelectSearchResult={handleSelectResult}
        searchResults={orderResults}
        SearchResultsListItemComponent={OrderSearchResultListItem}
        searchQuery={searchQuery}
        showSelector
      />
    </div>
  );
};
