import { Card, Typography } from '@afosto/components';
import * as Styled from './CardSelector.styles';
import { CARD_SELECTOR_COLORS } from './constants';
import type { CardSelectorContentProps, CardSelectorProps } from './types';

const CardSelectorContent = (props: CardSelectorContentProps) => {
  const { children, color = 'default', description, Icon, label } = props;
  const showLabelContainer = !!description || !!Icon || !!label;
  const activeColor =
    CARD_SELECTOR_COLORS[color as keyof typeof CARD_SELECTOR_COLORS] ||
    CARD_SELECTOR_COLORS.default;

  return (
    <>
      {showLabelContainer && (
        <Styled.LabelContainer>
          {Icon && (
            <Icon
              sx={{
                color: activeColor.icon,
                fontSize: 24,
                ...(description ? { alignSelf: 'flex-start' } : {}),
              }}
            />
          )}
          {(label || description) && (
            <div>
              {label && (
                <Typography color={activeColor.label} variant="h6">
                  {label}
                </Typography>
              )}
              {description && (
                <Typography color="gray.800">{description}</Typography>
              )}
            </div>
          )}
        </Styled.LabelContainer>
      )}
      {!!children && children}
    </>
  );
};

export const CardSelector = (props: CardSelectorProps) => {
  const { disabled = false, onSelect, ...otherProps } = props;

  return (
    <Card>
      {onSelect && (
        <Styled.ActionArea disabled={disabled} onClick={onSelect}>
          <CardSelectorContent {...otherProps} />
          <Styled.SelectIcon />
        </Styled.ActionArea>
      )}
      {!onSelect && (
        <Styled.Container>
          <CardSelectorContent {...otherProps} />
        </Styled.Container>
      )}
    </Card>
  );
};
