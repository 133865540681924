import { defineMessages } from 'react-intl';

export const translations = defineMessages({
  confirm: {
    id: 'actions.confirm',
    defaultMessage: 'Confirm',
    description: 'Common label for the confirm action',
  },
  description: {
    id: 'actions.cancelProducts.description',
    defaultMessage:
      'Manually scan or select products to cancel them on the order',
    description: 'Common description for the cancel products action',
  },
  title: {
    id: 'actions.cancelProducts',
    defaultMessage: 'Cancel products',
    description: 'Common label for cancel products action',
  },
});
