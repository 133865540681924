import { useIntl } from 'react-intl';
import { CardSelector } from '../../../CardSelector';
import { Fieldset } from '../../../Fieldset';
import { useOrder } from '../../../OrderProvider/hooks/useOrder';
import { CommentAltDots } from '../../../../icons/solid';
import { translations } from './translations';

export const NoteFormSection = () => {
  const intl = useIntl();
  const { openOrderNoteDialog, order } = useOrder();
  const currentNote = order?.customer?.notes;

  return (
    <Fieldset title={intl.formatMessage(translations.note)}>
      <CardSelector
        Icon={CommentAltDots}
        label={currentNote || intl.formatMessage(translations.addNote)}
        onSelect={openOrderNoteDialog}
      />
    </Fieldset>
  );
};
