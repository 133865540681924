import { SvgIcon, type SvgIconProps } from '@afosto/components';

export const Ideal = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 30 20">
    <rect
      width="28.17"
      height="19"
      x="1.17"
      y=".5"
      fill="#fff"
      stroke="#E4E9EF"
      rx="3.5"
    />
    <path
      fill="#000"
      d="M11.1 10.17a1.26 1.26 0 1 0 0-2.51 1.26 1.26 0 0 0 0 2.51Zm-1 .53h2.02v3.6H10.1v-3.6Z"
    />
    <path
      fill="#000"
      fillRule="evenodd"
      d="M8.6 4.13h6.93c4.72 0 6.53 2.58 6.53 5.83 0 3.9-2.53 5.86-6.53 5.86H8.6V4.12Zm.65.7v10.33h6.28c3.8 0 5.85-1.77 5.85-5.18 0-3.5-2.22-5.15-5.85-5.15H9.25Z"
      clipRule="evenodd"
    />
    <path
      fill="#D50072"
      fillRule="evenodd"
      d="M19.1 7.76h.57v1.73h.85c-.23-3.15-2.72-3.86-4.99-3.86h-2.4v2.13h.34c.66 0 1.06.43 1.06 1.15s-.4 1.16-1.03 1.16h-.38v4.22h2.41c3.7 0 4.95-1.7 5-4.22H19.1V7.76Zm-2.78 1.73v.58h-1.44V7.76h1.4v.58h-.83v.26h.78v.58h-.76v.31h.85Zm.24.58.7-2.31h.83l.7 2.3h-.6l-.12-.43h-.76l-.13.44h-.62Zm.92-1.03h-.01v.02-.02Zm0 0h.4l-.18-.64h-.02l-.2.64Zm-4.36.45V8.34h.35c.23 0 .48.06.48.57 0 .52-.23.58-.48.58h-.35Z"
      clipRule="evenodd"
    />
  </SvgIcon>
);
