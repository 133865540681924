import { defineMessages } from 'react-intl';

export const translations = defineMessages({
  details: {
    id: 'common.details',
    defaultMessage: 'Details',
    description: 'Common label for details',
  },
  sku: {
    id: 'common.sku',
    defaultMessage: 'SKU',
    description: 'Common label for sku',
  },
  price: {
    id: 'common.price',
    defaultMessage: 'Price',
    description: 'Common label for price',
  },
  productName: {
    id: 'common.productName',
    defaultMessage: 'Product name',
    description: 'Common label for product name',
  },
});
