import apiClient from '@afosto/api-client';
import { Management } from '@afosto/payment-service';
import { queryOptions } from '@tanstack/react-query';
import type { AddressObject } from '@afosto/components';
import type { GraphQLRequestOptions } from '@afosto/graphql-client';
import type {
  Contact,
  EntityReference,
  PaymentStatus,
  PaymentType,
  ServiceType,
} from '../types';

export type OrderPaymentActionType = 'EXECUTE' | 'FOLLOW' | 'START';

export interface OrderPaymentAction {
  type: OrderPaymentActionType;
  url: string;
}

export interface OrderPaymentAllocation {
  amount: number;
  createdAt: number;
  reference: EntityReference;
}

export interface OrderPaymentExpress {
  address?: AddressObject;
  amount: number;
  customer?: Contact;
  email?: number;
  isPreauthorized: boolean;
  payerId: string;
}

export interface OrderPaymentItem {
  ids: string[];
  amount: number;
  description: string;
  quantity: number;
  sku: string;
  vatRate: string;
}

export interface OrderPaymentPspProvider {
  id: string;
  isProcessed: boolean;
  metadata?: Record<string, unknown>;
  methodId: string;
  processedAt: number;
  providerTransactionReference: string;
}

export interface OrderPaymentService {
  ids: string[];
  amount: number;
  description: string;
  type: ServiceType;
  vatRate: string;
}

export interface OrderPayment {
  id: string;
  token: string;
  action?: OrderPaymentAction;
  allocation: OrderPaymentAllocation[];
  amount: number;
  amountFee: number;
  amountInvoiced: number;
  amountPaid: number;
  amountPreAuthorized: number;
  amountTotal: number;
  billingAddressId?: string;
  contactId?: string;
  createdAt: number;
  currency: string;
  description: string;
  express?: OrderPaymentExpress;
  integrationId?: string;
  isAuthorized: boolean;
  isCancelled: boolean;
  isCaptured: boolean;
  isExpired: boolean;
  isPaid: boolean;
  isPending: boolean;
  isPreAuthorized: boolean;
  isRefund: boolean;
  issuerId?: string;
  items: OrderPaymentItem[];
  metadata?: Record<string, string>;
  methodId: string;
  paidAt: number;
  phoneNumberId?: string;
  pspPayment?: OrderPaymentPspProvider;
  pspPreAuthorization?: OrderPaymentPspProvider;
  redirectUrl: string;
  reference: EntityReference;
  refundPaymentId?: string;
  returnUrl: string;
  services: OrderPaymentService[];
  shippingAddressId?: string;
  status: PaymentStatus;
  type: PaymentType;
  updatedAt: number;
}

export interface OrderPaymentResponse {
  data: OrderPayment | null;
}

export const GET_ORDER_PAYMENT_QUERY_KEY = 'getOrderPayment';

export const fetchOrderPayment = async ({
  queryKey,
}: {
  queryKey: [string, string, GraphQLRequestOptions<object>];
}): Promise<OrderPaymentResponse> => {
  const [, id, requestOptions = {}] = queryKey;

  const managementClient = new Management(apiClient);
  const response = await managementClient.getPaymentById(id, requestOptions);

  return response.data;
};

export const getOrderPayment = (
  id: string,
  requestOptions = {} as GraphQLRequestOptions<object>
) => {
  return queryOptions({
    queryKey: [GET_ORDER_PAYMENT_QUERY_KEY, id, requestOptions],
    queryFn: fetchOrderPayment,
    select: (response) => response?.data,
  });
};
