import { gql } from '@afosto/graphql-client';

export const CoreInvoiceFragment = gql`
  fragment CoreInvoiceFragment on Invoice {
    id
    created_at
    currency
    is_including_vat
    is_vat_shifted
    number
    subtotal
    total
    adjustments {
      amount
      description
      is_discount
    }
    lines {
      label
      quantity
      sku
      total
      debit {
        adjustments {
          amount
          description
          is_discount
        }
      }
    }
    vat {
      amount
      rate
    }
  }
`;
