import { stripWhitespaces } from '@afosto/utils';
import type { Contact } from '../types';

export const getFullName = (contact: Contact) => {
  const { givenName, additionalName, familyName } = contact || {};
  const formattedGivenName =
    givenName !== undefined && givenName !== null ? givenName : '';
  const formattedAdditionalName =
    additionalName !== undefined && additionalName !== null
      ? additionalName
      : '';
  const formattedFamilyName =
    familyName !== undefined && familyName !== null ? familyName : '';

  if (!givenName && !additionalName && !familyName) {
    return '';
  }

  return stripWhitespaces(
    `${formattedGivenName} ${formattedAdditionalName} ${formattedFamilyName}`
  );
};
