import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  label: yup.string().nullable(),
  price: yup.string().required(),
  quantity: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .required(),
  sku: yup.string().required(),
});
