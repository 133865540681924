import { useLocation } from 'react-router-dom';
import { MenuItem, Typography } from '@afosto/components';
import Link from '../../components/Link';
import { OrdersSearch } from '../../components/OrdersSearch';
import * as Styled from './Orders.styles';
import { FormattedMessage } from 'react-intl';
import { translations } from './translations';

export const Orders = () => {
  const { pathname } = useLocation();
  const type = pathname.replace('/orders', '').replace(/^\//, '');

  return (
    <Styled.Container>
      <Styled.LeftLayout>
        <Styled.TitleHeader>
          <Typography variant="h5">Orders</Typography>
        </Styled.TitleHeader>
        <Styled.MenuList>
          <MenuItem
            component={Link}
            href="/orders"
            selected={pathname === '/orders'}
          >
            <FormattedMessage {...translations.allOrders} />
          </MenuItem>
          <MenuItem
            component={Link}
            href="/orders/concept"
            selected={pathname === '/orders/concept'}
          >
            <FormattedMessage {...translations.concept} />
          </MenuItem>
          <MenuItem
            component={Link}
            href="/orders/open"
            selected={pathname === '/orders/open'}
          >
            <FormattedMessage {...translations.open} />
          </MenuItem>
          <MenuItem
            component={Link}
            href="/orders/closed"
            selected={pathname === '/orders/closed'}
          >
            <FormattedMessage {...translations.closed} />
          </MenuItem>
        </Styled.MenuList>
      </Styled.LeftLayout>
      <OrdersSearch type={type} />
    </Styled.Container>
  );
};
