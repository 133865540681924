import { useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import type { UseFormReturn } from '@afosto/components';
import { camelize, isDefined, uuid } from '@afosto/utils';
import { useQuery } from '@tanstack/react-query';
import { FormDialog } from '../FormDialog';
import { useOrder } from '../OrderProvider/hooks/useOrder';
import { CancelableItemsCounter } from './components/CancelableItemsCounter';
import { CancelableOrderItemsFormSection } from './components/CancelableItemsFormSection';
import { getCancelableOrderItems } from '../../queries';
import { useScanner } from '../../hooks/useScanner';
import { convertScanInput } from '../../utils/convertScanInput';
import { beepError, beepSuccess } from '../../utils';
import { translations } from './translations';
import type { CancelOrderItemsItemInput } from '../../mutations';
import type {
  CancelOrderItemsFormDialogFormData,
  CancelOrderItemsFormDialogProps,
} from './types';

export const CancelOrderItemsFormDialog = (
  props: CancelOrderItemsFormDialogProps
) => {
  const { formProps, open, ...otherProps } = props;

  const intl = useIntl();
  const formRef = useRef<UseFormReturn>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { cancelOrderItems, order, closeCancelOrderItemsDialog } = useOrder();
  const cancelAction = (order?.actions || []).find(
    (orderAction) => camelize(orderAction.action.toLowerCase()) === 'cancelAll'
  );

  const { data: cancelableItems, isFetched } = useQuery({
    ...getCancelableOrderItems({
      ids: cancelAction?.ids || [],
    }),
    enabled: (cancelAction?.ids || []).length > 0 && open,
  });

  const handleScanInput = (input: string) => {
    const { gtin, sku, ...itemValues } = convertScanInput(input);
    const cancelableItem = (cancelableItems || []).find((item) => {
      let isMatch = true;

      if (gtin && !item.gtin?.includes(gtin)) {
        isMatch = false;
      }

      return (
        isMatch &&
        Object.keys(itemValues).every(
          (propertyKey) =>
            itemValues[propertyKey as keyof typeof itemValues] ===
            item[propertyKey as keyof typeof item]
        )
      );
    });

    if (!cancelableItem) {
      beepError();
      return;
    }

    const [firstItemId] = cancelableItem.ids || [];
    const formValues = formRef.current?.getValues() || {};
    const fieldName = `items.${firstItemId}.quantity`;
    const currentQuantity = formValues?.items?.[firstItemId]?.quantity;
    const newQuantity = isDefined(currentQuantity)
      ? Number(currentQuantity) + 1
      : 1;

    if (newQuantity <= cancelableItem.quantity) {
      formRef.current?.setValue(fieldName, newQuantity);
      beepSuccess();
    } else {
      beepError();
    }
  };

  useScanner({
    enabled: open && isFetched,
    onScan: handleScanInput,
  });

  const handleSubmit = async (data: CancelOrderItemsFormDialogFormData) => {
    try {
      setIsSubmitting(true);

      const items = Object.keys(data?.items || {}).reduce(
        (acc: CancelOrderItemsItemInput[], itemId: string) => {
          const cancelableItem = (cancelableItems || []).find((item) =>
            item.ids.includes(itemId)
          );

          if (!cancelableItem) {
            return acc;
          }

          const dataItem = data?.items?.[itemId];
          const cancelledItems: CancelOrderItemsItemInput[] = (
            cancelableItem?.ids || []
          )
            .slice(0, Number(dataItem?.quantity || 0))
            .map((itemId) => ({
              contraItemId: uuid(),
              itemId,
              reason: 'CUSTOMER_REQUEST',
            }));

          return [...acc, ...cancelledItems];
        },
        []
      );

      await cancelOrderItems(
        {
          items,
        },
        { approveItems: true }
      );

      closeCancelOrderItemsDialog();
      setIsSubmitting(false);
    } catch {
      // Do something with error
      setIsSubmitting(false);
    }
  };

  return (
    <FormDialog
      {...otherProps}
      description={intl.formatMessage(translations.description)}
      formProps={{
        submitLabel: intl.formatMessage(translations.confirm),
        ...(formProps || {}),
        formRef,
        isSubmitting,
      }}
      onSubmit={handleSubmit}
      open={open}
      title={
        <>
          {intl.formatMessage(translations.title)}
          {isFetched && <CancelableItemsCounter items={cancelableItems} />}
        </>
      }
    >
      {isFetched && <CancelableOrderItemsFormSection items={cancelableItems} />}
    </FormDialog>
  );
};
