import { useContext } from 'react';
import { PrintContext } from '../PrintContext';

export const usePrint = () => {
  const context = useContext(PrintContext);

  if (!context) {
    throw new Error('usePrint must be used within a PrintProvider');
  }

  return context;
};
