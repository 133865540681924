import {
  graphQLClient,
  gql,
  type GraphQLRequestOptions,
} from '@afosto/graphql-client';
import { queryOptions } from '@tanstack/react-query';
import { CoreOrderFragment } from '../fragments';
import type { Order } from '../types';

export interface GetOrderResponse {
  order: Order;
}

export const GET_ORDER_QUERY_KEY = 'getOrder';

const fetchOrder = async ({
  queryKey,
}: {
  queryKey: [string, string, GraphQLRequestOptions<GetOrderResponse>];
}) => {
  const [, id, requestOptions = {}] = queryKey;
  const graphQLQuery = gql`
    ${CoreOrderFragment}
    query getOrder($id: String!) {
      order(id: $id) {
        ...CoreOrderFragment
      }
    }
  `;

  return graphQLClient.request<GetOrderResponse>(
    graphQLQuery,
    { id },
    requestOptions
  );
};

export const getOrder = (
  id: string,
  requestOptions = {} as GraphQLRequestOptions<GetOrderResponse>
) => {
  return queryOptions({
    queryKey: [GET_ORDER_QUERY_KEY, id, requestOptions],
    queryFn: fetchOrder,
    select: (response) => response?.order,
  });
};
