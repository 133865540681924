import apiClient from '@afosto/api-client';
import { Orders } from '@afosto/commerce-service';

export interface DeleteOrderInput {
  orderId: string;
  requestOptions?: object;
}

export const deleteOrder = async ({
  orderId,
  requestOptions,
}: DeleteOrderInput) => {
  const ordersClient = new Orders(apiClient);
  await ordersClient.deleteOrder(orderId, requestOptions);
};
