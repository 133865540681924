import { SvgIcon, type SvgIconProps } from '@afosto/components';

export const VVVCadeaukaart = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 30 20">
    <g clipPath="url(#VVVGradient-a)">
      <path
        fill="#fff"
        d="M23.1 0H4.9A4.8 4.8 0 0 0 0 4.67v10.66A4.8 4.8 0 0 0 4.9 20h18.2a4.8 4.8 0 0 0 4.9-4.67V4.67A4.8 4.8 0 0 0 23.1 0Z"
      />
      <path fill="url(#VVVGradient-b)" d="M29.31-.53H-1.84v20.58h31.15V-.53Z" />
      <path
        fill="url(#VVVGradient-c)"
        d="m41.27 0-12.3 20.28a3.93 3.93 0 0 1-6.66 0L13.85 6.36 17.76 0h23.51Z"
      />
      <path fill="url(#VVVGradient-d)" d="m17.76 0-3.88 6.36L10.03 0h7.73Z" />
      <path
        fill="url(#VVVGradient-e)"
        d="m10.03 0 3.85 6.36-3.85 6.36a3.62 3.62 0 0 1-6.1 0L-3.78 0h13.82Z"
      />
      <path
        fill="url(#VVVGradient-f)"
        d="M5.9 30.08h15.8c1.78 0 2.89-1.83 2.01-3.3l-7.93-13.03a2.36 2.36 0 0 0-4 0l-7.9 13.03c-.88 1.47.23 3.3 2.01 3.3Z"
      />
      <path
        fill="#20419A"
        d="M6.78 5.33 8.1 3.75h11.45l1.68 1.58-6.56 11.54h-1.68L6.78 5.33Z"
      />
      <path
        fill="#fff"
        d="M18.43 9.75a.74.74 0 0 1-.64.35.75.75 0 0 1-.64-.35l-2.28-3.83a.7.7 0 0 1 .27-.97c.35-.2.8-.08 1 .26l1.66 2.76 1.65-2.75a.76.76 0 0 1 1.01-.26c.36.2.48.63.27.97l-2.3 3.82Zm-3.82 6.34a.74.74 0 0 1-.63.36.74.74 0 0 1-.64-.36l-2.29-3.83a.7.7 0 0 1 .27-.97c.36-.2.8-.07 1.01.27l1.65 2.76 1.66-2.76a.75.75 0 0 1 1-.26c.36.2.48.63.27.97l-2.3 3.82ZM9.52 9.8 7.24 5.96A.7.7 0 0 1 7.5 5c.35-.2.8-.08 1 .26l1.65 2.76 1.66-2.75a.75.75 0 0 1 1-.26c.36.2.48.63.28.97l-2.3 3.82a.74.74 0 0 1-.64.35.74.74 0 0 1-.64-.35Zm10.44-6.04H8.04c-.68 0-1.3.34-1.65.9a1.78 1.78 0 0 0-.02 1.83l5.82 10.07a1.95 1.95 0 0 0 3.32.04l6.1-10.07c.35-.57.35-1.27.01-1.85a1.93 1.93 0 0 0-1.66-.92Z"
      />
    </g>
    <defs>
      <linearGradient
        id="VVVGradient-b"
        x1="25.37"
        x2="10.34"
        y1="19.51"
        y2="25.9"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#83D0F5" />
        <stop offset=".47" stopColor="#0F99D6" />
        <stop offset="1" stopColor="#263680" />
      </linearGradient>
      <linearGradient
        id="VVVGradient-c"
        x1="25.63"
        x2="28.75"
        y1="-4.14"
        y2="9.78"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0F99D6" />
        <stop offset="1" stopColor="#263680" />
      </linearGradient>
      <linearGradient
        id="VVVGradient-d"
        x1="5.78"
        x2="3.44"
        y1="27.35"
        y2="2.33"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E3127E" />
        <stop offset="0" stopColor="#E3127E" />
        <stop offset="1" stopColor="#6F237F" />
      </linearGradient>
      <linearGradient
        id="VVVGradient-e"
        x1="10.46"
        x2="3.85"
        y1="27.01"
        y2="1.8"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FBBF07" />
        <stop offset=".47" stopColor="#EF8A09" />
        <stop offset="1" stopColor="#E74517" />
      </linearGradient>
      <linearGradient
        id="VVVGradient-f"
        x1="22.23"
        x2="23.46"
        y1="1.92"
        y2="13.92"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#98C12C" />
        <stop offset="1" stopColor="#0A873C" />
        <stop offset="1" stopColor="#0A873C" />
      </linearGradient>
      <clipPath id="VVVGradient-a">
        <rect width="28" height="20" fill="#fff" rx="4" />
      </clipPath>
    </defs>
  </SvgIcon>
);
