import apiClient from '@afosto/api-client';
import { Orders } from '@afosto/commerce-service';

export interface CreateOrderPaymentInputPayment {
  amount: number;
}

export interface CreateOrderPaymentInput {
  orderId: string;
  payment: CreateOrderPaymentInputPayment;
  requestOptions?: object;
}

export interface CreateOrderPaymentResponseData {
  id: string;
  action: {
    type: string;
    url: string;
  };
}

export interface CreateOrderPaymentResponse {
  data: CreateOrderPaymentResponseData | null;
}

export const createOrderPayment = async ({
  orderId,
  payment,
  requestOptions,
}: CreateOrderPaymentInput): Promise<CreateOrderPaymentResponse> => {
  const ordersClient = new Orders(apiClient);
  const response = await ordersClient.createOrderPayment(
    orderId,
    { data: payment },
    requestOptions
  );

  return response?.data;
};
