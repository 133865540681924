import { useIntl } from 'react-intl';
import { useQuery } from '@tanstack/react-query';
import { FullscreenDialog } from '../FullscreenDialog';
import { Loader } from '../Loader';
import { CategoriesList } from './components/CategoriesList';
import { listCategories } from '../../queries';
import { translations } from './translations';
import type { Category } from '../CategoryProvider';
import type { SelectCategoryDialogProps } from './types';

export const SelectCategoryDialog = (props: SelectCategoryDialogProps) => {
  const { onClose, onSelectCategory, open, title, ...otherProps } = props;

  const intl = useIntl();

  const { data: categories = [], isLoading } = useQuery({
    ...listCategories(),
    enabled: open,
  });

  const handleClose = () => {
    if (onClose && typeof onClose === 'function') {
      onClose();
    }
  };

  const handleSelectCategory = (category: Category) => () => {
    if (onSelectCategory && typeof onSelectCategory === 'function') {
      onSelectCategory(category);
    }

    handleClose();
  };

  return (
    <FullscreenDialog
      {...otherProps}
      hideDialogHeader={isLoading}
      onClose={!isLoading ? handleClose : undefined}
      open={open}
      title={title || intl.formatMessage(translations.title)}
    >
      {isLoading && (
        <Loader
          message={`${intl.formatMessage(translations.fetchingCategories)}...`}
          sx={{
            mt: 6,
          }}
        />
      )}
      {!isLoading && (
        <CategoriesList
          categories={categories}
          onSelectCategory={handleSelectCategory}
        />
      )}
    </FullscreenDialog>
  );
};
