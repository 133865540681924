import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Box, Button, FeedbackMessage, Link } from '@afosto/components';
import { FullscreenDialog } from '../FullscreenDialog';
import { useOrder } from '../OrderProvider/hooks/useOrder';
import { translations } from './translations';
import type { ConfirmDeleteOrderDialogProps } from './types';

export const ConfirmDeleteOrderDialog = (
  props: ConfirmDeleteOrderDialogProps
) => {
  const { onClose, ...otherProps } = props;

  const intl = useIntl();
  const [isDeletingOrder, setIsDeletingOrder] = useState(false);

  const { closeConfirmDeleteOrderDialog, deleteOrder, parkOrder } = useOrder();

  const handleClose = () => {
    if (onClose && typeof onClose === 'function') {
      onClose();
    }
  };

  const handleDeleteOrder = async () => {
    try {
      setIsDeletingOrder(true);
      await deleteOrder();
      closeConfirmDeleteOrderDialog();
      setIsDeletingOrder(false);
    } catch {
      //TODO: Do something with error
      setIsDeletingOrder(false);
    }
  };

  const handleParkOrder = () => {
    parkOrder();
    closeConfirmDeleteOrderDialog();
  };

  return (
    <FullscreenDialog
      {...otherProps}
      onClose={handleClose}
      topBarProps={{
        actions: (
          <Link
            component="button"
            onClick={handleClose}
            fontWeight={500}
            type="button"
            variant="bodyLarge"
          >
            <FormattedMessage {...translations.close} />
          </Link>
        ),
      }}
      hideDialogHeader
    >
      <Box sx={{ mt: 6 }}>
        <FeedbackMessage
          description={intl.formatMessage(translations.description)}
          descriptionTypographyProps={{
            variant: 'bodyLarge',
          }}
          title={intl.formatMessage(translations.title)}
          titleTypographyProps={{
            variant: 'h3',
          }}
          severity="error"
        >
          <Box sx={{ display: 'flex', gap: 3, justifyContent: 'center' }}>
            <Button
              color="secondary"
              disabled={isDeletingOrder}
              onClick={handleParkOrder}
              size="large"
              sx={{ maxWidth: 240 }}
              variant="outlined"
              fullWidth
            >
              <FormattedMessage {...translations.park} />
            </Button>
            <Button
              color="error"
              loading={isDeletingOrder}
              onClick={handleDeleteOrder}
              size="large"
              sx={{ maxWidth: 240 }}
              fullWidth
            >
              <FormattedMessage {...translations.delete} />
            </Button>
          </Box>
        </FeedbackMessage>
      </Box>
    </FullscreenDialog>
  );
};
