import { AuthenticationError as AfAuthenticationError } from '@afosto/auth-react';
import { Grid } from '@afosto/components';

export const AuthenticationError = () => {
  return (
    <Grid item xs={12} sm={8} md={6}>
      <AfAuthenticationError />
    </Grid>
  );
};
