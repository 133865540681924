import { SvgIcon, type SvgIconProps } from '@afosto/components';

export const PayPal = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 30 20">
    <rect
      width="27"
      height="19"
      x=".5"
      y=".5"
      fill="#fff"
      stroke="#DBE2EA"
      rx="3.5"
    />
    <path
      fill="#28356A"
      fillRule="evenodd"
      d="m12.04 15.37.18-1.2-.4-.01H9.87l1.34-8.92.04-.07a.1.1 0 0 1 .07-.02h3.26c1.08 0 1.83.23 2.22.7.18.22.3.44.36.7.06.26.06.57 0 .96v.27l.18.1c.15.1.27.2.37.3.15.19.26.43.3.7a3.7 3.7 0 0 1-.44 2.18 2.34 2.34 0 0 1-1.5 1.14c-.3.08-.67.13-1.06.13h-.25a.75.75 0 0 0-.5.19.81.81 0 0 0-.26.48l-.02.1-.32 2.14-.01.08-.02.04-.04.01h-1.56Z"
      clipRule="evenodd"
    />
    <path
      fill="#298FC2"
      fillRule="evenodd"
      d="M17.52 7.56c0 .07-.02.13-.03.2-.43 2.31-1.9 3.1-3.78 3.1h-.96a.47.47 0 0 0-.46.42l-.63 4.17c-.02.16.1.3.25.3h1.7c.2 0 .36-.16.4-.36l.01-.1.32-2.11.02-.12c.03-.2.2-.36.4-.36h.26c1.64 0 2.93-.7 3.3-2.72.16-.84.08-1.55-.33-2.04a1.63 1.63 0 0 0-.47-.38Z"
      clipRule="evenodd"
    />
    <path
      fill="#22284F"
      fillRule="evenodd"
      d="M17.07 7.37a3.25 3.25 0 0 0-.42-.1 5.09 5.09 0 0 0-.84-.06h-2.56a.4.4 0 0 0-.17.05.42.42 0 0 0-.23.31l-.54 3.6-.02.11a.47.47 0 0 1 .46-.41h.96c1.88 0 3.35-.8 3.78-3.1l.03-.2a2.23 2.23 0 0 0-.45-.2Z"
      clipRule="evenodd"
    />
    <path
      fill="#28356A"
      fillRule="evenodd"
      d="M12.85 7.57a.4.4 0 0 1 .23-.31.4.4 0 0 1 .17-.05h2.56a5.09 5.09 0 0 1 1.36.2c.12.04.24.1.35.15.13-.85 0-1.43-.44-1.96-.49-.58-1.36-.82-2.49-.82h-3.26a.47.47 0 0 0-.46.4L9.51 14.2c-.02.18.1.34.28.34h2.01l1.05-6.96Z"
      clipRule="evenodd"
    />
  </SvgIcon>
);
