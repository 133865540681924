import { useIntl } from 'react-intl';
import { useOrder } from '../OrderProvider/hooks/useOrder';
import { ShortcutCard } from '../ShortcutCard';
import { Tag } from '../../icons/solid';
import { translations } from './translations';

export const ManuallyAddProductShortcut = () => {
  const intl = useIntl();
  const { openManuallyAddProductDialog } = useOrder();

  return (
    <ShortcutCard
      label={intl.formatMessage(translations.manuallyAddProduct)}
      Icon={Tag}
      onClick={openManuallyAddProductDialog}
    />
  );
};
