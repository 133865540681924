import { queryOptions } from '@tanstack/react-query';
import {
  graphQLClient,
  gql,
  type GraphQLRequestOptions,
} from '@afosto/graphql-client';
import type { Table } from '../types';

export interface GetTableResponse {
  table: Table;
}

export const GET_TABLE_QUERY_KEY = 'getTable';

export const fetchTable = async ({
  queryKey,
}: {
  queryKey: [string, string, GraphQLRequestOptions<GetTableResponse>];
}) => {
  const [, category, requestOptions = {}] = queryKey;
  const graphQLQuery = gql`
    query getTable($category: String!) {
      table(id: $category, category: CATEGORY) {
        id
        name
        columns {
          key
          label
          type
          is_sortable
          is_visible
          index_as
          foreign_key {
            type
            column
          }
          display {
            type
            options {
              color
              label
              value
            }
          }
        }
        views {
          id
          label
          columns
          pagesize
          sort
          is_default
          filters {
            key
            operator
            value
          }
          rbac {
            roles {
              id
              name
            }
          }
          created_at
          updated_at
        }
      }
    }
  `;

  return graphQLClient.request<GetTableResponse>(
    graphQLQuery,
    { category },
    requestOptions
  );
};

export const getTable = (
  category: string,
  requestOptions = {} as GraphQLRequestOptions<GetTableResponse>
) => {
  return queryOptions({
    queryKey: [GET_TABLE_QUERY_KEY, category, requestOptions],
    queryFn: fetchTable,
  });
};
