import { defineMessages } from 'react-intl';

export const translations = defineMessages({
  close: {
    id: 'common.close',
    defaultMessage: 'Close',
    description: 'Common label for close',
  },
  delete: {
    id: 'common.delete',
    defaultMessage: 'Delete',
    description: 'Common label for delete',
  },
  description: {
    id: 'feedback.conceptOrderDeleteOrPark.description',
    defaultMessage:
      'Decide if you want to delete the order or park it to continue later',
    description: 'Description for delete or park concept order feedback',
  },
  park: {
    id: 'common.park',
    defaultMessage: 'Park',
    description: 'Common label for park',
  },
  title: {
    id: 'feedback.conceptOrderDeleteOrPark.title',
    defaultMessage: 'Delete/park concept order',
    description: 'Title for delete or park concept order feedback',
  },
});
