import { defineMessages } from 'react-intl';

export const translations = defineMessages({
  description: {
    id: 'components.selectChannelDialog.description',
    defaultMessage: 'Select the POS you want to use',
    description: 'Description of the select channel dialog',
  },
  title: {
    id: 'components.selectChannelDialog.title',
    defaultMessage: 'Select a POS',
    description: 'Title of the select channel dialog',
  },
});
