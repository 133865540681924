import { useIntl } from 'react-intl';
import { camelize } from '@afosto/utils';
import { useOrder } from '../OrderProvider/hooks/useOrder';
import { ShortcutCard } from '../ShortcutCard';
import { Times } from '../../icons/solid';
import { translations } from './translations';

export const CancelProductsShortcut = () => {
  const intl = useIntl();
  const { openCancelOrderItemsDialog, order } = useOrder();

  const cancelAction = (order?.actions || []).find(
    (orderAction) => camelize(orderAction.action.toLowerCase()) === 'cancelAll'
  );

  return (
    <ShortcutCard
      disabled={!cancelAction?.ids?.length}
      Icon={Times}
      label={intl.formatMessage(translations.cancelProducts)}
      onClick={openCancelOrderItemsDialog}
    />
  );
};
