import { FormattedMessage } from 'react-intl';
import { Typography } from '@afosto/components';
import { useOrder } from '../OrderProvider/hooks/useOrder';
import { OrderStateLabel } from '../OrderStateLabel';
import { translations } from './translations';
import * as Styled from './ReceiptTitle.styles';

export const ReceiptTitle = () => {
  const { order } = useOrder();

  return (
    <Styled.Container>
      <Typography variant="h5">
        {order?.number ? (
          order.number
        ) : (
          <FormattedMessage {...translations.cart} />
        )}
      </Typography>
      {order && <OrderStateLabel state={order.state} />}
    </Styled.Container>
  );
};
