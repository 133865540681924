import { defineMessages } from 'react-intl';

export const translations = defineMessages({
  addNote: {
    id: 'actions.addNote',
    defaultMessage: 'Add note',
    description: 'Common label for the add note action',
  },
  note: {
    id: 'common.note',
    defaultMessage: 'Note',
    description: 'Common label for note',
  },
});
