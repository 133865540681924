import { SvgIcon, type SvgIconProps } from '@afosto/components';

export const In3 = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 30 20">
    <rect width="28" height="20" fill="#001C5D" rx="4" />
    <path
      fill="#33EBC7"
      d="M6.88 8.2a.86.86 0 0 0-.88.84v4.44c0 .47.4.84.88.84.49 0 .88-.37.88-.84V9.04c0-.45-.4-.83-.88-.83Zm0-.52c.49 0 .88-.37.88-.84 0-.46-.4-.83-.88-.83a.86.86 0 0 0-.88.83c0 .47.4.84.88.84Zm5.21.52c-.85 0-1.4.3-1.73.6a2.13 2.13 0 0 0-.56-.36.84.84 0 0 0-.3-.05.86.86 0 0 0-.89.84c0 .32.19.6.46.74h.01c.06.03.1.07.14.1.22.22.3.61.32.85v2.56c0 .46.4.84.89.84s.88-.37.88-.84V10.7c0-.45.38-.82.85-.82s.86.37.86.82v2.79c0 .47.39.84.88.84.48 0 .88-.37.88-.84v-2.44c0-.32-.07-1.39-.82-2.13a2.56 2.56 0 0 0-1.87-.72Zm9.29 1.86.08-.09.04-.05.1-.13v-.01a2.54 2.54 0 0 0 .38-1.35c0-.82-.32-1.33-.63-1.65-.4-.38-.88-.78-2.3-.78h-2.6c-.5 0-.9.37-.9.84 0 .46.4.83.89.83h2.93c.47 0 .86.37.86.82 0 .42-.33.76-.75.8l-.33.01h-2.7a.86.86 0 0 0-.88.84c0 .46.39.84.88.84h2.92c.48 0 .86.36.86.81 0 .45-.38.82-.85.82h-2.93a.86.86 0 0 0-.88.83c0 .47.39.84.88.84h2.54a3.5 3.5 0 0 0 2.25-.78c.5-.44.76-1.07.76-1.8 0-.8-.32-1.32-.63-1.64-.01.03 0 .02.01 0Z"
    />
  </SvgIcon>
);
