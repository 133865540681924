import { useIntl } from 'react-intl';
import { Fields, Grid } from '@afosto/components';
import { Fieldset } from '../../../Fieldset';
import { translations } from './translations';

export const ContactDetailsFormSection = () => {
  const intl = useIntl();

  return (
    <Fieldset title={intl.formatMessage(translations.details)}>
      <Grid container spacing={3}>
        <Grid item container spacing={3} xs={12}>
          <Grid item xs={12} md={4}>
            <Fields.TextField
              name="contact.givenName"
              label={intl.formatMessage(translations.firstName)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Fields.TextField
              name="contact.additionalName"
              label={intl.formatMessage(translations.prefix)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Fields.TextField
              name="contact.familyName"
              label={intl.formatMessage(translations.lastName)}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Fields.TextField
            name="contact.email"
            label={intl.formatMessage(translations.email)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Fields.PhoneNumberField
            countryFieldName="contact.phoneNumberCountry"
            numberFieldName="contact.phoneNumberNumber"
            label={intl.formatMessage(translations.phoneNumber)}
            size="large"
            fullWidth
          />
        </Grid>
      </Grid>
    </Fieldset>
  );
};
