import { FormattedMessage, useIntl } from 'react-intl';
import { FullscreenDialog } from '../FullscreenDialog';
import { OrderActionsSection } from './components/OrderActionsSection';
import { translations } from './translations';
import type { OrderActionsDialogProps } from './types';
import { Link } from '@afosto/components';

export const OrderActionsDialog = (props: OrderActionsDialogProps) => {
  const { onClose, ...otherProps } = props;

  const intl = useIntl();

  const handleClose = () => {
    if (onClose && typeof onClose === 'function') {
      onClose();
    }
  };

  return (
    <FullscreenDialog
      {...otherProps}
      onClose={handleClose}
      title={intl.formatMessage(translations.title)}
      topBarProps={{
        actions: (
          <Link
            component="button"
            onClick={handleClose}
            fontWeight={500}
            type="button"
            variant="bodyLarge"
          >
            <FormattedMessage {...translations.close} />
          </Link>
        ),
      }}
    >
      <OrderActionsSection />
    </FullscreenDialog>
  );
};
