import { ActionsList } from '../../../ActionsList';
import { CardSelector } from '../../../CardSelector';
import type { CategoriesListProps } from './types';

export const CategoriesList = (props: CategoriesListProps) => {
  const { categories, onSelectCategory } = props;

  return (
    <ActionsList>
      {categories.map((category) => (
        <CardSelector
          key={category.key}
          description={category.formula}
          label={category.label}
          onSelect={onSelectCategory(category)}
        />
      ))}
    </ActionsList>
  );
};
