import { CategoryContext } from './CategoryContext';
import { SelectCategoryDialog } from '../SelectCategoryDialog';
import { useCategoryProvider } from './hooks/useCategoryProvider';
import type { CategoryProviderProps } from './types';

export const CategoryProvider = (props: CategoryProviderProps) => {
  const { children } = props;

  const categoryProviderState = useCategoryProvider();
  const {
    closeSelectCategoryDialog,
    selectCategory,
    showSelectCategoryDialog,
  } = categoryProviderState;

  return (
    <CategoryContext.Provider value={categoryProviderState}>
      {children}
      <SelectCategoryDialog
        onClose={closeSelectCategoryDialog}
        onSelectCategory={selectCategory}
        open={showSelectCategoryDialog}
      />
    </CategoryContext.Provider>
  );
};
