import apiClient from '@afosto/api-client';
import { Channels } from '@afosto/channel-service';
import { queryOptions } from '@tanstack/react-query';
import type { Channel } from '../types';

export interface ChannelsResponse {
  data: Channel[];
  meta: object;
}

export const LIST_CHANNELS_QUERY_KEY = 'listChannels';

export const fetchChannels = async ({
  queryKey,
}: {
  queryKey: [string, object, object];
}): Promise<ChannelsResponse> => {
  const [, query, requestOptions] = queryKey;
  const channelsClient = new Channels(apiClient);
  const response = await channelsClient.listChannels(query, requestOptions);
  return response.data;
};

export const listChannels = (query = {}, requestOptions = {}) => {
  return queryOptions({
    queryKey: [LIST_CHANNELS_QUERY_KEY, query, requestOptions],
    queryFn: fetchChannels,
  });
};
