import { TwoFactor as TwoFactorForm } from '@afosto/auth-react';
import { Grid } from '@afosto/components';

export const TwoFactor = () => {
  return (
    <Grid item xs={12} sm={8} md={6}>
      <TwoFactorForm />
    </Grid>
  );
};
